import React from 'react';
import {
  SimpleForm,
  Edit,
  SelectInput,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  email,
} from 'react-admin';
import PhoneInput from '../../../components/PhoneInput';
import ResourceTitle from '../../../components/ResourceTitle';
import SupplierAutocomplete from '../../../components/SupplierAutocomplete';
import {
  ALL_USER_ROLES_IDS as roleIds,
  ALL_USER_ROLES_NAMES as roleNames,
  adminRoleChoices,
} from '../../../config/user.config';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();
const validateEmail = [validateRequired, email()];

const UserEdit = (props) => {
  const supplierId = localStorage.getItem('supplierId');
  const role = localStorage.getItem('role');

  return (
    <Edit {...props} title={<ResourceTitle field="username" />} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <TextInput
          label="ra.label.email"
          source="email"
          validate={validateEmail}
          sx={{ maxWidth: '300px', width: '100%' }}
          disabled
        />
        <PhoneInput source="phone" shouldValidate sx={{ maxWidth: '300px', width: '100%' }} />
        <TextInput
          label="ra.label.first_name"
          source="firstName"
          sx={{ maxWidth: '300px', width: '100%' }}
        />
        <TextInput
          label="ra.label.last_name"
          source="lastName"
          sx={{ maxWidth: '300px', width: '100%' }}
        />
        <FormDataConsumer>
          {() => {
            return (
              role === roleNames.ADMIN && (
                <SelectInput
                  label="ra.label.role"
                  source="role"
                  optionValue="value"
                  choices={adminRoleChoices}
                  validate={validateRequired}
                  sx={{ maxWidth: '300px', width: '100%' }}
                />
              )
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              formData.role !== roleIds.ADMIN &&
              formData.role !== roleIds.USER && (
                <SupplierAutocomplete sx={{ maxWidth: '300px', width: '100%' }} />
              )
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {() => {
            return (
              role === roleNames.SUPPLIER_ADMIN && (
                <ReferenceInput
                  label="ra.label.branch"
                  source="branchId"
                  reference="address"
                  filter={{ supplierId }}
                  default=""
                  style={{ width: '256px' }}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
