import React, { useEffect, useState } from 'react';
import { Create, minValue, regex, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import PhoneInput from '../../../components/PhoneInput';
import ResourceTitle from '../../../components/ResourceTitle';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { baseUrl } from '../../../config/connection';

const validateRequired = required();
const validateMinValue = [minValue(25)];
const ssnRegex = regex(
  /^(19|20)?[0-9]{6}[-+]?[0-9]{4}$|^(19|20)?[0-9]{10}$/,
  'Invalid SSN, SSN should be 10 or 12 digits long without "-" character!',
);
const validateSSN = [validateRequired, ssnRegex];

const CourierCreate = (props) => {
  const [easyFreelanceFlag, setEasyFreelanceFlag] = useState(false);

  const fetchFeatureFlag = (flag) => {
    fetchWithAuthorization(`${baseUrl}/feature-flags/${flag}`)
      .then((results) => results.json())
      .then((data) => setEasyFreelanceFlag(data));
  };

  useEffect(() => {
    fetchFeatureFlag('integrations.easy-freelance');
  });

  return (
    <Create
      {...props}
      title={<ResourceTitle field="firstName" />}
      actions={<CancelButton />}
      redirect="list"
    >
      <SimpleForm redirect="list">
        <TextInput label="ra.label.email" source="email" validate={validateRequired} />
        <TextInput label="ra.label.password" source="password" validate={validateRequired} />
        <PhoneInput source="phone" shouldValidate />
        <TextInput label="ra.label.first_name" source="firstName" validate={validateRequired} />
        <TextInput label="ra.label.last_name" source="lastName" validate={validateRequired} />

        {/* Easy freelance user details */}
        {easyFreelanceFlag &&
          <>
            <TextInput
              label="ra.label.ssn"
              source="easyFreelanceUser.ssn"
              validate={validateRequired}
            />
            <TextInput
              label="ra.label.address"
              source="easyFreelanceUser.address"
              validate={validateRequired}
            />
            <TextInput
              label="ra.label.city"
              source="easyFreelanceUser.city"
              validate={validateRequired}
            />
            <TextInput
              label="ra.label.postal_code"
              source="easyFreelanceUser.postal_code"
              validate={validateRequired}
            />
            <SelectInput
              label="ra.label.main_work"
              source="easyFreelanceUser.main_work"
              choices={[
                { id: 'false', name: 'FALSE' },
                { id: 'true', name: 'TRUE' },
              ]}
              sx={{ width: '206px' }}
            />
            <TextInput label="ra.label.nationality" source="easyFreelanceUser.nationality" />
            <TextInput label="ra.label.note" source="easyFreelanceUser.note" />
            <TextInput
              label="ra.label.bank_clearing_number"
              source="easyFreelanceUser.bank_clearing_number"
            />
            <TextInput
              label="ra.label.bank_account_number"
              source="easyFreelanceUser.bank_account_number"
            />
            <TextInput
              label="ra.label.tax_percent"
              source="easyFreelanceUser.tax_percent"
              validate={validateMinValue}
            />
          </>}
      </SimpleForm>
    </Create>
  );
};

export default CourierCreate;
