/* eslint-disable react/jsx-one-expression-per-line */
import React, { forwardRef, useMemo } from 'react';
import logo from '../../../../../../public/images/roomesrvice.logo.png';
import '../styles.css';

export const SupplierReport = ({ supplier, reportData, dateRange, tableHeaders }) => {
  const generateDateRange = (date) => {
    const day = date?.toString().split(' ')[2];
    const month = date?.toString().split(' ')[1];
    const year = date?.toString().split(' ')[3];

    return `${day}. ${month} ${year}`;
  };

  const bodyData = useMemo(() => {
    const filteredData = reportData.filter((item) => item.orderId !== 'Total:');

    const mappedData = filteredData.map((item) => {
      const refunds = item.refunds.map((refund) => Number(refund).toFixed(2));
      const refundsComments = item.refundsComments;
      const refundsTotalPerItem = item.refunds.reduce((acc, refund) => acc + Number(refund), 0);
      const platformCommission = item.platformCommission !== 0 ? item.platformCommission / 100 : 0;
      const priceWithoutDelivery =
        item.orderTotalPrice
        - item.orderDeliveryPrice
        - refundsTotalPerItem;
      const brutto = (priceWithoutDelivery - (priceWithoutDelivery / 125) * 25).toFixed(2);
      const rsCommission = (brutto * platformCommission).toFixed(2);
      const netto = (brutto - rsCommission).toFixed(2);

      return {
        type: 'Ordre',
        orderId: item.orderId,
        estimatedFulfillment: item.orderDeliveredAt || 'Unknown',
        refundsComments,
        refunds,
        brutto: Number(brutto).toFixed(2),
        rsShare: `-${Number(rsCommission).toFixed(2)}`,
        netto: Number(netto).toFixed(2),
      };
    });

    return mappedData;
  }, [reportData]);

  const bruttoTotals = useMemo(() => {
    const totalBrutto = bodyData.reduce((acc, item) => acc + Number(item.brutto), 0).toFixed(2);
    const totalNetto = bodyData.reduce((acc, item) => acc + Number(item.netto), 0).toFixed(2);
    const totalRsShare = bodyData.reduce((acc, item) => acc + Number(item.rsShare), 0).toFixed(2);
    const totalRefunds = bodyData
      .reduce(
        (acc, item) => acc + item.refunds.reduce((total, refund) => total + Number(refund), 0),
        0,
      ).toFixed(2);

    return {
      type: 'Total -i alt ekskl. moms',
      orderId: '',
      refunds: totalRefunds,
      refundsComments: '',
      brutto: totalBrutto,
      rsShare: totalRsShare,
      netto: totalNetto,
      fulfilmentDate: '',
    };
  }, [bodyData]);

  const herafTotals = useMemo(
    () => ({
      ...bruttoTotals,
      rsShare: Number(bruttoTotals.rsShare).toFixed(2),
      type: '- Heraf ordre, i alt ekskl. moms',
    }),
    [bruttoTotals],
  );

  const vatTotals = useMemo(
    () => ({
      ...bruttoTotals,
      brutto: Number(bruttoTotals.brutto * 0.25).toFixed(2),
      rsShare: Number(bruttoTotals.rsShare * 0.25).toFixed(2),
      netto: Number(bruttoTotals.netto * 0.25).toFixed(2),
      type: 'Moms 25%',
      fulfilmentDate: '',
    }),
    [bruttoTotals],
  );

  const allTotals = useMemo(
    () => ({
      ...bruttoTotals,
      brutto: (Number(bruttoTotals.brutto) + Number(vatTotals.brutto)).toFixed(2),
      rsShare: (Number(bruttoTotals.rsShare) + Number(vatTotals.rsShare)).toFixed(2),
      netto: (Number(bruttoTotals.netto) + Number(vatTotals.netto)).toFixed(2),
      type: 'Total i alt inkl. moms',
      fulfilmentDate: '',
    }),
    [bruttoTotals, vatTotals],
  );

  return (
    <div className="pdf-main">
      {/* Header */}
      <div className="header">
        <div className="header-left-side">
          <div className="header-left-side-address">
            {supplier?.name} <br />
            {/* Kongens Nytorv 13 <br />
            1095 Kobenhavn K <br /> */}
          </div>
          <div className="header-left-side-info">
            <span className="header-left-side-info_bold">
              Specifikation til kreditnota/afregningsnr. 4230000006 (selvfacturering)
            </span>
            <br />
            <span className="header-left-side-info_semi_bold">
              {dateRange.from && dateRange.to &&
                <>For perioden {generateDateRange(dateRange.from)} til {generateDateRange(dateRange.to)}</>}
            </span>
          </div>
        </div>
        <div className="header-right-side">
          <div className="roomservice-logo">
            <img src={logo} alt="roomservice logo" />
          </div>
          <div>
            Room Service ApS <br />
            Ll. Skt. Hans Gade 11, <br />
            8800 Viborg
          </div>
          <div>
            Tlf. 7023 7026 <br />
            CVR: 3188 3172
          </div>
          <div>
            Web: https://www.roomservice.dk <br />
            E-mail: bogholderi@roomservice.dk
          </div>
          <div>
            {/* need to replace with current date */}
            {/* Dato: 28. Februar 2023 <br /> */}
            Dato: {generateDateRange(new Date())} <br />
          </div>
        </div>
      </div>

      <div className="table-container">
        <div className="table-head">
          <div className="table-row">
            {tableHeaders.map((header, idx) => {
              return (
                <div className={`cell-${idx}`} key={header.key}>
                  {header.label}
                </div>
              );
            })}
          </div>
        </div>
        <div className="table-body">
          {/* Map through orders to render the data */}
          {bodyData.map((order, orderIdx) => (
            <div
              className={`table-row ${orderIdx === bodyData.length - 1 ? '' : 'border-bottom'}`}
              key={order.orderId}
            >
              {Object.keys(order).map((item, itemIdx) => {
                return (
                  <div
                    className={`cell-${itemIdx} body-cell-${itemIdx}`}
                    key={item}
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    {['refunds', 'refundsComments'].includes(item) ?
                      order[item].map((data) => (
                        <div
                          className="table-row"
                        >
                          {data}
                        </div>
                      ))
                      : order[item]}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="table-footer">
          <div className="footer-row">
            {tableHeaders.map((header, idx) => {
              return (
                <div
                  className={`footer-cell cell-${idx} footer-totals-cell`}
                  key={header.key}
                >
                  {bruttoTotals[header.key]}
                </div>
              );
            })}
          </div>
          <div className="footer-row">
            {tableHeaders.map((header, idx) => {
              return (
                <div className={`footer-cell cell-${idx}`} key={header.key}>
                  {herafTotals[header.key]}
                </div>
              );
            })}
          </div>
          <div className="footer-row footer-vat-row">
            {tableHeaders.map((header, idx) => {
              return (
                <div className={`footer-cell cell-${idx} footer-vat-cell`} key={header.key}>
                  {vatTotals[header.key]}
                </div>
              );
            })}
          </div>
          <div className="footer-row footer-all-totals-row">
            {tableHeaders.map((header, idx) => {
              return (
                <div
                  className={`footer-cell cell-${idx} footer-totals-cell`}
                  key={header.key}
                >
                  {allTotals[header.key]}
                </div>
              );
            })}
          </div>
        </div>
        <div className="footer-info">
          Bemaerk, at betalingen for denne kreditnota/afregning afregnes til NEMkonto tilknyttet CVR
          nr {supplier?.cvrNumber} indenfor 3 hverdage fra d. {generateDateRange(new Date())}
        </div>
      </div>
    </div>
  );
};

const SupplierReportToPrint = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <SupplierReport {...props} />
    </div>
  );
});

export default SupplierReportToPrint;
