import React, { useState } from 'react';
import { EditButton, useRefresh, useTranslate, useRecordContext } from 'react-admin';
import { CardActions, CircularProgress, IconButton, Tooltip } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { RUN_STATUS } from '../../utils/constants';
import { runCampaignInit } from '../../utils/runCampaign';
import useStyles from './styles';

const RunCampaignActions = () => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const [simulateState, setSimulateState] = useState({ isLoading: false, itemId: null });
  const [initState, setInitState] = useState({ isLoading: false, itemId: null });
  const [simulateIsFailed, setSimulateIsFailed] = useState(false);
  const [initIsFailed, setInitIsFailed] = useState(false);

  const record = useRecordContext();

  const campaignId = record?.campaignId;
  const status = record?.status;

  const styles = useStyles();

  const runCampaignSimulate = (e, id) => {
    setSimulateState({ isLoading: true, itemId: record?.id });
    setSimulateIsFailed(false);

    runCampaignInit(e, record?.id, true).then((res) => {
      if (res.ok) {
        setSimulateState((prev) => ({ ...prev, isLoading: false }));
        refresh();
      } else {
        setSimulateIsFailed(true);
        refresh();
      }
    });
  };

  const runCampaignInitiate = (e, id) => {
    setInitState({ isLoading: true, itemId: record?.id });
    setInitIsFailed(false);
    refresh();
    runCampaignInit(e, record?.id).then((res) => {
      if (res.ok) {
        setInitState((prev) => ({ ...prev, isLoading: false }));
        refresh();
      } else {
        setInitIsFailed(true);
        refresh();
      }
    });
  };

  return (
    <CardActions className={styles.root}>
      <>
        <Tooltip title={translate('ra.label.run_simulate')}>
          <IconButton
            onClick={(e) => {
              runCampaignSimulate(e, campaignId);
            }}
            disabled={status === RUN_STATUS.LAUNCHED}
            color={status === RUN_STATUS.LAUNCHED ? 'default' : 'inherit'}
          >
            {(simulateState.itemId !== campaignId || !simulateState.isLoading) && (
              <PlayCircleFilledWhiteOutlinedIcon
                fontSize="medium"
                color={status === RUN_STATUS.LAUNCHED ? 'inherit' : 'primary'}
              />
            )}
            {!simulateIsFailed &&
              simulateState.isLoading &&
              simulateState.itemId === campaignId && (
                <CircularProgress color="primary" size="20px" />
            )}
            {simulateIsFailed && simulateState.itemId === campaignId && (
              <WarningRoundedIcon color="error" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={translate('ra.label.run_init')}>
          <IconButton
            onClick={(e) => {
              runCampaignInitiate(e, campaignId);
            }}
            disabled={status === RUN_STATUS.LAUNCHED}
            color={status === RUN_STATUS.LAUNCHED ? 'default' : 'inherit'}
          >
            {(initState.itemId !== campaignId || !initState.isLoading) && (
              <PlayCircleIcon
                fontSize="medium"
                color={status === RUN_STATUS.LAUNCHED ? 'inherit' : 'primary'}
              />
            )}
            {!initIsFailed && initState.isLoading && initState.itemId === campaignId && (
              <CircularProgress color="primary" size="20px" />
            )}
            {initIsFailed && initState.itemId === campaignId && (
              <WarningRoundedIcon color="error" />
            )}
          </IconButton>
        </Tooltip>
        <EditButton record={record} />
      </>
    </CardActions>
  );
};

export default RunCampaignActions;
