import React from 'react';
import { Edit, required, SimpleForm, TextInput } from 'react-admin';
import ResourceTitle from '../../../components/ResourceTitle';
import { FilterTypeListInput } from '../Filters/FilterTypeList';
import { PerkTypeListInput } from '../Perks/PerkTypeList';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();

const DiscountEdit = () => {
  return (
    <Edit title={<ResourceTitle field="name" />} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <TextInput source="name" validate={validateRequired} />
        <TextInput source="label" validate={validateRequired} />
        <FilterTypeListInput source="filters" />
        <PerkTypeListInput source="perks" />
      </SimpleForm>
    </Edit>
  );
};

export default DiscountEdit;
