import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const PaymentConfigList = () => (
  <List>
    <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
      <TextField label="ra.label.name" source="name" />
      <TextField label="ra.label.terminal" source="terminalId" />
    </Datagrid>
  </List>
);

export default PaymentConfigList;
