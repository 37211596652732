import React from 'react';
import { Button } from '@mui/material';
import { TopToolbar, CreateButton, useNotify } from 'react-admin';
import { putWithAuthorization } from '../../../utils/fetchWithAuthorization';
import url from '../../../config/connection';

const SupplierListActions = () => {
  const notify = useNotify();

  const openCloseHandler = (action) => {
    return putWithAuthorization(`${url}/supplier/update-many-statuses`, {
      body: JSON.stringify({
        actionType: action,
      }),
    })
      .then(() => {
        switch (action) {
          case 'OPEN':
            notify('Suppliers were successfully opened!', { type: 'success' });
            break;
          case 'CLOSE':
            notify('Suppliers were successfully closed!', { type: 'info' });
            break;
          default:
            return null;
        }
      })
      .catch(() => notify('Something went wrong', { type: 'error' }));
  };

  return (
    <TopToolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <Button
        size="small"
        variant="contained"
        color="success"
        onClick={() => openCloseHandler('OPEN')}
      >
        Open All
      </Button>
      <Button
        size="small"
        variant="contained"
        color="warning"
        onClick={() => openCloseHandler('CLOSE')}
      >
        Close All
      </Button>
      <CreateButton variant="outlined" />
    </TopToolbar>
  );
};

export default SupplierListActions;
