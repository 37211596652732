import React from 'react';
import {
  ChipField,
  ReferenceArrayField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import ResourceTitle from '../../../components/ResourceTitle';

const EmailOrPhoneChipField = ({ record }) => {
  const displayValue = record.email ? 'email' : 'phone';

  return <ChipField source={displayValue} />;
};

const CompanyShow = () => {
  return (
    <Show title={<ResourceTitle field="legalName" />}>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.name" source="legalName" />
          <TextField label="ra.label.phone" source="phoneNumber" />
          <TextField label="ra.label.email" source="email" />
          <TextField label="ra.label.contract" source="contractNumber" />
          <ReferenceArrayField
            label="ra.label.users"
            source="users_ids"
            reference="user"
            perPage={100}
          >
            <SingleFieldList>
              <EmailOrPhoneChipField />
            </SingleFieldList>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CompanyShow;
