import { postWithAuthorization } from '../../utils/fetchWithAuthorization';
import url from '../../config/connection';

const giveDiscount = ({ values }, notify) => {
  if (values.data && values.discountId) {
    postWithAuthorization(`${url}/owned-discount`, {
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        owner: {
          type: 'SUPPLIER',
          id: String(values.id),
        },
        discountId: values.discountId,
        validUntil: new Date(values.data),
      }),
    })
      .then(() => {
        notify('Approved');
      })
      .catch((e) => {
        notify('Error: Not approved', 'warning');
      });
  }
};

export default giveDiscount;
