export const style = {
  cardTitle: {
    margin: '0px 0 10px',
    minWidth: '250px',
    borderBottom: '1px solid #aaa',
    display: 'flex',
  },
  dropdownWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    background: 'rgba(76, 134, 168, 0.1)',
    width: 'calc(100% + 48px)',
    margin: '-4px -24px',
    justifyContent: 'center',
    padding: '20px',
  },
  cardStyle: {
    padding: '10px',
    margin: '20px 5px',
    minWidth: '250px',
  },
  price: {
    fontSize: '32px',
    textAlign: 'center',
    display: 'block',
  },
};

export default style;
