import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import DeliverySettingsInput from '../DeliverySettingsFieldInput';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const DeliverySettingsEdit = () => (
  <Edit actions={<CancelButton />}>
    <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
      <DeliverySettingsInput />
    </SimpleForm>
  </Edit>
);

export default DeliverySettingsEdit;
