import React from 'react';
import { TextInput, required } from 'react-admin';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

const phoneValidation = (value) => {
  const isPossibleNumber = isPossiblePhoneNumber(value);

  if (!isValidPhoneNumber(value) && !isPossibleNumber) {
    return 'Invalid phone number';
  }

  return undefined;
};

const validateRequired = required();
const validatePhone = [validateRequired, phoneValidation];

const PhoneInput = ({ sx: styles = {}, source, shouldValidate, control, ...props }) => {
  return (
    <TextInput
      {...props}
      label="ra.label.phone"
      source={source}
      validate={shouldValidate ? validatePhone : []}
      sx={styles}
      control={control}
    />
  );
};

export default PhoneInput;
