export const formatPhone = (phone) => {
  if (phone) {
    const trimmedPhone = phone.replace(/[^0-9]/gim, '').trim();
    const phonePrefix = `+${trimmedPhone.slice(0, 3)}`;
    const phoneRegionalPrefix = `(${trimmedPhone.slice(3, 5)})`;
    const phoneNumberFirstPart = `${trimmedPhone.slice(5, 7)}`;
    const phoneNumberSecondPart = `${trimmedPhone.slice(7, 9)}`;
    const phoneNumberThirdPart = `${trimmedPhone.slice(9, 11)}`;

    const phoneNumber = `${phoneNumberFirstPart}-${phoneNumberSecondPart}-${phoneNumberThirdPart}`;

    const finalPhone = `${phonePrefix} ${phoneRegionalPrefix} ${phoneNumber}`;

    return finalPhone;
  }

  return null;
};
