import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { baseUrl } from '../../../config/connection';

const ReferenceFieldCustome = () => {
  const [discountName, setDiscountName] = useState(null);
  const record = useRecordContext();

  useEffect(() => {
    if (record?._id && record?.id) {
      fetchWithAuthorization(`${baseUrl}/admin/discount/${record?.id}`)
        .then((res) => res.json())
        .then((res) => setDiscountName(res.name));
    }
  }, [record]);

  return <span>{discountName || ''}</span>;
};

export default ReferenceFieldCustome;
