import React from 'react';
import {
  Edit,
  SimpleForm,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import UIHtmlSeoForm from '../common/Form';

const UIBannerEdit = () => {
  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <UIHtmlSeoForm />
      </SimpleForm>
    </Edit>
  );
};

export default UIBannerEdit;
