import GoodsCreate from './components/Create';
import GoodsEdit from './components/Edit';
import GoodsList from './components/List';
import GoodsShow from './components/Show';

export default {
  list: GoodsList,
  create: GoodsCreate,
  edit: GoodsEdit,
  show: GoodsShow,
};
