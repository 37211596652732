import React, { useState, useCallback } from 'react';
import { Select, FormControl, InputLabel, MenuItem, Button, Box } from '@mui/material';
import 'react-day-picker/lib/style.css';
import { useTranslate } from 'react-admin';
import { TabStyle } from '../../style';
import url from '../../../config/connection';
import TimeRangePicker from '../TimeRangePicker';
import { parseDateToDDMMYYY } from '../../../utils/timeOperations';
import {
  fetchWithAuthorization,
  postWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import formatDateTime from '../../../utils/formatDateTime';
import { DEFAULT_PAYMENT_METHODS } from '../../../config/payment.config';
import { DEFAULT_REPORT_FIELDS } from '../../../config/reportFields.config';
import PreviewData from '../PreviewData';
import CSVExport from '../CSVExport';
import CheckBoxTable from '../CheckBoxTable';
import { courierReportHeaders } from '../../constants';
import Modal from '../../../components/Modal';
import { formatCourier } from '../../utils/formatCourier';

const CourierReport = ({ getDataHeadings }) => {
  const [selectedCouriers, setSelectedCouriers] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(DEFAULT_PAYMENT_METHODS);
  const [reportData, setCourierExportData] = useState([]);
  const [fields, setFields] = useState(DEFAULT_REPORT_FIELDS.COURIER);

  const translate = useTranslate();

  const getReport = useCallback(() => {
    const isDateRangeComplete = dateRange.from && dateRange.to;

    if (selectedCouriers.length || isDateRangeComplete) {
      const parsedPaymentMethods = Object.keys(paymentMethods).filter(
        (method) => paymentMethods[method],
      );

      postWithAuthorization(`${url}/report/couriers/export`, {
        body: JSON.stringify({
          selectedCouriers,
          dateRange,
          paymentMethods: parsedPaymentMethods,
          reportFields: fields,
        }),
      })
        .then((data) => data.json())
        .then((couriersData) => {
          couriersData.forEach((row, index) => {
            if (index === couriersData.length - 1) {
              return row;
            }

            const { date, time } = formatDateTime(row.orderOrderedAt);

            row.orderOrderedAt = `${date} ${time}`;
          });

          setCourierExportData(couriersData);
        });
    }
  }, [selectedCouriers, dateRange, paymentMethods, fields]);

  const fetchCouriers = useCallback(() => {
    if (couriers.length) {
      return;
    }

    fetchWithAuthorization(`${url}/courier`)
      .then((res) => res.json())
      .then(({ data }) => setCouriers(data))
      .catch(() => {
        throw new Error('failed to fetch');
      });
  }, [couriers]);

  const handleCourierSelect = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'all') {
      setSelectedCouriers(
        selectedCouriers.length === couriers.length
          ? []
          : couriers.map(({ userId }) => userId),
      );

      return;
    }

    setSelectedCouriers(value);
  };

  const composeCalendarBtnTitle = () => {
    const { from, to } = dateRange;
    const isSelectedRange = from && to;

    if (!isSelectedRange) {
      return translate('ra.action.select_time_range');
    }

    return `${parseDateToDDMMYYY(from)} - ${parseDateToDDMMYYY(to)}`;
  };

  const changeField = (field, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const changePaymentMethod = (method, value) => {
    setPaymentMethods((prevPaymentMethods) => ({
      ...prevPaymentMethods,
      [method]: value,
    }));
  };

  return (
    <div style={TabStyle.wrapper}>
      <FormControl style={{ minWidth: '250px', maxWidth: '550px' }}>
        <InputLabel htmlFor="courierId">{translate('ra.title.select_courier')}</InputLabel>
        <Select
          multiple
          value={selectedCouriers}
          onChange={handleCourierSelect}
          onOpen={fetchCouriers}
          inputProps={{
            name: 'Courier',
            id: 'courierId',
          }}
        >
          <MenuItem
            value="all"
          >
            {translate('ra.action.select_all')}
          </MenuItem>
          {couriers.map((courier) => {
            return (
              <MenuItem key={courier.id} value={courier.userId}>
                {formatCourier(courier)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        color="primary"
        style={{ margin: '15px 0', color: '#477890', borderColor: '#477890' }}
        onClick={() => {
          setCalendarModalOpen(true);
        }}
      >
        {composeCalendarBtnTitle()}
      </Button>
      <Modal open={calendarModalOpen} onClose={() => setCalendarModalOpen(false)}>
        <TimeRangePicker range={dateRange} changeRange={setDateRange} />
      </Modal>
      <CheckBoxTable
        data={paymentMethods}
        onChangeAction={changePaymentMethod}
        type="changePaymentMethod"
        translate={translate}
      />
      <CSVExport
        data={reportData}
        headers={courierReportHeaders.filter(({ key }) => fields[key])}
      />
      <CheckBoxTable data={fields} onChangeAction={changeField} type="changeField" />
      <Box mt={2}>
        <Button onClick={getReport} variant="outlined" color="primary">
          {translate('ra.action.generate_report')}
        </Button>
      </Box>
      <PreviewData fields={fields} data={reportData} getDataHeadings={getDataHeadings} />
    </div>
  );
};

export default CourierReport;
