import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import styles from './styles';

const GoodsFilter = ({ setFilteredGoods, supplierGoods }) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchValue.length) {
      const filtered = supplierGoods.filter((good) => {
        return good.name.match(new RegExp(`\\b${searchValue}`, 'i'));
      });

      setFilteredGoods(() => [...filtered]);
    } else {
      setFilteredGoods(null);
    }
  }, [searchValue, setFilteredGoods, supplierGoods]);

  return (
    <TextField
      onChange={(e) => setSearchValue(e.target.value)}
      style={styles.searchField}
      value={searchValue}
      variant="outlined"
      placeholder="Search"
    />
  );
};

export default GoodsFilter;
