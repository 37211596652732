const styles = {
  Wrapper: {
    display: 'inline-flex',
    padding: '0 8px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    marginTop: '32px',
    marginBottom: '12px',
    position: 'relative',
    // maxWidth: '200px',
  },
  Label: {
    left: 0,
    position: 'absolute',
    top: '-25px',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 1)',
    textTransform: 'capitalize',
  },
};

export default styles;
