import { StoreMallDirectoryOutlined } from '@mui/icons-material';
import SupplierCreate from './components/Create';
import SupplierList from './components/List';
import SupplierShow from './components/Show';
import SupplierEdit from './components/Edit';

export default {
  list: SupplierList,
  create: SupplierCreate,
  show: SupplierShow,
  edit: SupplierEdit,
  icon: StoreMallDirectoryOutlined,
};
