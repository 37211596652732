import React, { useState } from 'react';
import { List, Datagrid, TextField, ReferenceField, FunctionField, useRefresh } from 'react-admin';
import { CircularProgress, IconButton } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { RUN_STATUS } from '../../utils/constants';
import { runCampaignInit } from '../../utils/runCampaign';
import { formatDate } from '../../utils/formatDate';
import ListDeleteConfirmationButton from '../../../../components/ListDeleteConfirmationButton';

const RunCampaignList = ({ ...props }) => {
  const refresh = useRefresh();
  const [runSimulateState, setRunSimulateState] = useState({ isLoading: false, itemId: null });
  const [runInitState, setRunInitState] = useState({ isLoading: false, itemId: null });
  const [runSimulateIsFailed, setRunSimulateIsFailed] = useState(false);
  const [runInitIsFailed, setRunInitIsFailed] = useState(false);

  const runCampaignSimulate = (e, id) => {
    setRunSimulateState({ isLoading: true, itemId: id });
    setRunSimulateIsFailed(false);

    runCampaignInit(e, id, true).then((res) => {
      if (res.ok) {
        setRunSimulateState((prev) => ({ ...prev, isLoading: false }));
        refresh();
      } else {
        setRunSimulateIsFailed(true);
        refresh();
      }
    });
  };

  const runCampaignInitiate = (e, id) => {
    setRunInitState({ isLoading: true, itemId: id });
    setRunInitIsFailed(false);

    runCampaignInit(e, id).then((res) => {
      if (res.ok) {
        setRunInitState((prev) => ({ ...prev, isLoading: false }));
        refresh();
      } else {
        setRunInitIsFailed(true);
        refresh();
      }
    });
  };

  return (
    <List {...props}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <ReferenceField
          label="ra.label.name"
          source="campaignId"
          reference="marketing-campaigns"
          linkType="show"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>

        <FunctionField
          label="ra.label.launchDate"
          render={({ launchDate }) => formatDate(launchDate)}
        />
        <FunctionField
          label="ra.label.effectiveDate"
          render={({ effectiveDate }) => formatDate(effectiveDate)}
        />
        <TextField label="ra.label.discounts_sent" source="statistics.discountsSent" />
        <TextField label="ra.label.discounts_used" source="statistics.discountsUsed" />
        <TextField label="ra.label.status" source="status" />
        <FunctionField
          label="ra.label.run_simulate"
          render={({ id, status }) => {
            return (
              <IconButton
                onClick={(e) => {
                  runCampaignSimulate(e, id);
                }}
                disabled={status === RUN_STATUS.LAUNCHED}
                color={status === RUN_STATUS.LAUNCHED ? 'secondary' : 'inherit'}
              >
                {(runSimulateState.itemId !== id || !runSimulateState.isLoading) && (
                  <PlayCircleFilledWhiteOutlinedIcon
                    fontSize="medium"
                    color={status === RUN_STATUS.LAUNCHED ? 'inherit' : 'primary'}
                  />
                )}
                {!runSimulateIsFailed &&
                  runSimulateState.isLoading &&
                  runSimulateState.itemId === id && (
                    <CircularProgress color="primary" size="20px" />
                    // eslint-disable-next-line indent
                  )}
                {runSimulateIsFailed && runSimulateState.itemId === id && (
                  <WarningRoundedIcon color="error" />
                )}
              </IconButton>
            );
          }}
        />
        <FunctionField
          label="ra.label.run_init"
          render={({ id, status }) => {
            return (
              <IconButton
                onClick={(e) => {
                  runCampaignInitiate(e, id);
                }}
                disabled={status === RUN_STATUS.LAUNCHED}
                color={status === RUN_STATUS.LAUNCHED ? 'secondary' : 'inherit'}
              >
                {(runInitState.itemId !== id || !runInitState.isLoading) && (
                  <PlayCircleIcon
                    fontSize="medium"
                    color={status === RUN_STATUS.LAUNCHED ? 'inherit' : 'primary'}
                  />
                )}
                {!runInitIsFailed && runInitState.isLoading && runInitState.itemId === id && (
                  <CircularProgress color="primary" size="20px" />
                )}
                {runInitIsFailed && runInitState.itemId === id && (
                  <WarningRoundedIcon color="error" />
                )}
              </IconButton>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default RunCampaignList;
