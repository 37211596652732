import React from 'react';
import { Create, required, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import { FilterTypeListInput } from '../Filters/FilterTypeList';
import { PerkTypeListInput } from '../Perks/PerkTypeList';

const validateRequired = required();

const DiscountCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable />
  </Toolbar>
);

const DiscountCreate = (props) => {
  return (
    <Create {...props} redirect="list" actions={<CancelButton />}>
      <SimpleForm toolbar={<DiscountCreateToolbar />}>
        <TextInput source="name" name="name" validate={validateRequired} />
        <TextInput source="label" name="label" validate={validateRequired} />
        <FilterTypeListInput source="filters" />
        <PerkTypeListInput source="perks" />
      </SimpleForm>
    </Create>
  );
};

export default DiscountCreate;
