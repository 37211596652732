import Editor from '@monaco-editor/react';
import React, { useCallback } from 'react';
import {
  required,
  SelectInput,
} from 'react-admin';
import { useController } from 'react-hook-form';

const documentTypeChoices = [
  { id: 'RECEIPT', name: 'RECEIPT' },
];

const validateRequired = required();

export function FormattedTextArea({ language, source, height, width }) {
  const control = useController({
    name: source,
    defaultValue: '',
  });

  const onChangeCallback = useCallback(
    (value) => {
      control.field.onChange(value);
    },
    [control],
  );

  return (
    <Editor
      language={language}
      height={height}
      width={width}
      value={control.field.value}
      onChange={onChangeCallback}
    />
  );
}

const DocumentHtmlForm = () => (
  <>
    <SelectInput
      label="ra.label.document_type"
      source="type"
      validate={validateRequired}
      choices={documentTypeChoices}
      sx={{ maxWidth: '218px', width: '100%' }}
    />
    <FormattedTextArea language="html" height={500} source="htmlContent" />
  </>
);

export default DocumentHtmlForm;
