import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@mui/styles';
import GoodCard from './GoodCard';

const useStyles = makeStyles({
  selectBtn: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
});

const ManagedGoodCard = ({
  section,
  setSections,
  good,
  goodIdx,
  onAvailabilityChange,
  parsedProduct,
  setIsSavedChanges,
  isFiltered,
  isCurrentMenu,
}) => {
  const [selected, setSelected] = useState(false);
  const styles = useStyles();
  const translate = useTranslate();

  useEffect(() => {
    const foundGood = section && !!section.goods.find((sectionGood) => sectionGood.id === good.id);

    setSelected(foundGood);
  }, [good.id, section]);

  const handleSelect = () => {
    const newSection = JSON.parse(JSON.stringify(section));

    if (selected) {
      newSection.goods = newSection.goods.filter((sectionGood) => sectionGood.id !== good.id);
    } else {
      newSection.goods = [...newSection.goods, good];
    }

    setSelected(!selected);

    setSections((prev) => {
      const index = prev.findIndex((otherSection) => otherSection.id === section.id);
      const copy = [...prev];

      copy[index] = newSection;

      return [...copy];
    });

    setIsSavedChanges(false);
  };

  return (
    <GoodCard
      shouldDisplayActionButtons={isCurrentMenu || isFiltered ? selected : false}
      good={good}
      goodIdx={goodIdx}
      onAvailabilityChange={onAvailabilityChange}
      parsedProduct={parsedProduct}
      section={section}
      setSections={setSections}
      setIsSavedChanges={setIsSavedChanges}
      actions={
        <Button
          size="small"
          onClick={handleSelect}
          fullWidth
          color={selected ? 'secondary' : 'primary'}
          className={styles.selectBtn}
        >
          {translate(`ra.action.${selected ? 'remove' : 'add'}`)}
        </Button>
      }
    />
  );
};

export default ManagedGoodCard;
