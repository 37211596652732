import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import {
  Select,
  FormControlLabel,
  Checkbox,
  MenuItem,
  TextField,
} from '@mui/material';
import { DEFAULT_FILTER_VALUES, PAYMENT_TYPES, CARD_TYPES } from '../../../config';

const PaymentFilter = ({ type, handleFilterCheck, checkedFilters, disabled }) => {
  const translate = useTranslate();
  const [options, setOptions] = useState(DEFAULT_FILTER_VALUES.PAYMENT_FILTER);
  const [checked, setChecked] = useState(false);

  const currentFilter =
    checkedFilters && checkedFilters.find((filterObject) => filterObject.type === type);

  useEffect(() => {
    setOptions(currentFilter || DEFAULT_FILTER_VALUES.PAYMENT_FILTER);
    setChecked(!!currentFilter);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const e = {
      target: {
        value: type,
        checked,
      },
    };
    const filterOptions = {
      type,
      ...options,
    };

    handleFilterCheck(e, filterOptions);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, checked]);

  const updatePaymentMethod = ({ target: { value } }) => {
    setOptions(() => ({
      paymentType: value,
      cardType: value === PAYMENT_TYPES.CASH
        ? null
        : CARD_TYPES.MASTERCARD,
      binsRange: null,
    }));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() => setChecked(!checked)}
            color="primary"
            disabled={disabled}
          />
        }
        label={type}
      />
      <span>{translate('ra.discount.paymentFilter.set')}</span>
      <Select
        value={options.paymentType}
        onChange={updatePaymentMethod}
        variant="standard"
        sx={{ marginLeft: '10px' }}
      >
        {Object.entries(PAYMENT_TYPES).map(([key, value]) => {
          return (
            <MenuItem value={value} key={key}>
              {key}
            </MenuItem>
          );
        })}
      </Select>
      {options.paymentType === PAYMENT_TYPES.CARD ? (
        <>
          <Select
            variant="standard"
            sx={{ marginLeft: '10px' }}
            value={options.cardType}
            onChange={({ target: { value } }) => {
              setOptions((prev) => ({ ...prev, cardType: value }));
            }}
          >
            {Object.entries(CARD_TYPES).map(([key, value]) => {
              return (
                <MenuItem value={value} key={key}>
                  {key}
                </MenuItem>
              );
            })}
          </Select>

          <TextField
            id="binsRange"
            placeholder="Bank Identification Number"
            onChange={({ target: { value } }) => {
              setOptions((prev) => ({ ...prev, binsRange: value }));
            }}
            type="text"
            sx={{ marginLeft: '10px' }}
            value={options.binsRange}
            variant="standard"
          />
        </>
      ) : null}
    </div>
  );
};

export default PaymentFilter;
