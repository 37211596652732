import React, { useState } from 'react';
import {
  Tab,
  Show,
  Datagrid,
  TextField,
  ChipField,
  ArrayField,
  FunctionField,
  TabbedShowLayout,
  ReferenceManyField,
  DateField,
  useTranslate,
  ReferenceField,
  useNotify,
  useShowController,
} from 'react-admin';
import { Button } from '@mui/material';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import ShowActions from '../ShowActions';
import OrderedAtField from '../../../components/OrderedAtField';
import PriceField from '../../../components/PriceField';
import StatusLabel from '../../../components/StatusLabel';
import { Address, Supplier } from '../../../Orders/components/OrderListDatagrid';
import ResourceTitle from '../../../components/ResourceTitle';
import formatDateTime from '../../../utils/formatDateTime';
import { formatPhone } from '../../../utils/formatPhone';

const auth = getAuth();

const UserShow = (props) => {
  const [isPasswordChangeRequested, setIsPasswordChangeRequested] = useState(false);

  const translate = useTranslate();
  const notify = useNotify();

  const showController = useShowController();
  const userEmail = showController.record?.email;

  const rowClickRedirect = (id, __, record) => {
    const dateToFormat = record?.orderedAt || record?.updatedAt;
    const formattedDate = formatDateTime(dateToFormat);

    return !formattedDate.today ? `/order/history/${id}/show` : `/order/today/${id}/show`;
  };

  const onChangePassword = async () => {
    await sendPasswordResetEmail(auth, userEmail);
    setIsPasswordChangeRequested(true);

    notify(`Password reset email sent to ${userEmail}`, { type: 'success' });
  };

  return (
    <Show title={<ResourceTitle field="username" />} actions={<ShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.id" source="id" />
          <TextField label="ra.label.email" source="email" />
          <TextField label="ra.label.first_name" source="firstName" />
          <TextField label="ra.label.last_name" source="lastName" />
          <FunctionField source="phone" render={({ phone }) => formatPhone(phone)} />
          <ReferenceField label="ra.label.company" source="companyId" reference="company">
            <TextField source="legalName" />
          </ReferenceField>
          <ArrayField label="ra.label.discount" source="discounts">
            <Datagrid bulkActionButtons={false}>
              <TextField label="ra.label.name" source="name" />
              <TextField label="ra.label.label" source="label" />
              <DateField
                label="ra.label.data"
                source="validUntil"
                options={{ year: 'numeric', month: 'numeric', day: 'numeric' }}
                locales="ro-RO"
              />
            </Datagrid>
          </ArrayField>
          <ArrayField label="ra.label.referrals" source="referrals">
            <Datagrid>
              <TextField label="ra.label.first_name" source="firstName" />
              <TextField label="ra.label.last_name" source="lastName" />
              <TextField label="ra.label.email" source="email" />
            </Datagrid>
          </ArrayField>
          <FunctionField
            label="ra.label.referrer"
            render={({ referrer }) => {
              return referrer ? (
                <span>{`${referrer.firstName} ${referrer.lastName}, ${referrer.email}`}</span>
              ) : (
                <span>{translate('ra.message.user_is_not_referral')}</span>
              );
            }}
          />
          <ChipField
            sx={{ width: '180px' }}
            label="ra.label.role"
            color="secondary"
            source="roleName"
          />
          <Button
            onClick={onChangePassword}
            variant="contained"
            color="primary"
            style={{ marginBottom: '16px', maxWidth: '180px', width: '100%' }}
            disabled={isPasswordChangeRequested}
          >
            {translate('ra.action.password_reset')}
          </Button>
        </Tab>
        <Tab label="ra.tab.user_address">
          <ReferenceManyField reference="useraddress" target="userId">
            <Datagrid rowClick="show">
              <FunctionField
                label="ra.label.address"
                render={({ street, area, house, country }) => {
                  return [street, house, area, country].filter(Boolean).join(', ');
                }}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="resources.transaction.name">
          <ReferenceManyField reference="transaction" target="card.userId">
            <Datagrid>
              <ChipField label="ra.label.status" source="status" />
              <TextField label="ra.label.rrn" source="transactionData.RRN" />
              <TextField label="ra.label.sum" source="transactionData.AMOUNT" />
              <DateField label="ra.label.created_at" source="createdAt" showTime />
              <TextField label="ra.label.order_id" source="orderId" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="resources.order/history.name">
          <ReferenceManyField reference="order" target="client.id" perPage={500}>
            <Datagrid rowClick={rowClickRedirect} bulkActionButtons={false}>
              <FunctionField
                style={{
                  letterSpacing: '-0.02em',
                  color: '#555555',
                }}
                label="ra.label.uppercase.supplier"
                render={Supplier}
              />
              <FunctionField
                style={{
                  letterSpacing: '-0.02em',
                  color: '#555555',
                }}
                label="ra.label.uppercase.address"
                render={Address}
              />
              <OrderedAtField label="ra.label.uppercase.ordered_at" />

              <PriceField
                label="ra.label.uppercase.price"
                source="totalPrice"
                parse={(v) => v / 100}
              />
              <StatusLabel label="ra.label.uppercase.status" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
