import React from 'react';
import { Create, required, SimpleForm, NumberInput, BooleanInput, minValue } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import { TranslatableTextInput } from '../../../components/Translatable';

const validateRequired = required();

const ServiceFeesCreate = () => (
  <Create redirect="list" actions={<CancelButton />}>
    <SimpleForm>
      <TranslatableTextInput label="ra.label.title" source="title" validate={validateRequired} />
      <NumberInput
        parse={(v) => v * 100 || ''}
        format={(v) => v / 100 || ''}
        label="ra.label.feeAmount"
        source="feeAmount"
        validate={minValue(0)}
        sx={{ maxWidth: '270px', width: '100%' }}
      />
      <NumberInput
        parse={(v) => v * 100 || ''}
        format={(v) => v / 100 || ''}
        label="ra.label.feeThreshold"
        source="feeThreshold"
        validate={minValue(1)}
        sx={{ maxWidth: '270px', width: '100%' }}
      />
      <BooleanInput label="ra.label.active" source="isActive" />
    </SimpleForm>
  </Create>
);

export default ServiceFeesCreate;
