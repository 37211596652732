/* eslint-disable max-len */
import React from 'react';
import {
  ArrayField,
  Datagrid,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import TranslatableTextFieldList from '../../../components/Translatable/TranslatableFields/TranslatableTextFieldList';
import TranslatableTextFieldShow from '../../../components/Translatable/TranslatableFields/TranslatableTextFieldShow';
import { baseUrl } from '../../../config/connection';
import ImageField from '../ImageField';

const NavigationShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField label="ra.label.name" source="name" />
        <TranslatableTextFieldShow label="ra.label.label" source="label" />
        <TextField label="ra.label.slug" source="slug" />
        <ReferenceField
          label="ra.label.supplier_store"
          reference="store"
          source="storeId"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="items">
          <Datagrid bulkActionButtons={false} sx={{ maxWidth: '50%' }}>
            <TextField label="ra.label.slug" source="slug" />
            <TextField label="ra.label.action" source="action.type" />
            <TranslatableTextFieldList label="ra.label.label" source="label" />
            <ImageField apiRoot={baseUrl} source="image" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default NavigationShow;
