import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const LocaleList = ({ ...props }) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.locale" source="locale" />
        <TextField label="ra.label.long" source="long" />
        <TextField label="ra.label.short" source="short" />
      </Datagrid>
    </List>
  );
};

export default LocaleList;
