import React, { useState } from 'react';
import {
  EditButton,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  useRefresh,
  useNotify,
  Toolbar,
} from 'react-admin';
import { Button, Card, CardContent, Modal } from '@mui/material';
import useStyles from './styles';
import giveDiscount from '../../utils/giveDiscount';
import DiscountActionToolbar from '../../../components/DiscountActionToolbar';

const ShowActions = ({ basePath, data, syncSupplier }) => {
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  return (
    <Toolbar className={styles.root}>
      <EditButton basePath={basePath} record={data} />
      <Button
        color="primary"
        variant="outlined"
        onClick={(e) => {
          e.stopPropagation();
          syncSupplier(data.id);
        }}
      >
        SYNC
      </Button>
      <Button color="primary" variant="outlined" onClick={handleOpen}>
        Discount
      </Button>

      <Modal
        disableEnforceFocus
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 1500,
        }}
      >
        <Card>
          <CardContent>
            <SimpleForm
              onSubmit={(values) => {
                giveDiscount({ values }, notify);
                handleClose();
              }}
              toolbar={<DiscountActionToolbar />}
            >
              <ReferenceInput label="ra.label.discount" reference="discount" source="discountId">
                <SelectInput optionText="name" sx={{ maxWidth: '205px', width: '100%' }} />
              </ReferenceInput>
              <DateTimeInput source="data" label="ra.label.data" />
            </SimpleForm>
          </CardContent>
        </Card>
      </Modal>
    </Toolbar>
  );
};

export default ShowActions;
