import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import UISettingsForm from '../common/Form';

const SettingsEdit = () => {
  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <UISettingsForm />
      </SimpleForm>
    </Edit>
  );
};

export default SettingsEdit;
