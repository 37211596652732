import React from 'react';
import { useTranslate } from 'react-admin';
import PaymentFilter from './PaymentFilter';
import SumFilter from './SumFilter';
import TimeFilter from './TimeFilter';
import { FILTER_TYPES } from '../../../config';

const PerkType = (props) => {
  const translate = useTranslate();
  const { type } = props;

  const paymentFilterSwitch = {
    [FILTER_TYPES.PAYMENT_FILTER]: <PaymentFilter {...props} />,
    [FILTER_TYPES.SUM_FILTER]: <SumFilter {...props} />,
    [FILTER_TYPES.TIME_FILTER]: <TimeFilter {...props} />,
  };

  if (!paymentFilterSwitch[type]) {
    return translate('ra.discount.cannotHandleFilter', { type });
  }

  return paymentFilterSwitch[type];
};

export default PerkType;
