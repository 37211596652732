import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const SettingsList = ({ ...props }) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.locale" source="locale" />
        <TextField label="ra.label.currency" source="currency" />
        <TextField label="ra.label.country" source="country" />
        <TextField label="ra.label.cityVelocity" source="cityVelocity" />
        <TextField label="ra.label.bufferTime" source="bufferTime" />
      </Datagrid>
    </List>
  );
};

export default SettingsList;
