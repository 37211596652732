import React from 'react';
import {
  SimpleForm,
  Create,
  SelectInput,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  email,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import CancelButton from '../../../components/CancelButton';
import PhoneInput from '../../../components/PhoneInput';
import ResourceTitle from '../../../components/ResourceTitle';
import {
  ALL_USER_ROLES_IDS as roleIds,
  ALL_USER_ROLES_NAMES as roleNames,
  adminRoleChoices,
} from '../../../config/user.config';

const validateRequired = required();
const validateEmail = [validateRequired, email()];

const UserCreate = (props) => {
  const { setValue } = useForm();

  const supplierIdFromLocalStorage = localStorage.getItem('supplierId');
  const role = localStorage.getItem('role');
  const isCurrentUserSupplierAdmin = role === roleNames.SUPPLIER_ADMIN;
  const isCurrentUserAdmin = role === roleNames.ADMIN;

  const onChangeUserName = (e) => {
    setValue('email', `${e.target.value}`);
  };
  const defaultRoleValue = isCurrentUserAdmin ? roleIds.ADMIN : roleIds.SUPPLIER_BRANCH;

  return (
    <Create
      {...props}
      title={<ResourceTitle field="username" />}
      actions={<CancelButton />}
      redirect="list"
    >
      <SimpleForm>
        <FormDataConsumer>
          {() => {
            return (
              <TextInput
                label="ra.label.email"
                source="email"
                sx={{ maxWidth: '300px', width: '100%' }}
                validate={validateEmail}
                inputProps={{
                  onChange: (e) => onChangeUserName(e),
                }}
              />
            );
          }}
        </FormDataConsumer>
        <PhoneInput source="phone" sx={{ maxWidth: '300px', width: '100%' }} shouldValidate />
        <TextInput
          label="ra.label.password"
          type="password"
          source="password"
          validate={validateRequired}
          sx={{ maxWidth: '300px', width: '100%' }}
        />
        <SelectInput
          label="ra.label.role"
          source="role"
          optionValue="value"
          choices={adminRoleChoices}
          defaultValue={defaultRoleValue}
          style={{ display: isCurrentUserAdmin ? 'flex' : 'none' }}
          sx={{ maxWidth: '300px', width: '100%' }}
          validate={validateRequired}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData.role !== roleIds.ADMIN &&
              formData.role !== roleIds.USER && (
                <ReferenceInput
                  {...rest}
                  label="ra.label.supplier"
                  source="supplierId"
                  reference="supplier"
                  perPage={10000}
                  defaultValue={isCurrentUserAdmin ? null : supplierIdFromLocalStorage}
                  validate={validateRequired}
                  style={{ display: isCurrentUserAdmin ? 'flex' : 'none' }}
                >
                  <SelectInput sx={{ maxWidth: '300px', width: '100%' }} optionText="name" />
                </ReferenceInput>
              )
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const selectedSupplier = isCurrentUserSupplierAdmin
              ? supplierIdFromLocalStorage
              : formData.supplierId;

            return (
              selectedSupplier &&
              formData.role === roleIds.SUPPLIER_BRANCH && (
                <ReferenceInput
                  {...rest}
                  label="ra.label.branch"
                  source="branchId"
                  reference="address"
                  perPage={250}
                  filter={{ supplierId: selectedSupplier }}
                  default=""
                  sx={{ maxWidth: '300px', width: '100%' }}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
