import React from 'react';
import { Typography } from '@mui/material';
import { TextInput, useRecordContext, useTranslate } from 'react-admin';
import SORTED_LOCALES from '../constants';
import { useOrganizationLocales } from '../../../hooks/useOrganizationLocales';

const TranslatableTextInputMgStructure = ({ source, label, ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext() || {};

  if (!record.translations) {
    record.translations = {};
  }

  const translations = Object.values(record.translations);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const textInputs = translations.reduce((accum, object, key) => {
    if (object.property !== source) {
      return accum;
    }

    return {
      ...accum,
      [object.locale]: (
        <div key={object.locale} className="ml-1r">
          <TextInput
            {...props}
            label={object.locale}
            sx={{ minWidth: '270px' }}
            source={`translations[${key}].value`}
            type="text"
          />
        </div>
      ),
    };
  }, {});

  const existingLocales = translations.map((object) => {
    return object.property === source ? object.locale : null;
  });

  const defaultLocales = useOrganizationLocales();

  const necessaryLocales = defaultLocales.filter((locale) => existingLocales.indexOf(locale) < 0);

  let length = existingLocales.length;

  const addonTextInputs = necessaryLocales.map((locale) => {
    if (record?.id) {
      translations.push({
        locale,
        value: '',
        property: source,
      });
    }

    const field = (
      <div key={locale + source} className="ml-1r">
        <TextInput
          {...props}
          label={locale}
          sx={{ minWidth: '270px' }}
          source={`S[${source}${length}]`}
          parse={(value) => ({ locale, value, property: source })}
          format={(translation) => (translation ? translation.value : '')}
          type="text"
        />
      </div>
    );

    length += 1;

    return field;
  });

  return (
    <div>
      <Typography className="mt-1r">{translate(label) || capitalize(source)}</Typography>
      {SORTED_LOCALES.map((orderedTranslationKey) => textInputs[orderedTranslationKey])}
      {addonTextInputs}
    </div>
  );
};

export default TranslatableTextInputMgStructure;
