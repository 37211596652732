import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import { ListTopToolBar } from '../../../components/ListTopToolBar';

const DeliverySettingsList = () => {
  return (
    <List
      bulkActionButtons={<ListDeleteConfirmationButton />}
      actions={<ListTopToolBar />}
    >
      <Datagrid rowClick="show">
        <TextField label="ra.label.name" source="name" />
      </Datagrid>
    </List>
  );
};

export default DeliverySettingsList;
