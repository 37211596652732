/* eslint-disable react/no-unstable-nested-components */
import { Button } from '@mui/material';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import InvoiceReportToPrint from './InvoiceReport';

const InvoicePDFReport = ({ reportData, dateRange, tableHeaders }) => {
  const orderReportRef = useRef(null);

  return (
    <div>
      {/* Button to generate the PDF */}
      <ReactToPrint
        trigger={() => (
          <Button variant="outlined" color="primary">
            Generate PDF report
          </Button>
        )}
        content={() => orderReportRef.current}
        documentTitle="Invoices order report"
      />
      <div style={{ display: 'none' }}>
        <InvoiceReportToPrint
          ref={orderReportRef}
          reportData={reportData}
          dateRange={dateRange}
          tableHeaders={tableHeaders}
        />
      </div>
    </div>
  );
};

export default InvoicePDFReport;
