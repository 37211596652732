import React from 'react';
import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const PaymentConfigShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.name" source="name" />
          <TextField label="ra.label.terminal" source="terminalId" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default PaymentConfigShow;
