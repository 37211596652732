import MoneyIcon from '@mui/icons-material/Money';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import {
  useRefresh,
  useTranslate,
} from 'react-admin';
import url from '../../../config/connection';
import REFUND_CULPRIT from '../../../config/refund.config';
import { postWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { styles } from './style';

export default function PartialRefund({
  record,
}) {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [isRefundModalOpen, setRefundModalOpen] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundReason, setRefundReason] = useState('');
  const [refundCulprit, setRefundCulprit] = useState('');

  const requestRefund = useCallback(() => {
    if (refundAmount < 1 || !refundReason.trim()) {
      return;
    }

    setRefundModalOpen(false);

    postWithAuthorization(
      `${url}/order/${record.id}/refund`,
      {
        body: JSON.stringify({
          amount: refundAmount * 100,
          reason: refundReason,
          culprit: refundCulprit,
        }),
      },
    ).then(() => {
      setRefundAmount(0);
      setRefundReason('');
      setRefundCulprit('');
    })
      .then(refresh);
  }, [refresh, record.id, refundAmount, refundReason, refundCulprit]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dialog open={isRefundModalOpen} onClose={() => setRefundModalOpen(false)}>
        <DialogTitle>{translate('ra.label.create_refund')}</DialogTitle>
        <DialogContent style={styles.flexColumn}>
          <TextField
            type="text"
            label={translate('ra.label.refund_reason')}
            required
            onChange={({ target }) => setRefundReason(target.value)}
            value={refundReason}
          />
          <TextField
            type="number"
            label={translate('ra.label.refund_amount')}
            required
            onChange={
              ({ target }) => setRefundAmount(target.value > 0 ? Number(target.value) : null)
            }
            value={refundAmount || ''}
          />
          <FormControl>
            <FormLabel>
              {translate('ra.label.refundCulprit')}
            </FormLabel>
            <RadioGroup
              row
              required
              onChange={({ target }) => setRefundCulprit(target.value)}
            >
              {Object.keys(REFUND_CULPRIT).map((key) => (
                <FormControlLabel
                  key={key}
                  value={REFUND_CULPRIT[key]}
                  control={<Radio />}
                  label={translate(`ra.label.${REFUND_CULPRIT[key].toLowerCase()}`)}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Button onClick={() => {
            requestRefund();
          }}
          >
            {translate('ra.action.refund')}
          </Button>
        </DialogContent>
      </Dialog>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setRefundModalOpen(true);
        }}
      >
        <MoneyIcon />
      </IconButton>
    </div>
  );
}
