import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { FormControlLabel, Checkbox, TextField } from '@mui/material';
import { DEFAULT_PERK_VALUES } from '../../../config';

const PercentPerk = ({ type, handlePerkCheck, checkedPerks, disabled }) => {
  const translate = useTranslate();
  const [amount, setAmount] = useState(DEFAULT_PERK_VALUES.PERCENT_PERK);
  const [checked, setChecked] = useState(false);

  const currentPerk = checkedPerks && checkedPerks.find((perkObject) => perkObject.type === type);

  useEffect(() => {
    setAmount(currentPerk?.amount || DEFAULT_PERK_VALUES.PERCENT_PERK);
    setChecked(!!currentPerk);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const e = {
      target: {
        value: type,
        checked,
      },
    };
    const perkOptions = {
      type,
      amount,
    };

    handlePerkCheck(e, perkOptions);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, checked]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() => setChecked(!checked)}
            color="primary"
            disabled={disabled}
          />
        }
        label={type}
      />
      <span>{translate('ra.discount.percentPerk.set')}</span>
      <TextField
        id="amount"
        variant="standard"
        value={amount}
        onChange={(e) => {
          setAmount(e.target.value > 100 ? 100 : e.target.value);
        }}
        disabled={disabled}
        type="text"
        style={{ width: '50px' }}
        inputProps={{ style: { textAlign: 'center' } }}
      />
      <span>%</span>
    </div>
  );
};

export default PercentPerk;
