import React from 'react';
import { TextField, Labeled, useTranslate, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import AbstractTranslatableInput from '../TranslatableInputs/AbstractTranslatableInput';
import { orderObjectByKeys } from '../helper';

const TranslatableTextField = ({ source, label }) => {
  const record = useRecordContext();

  if (!record.translations) {
    record.translations = [];
  }

  const translate = useTranslate();

  const translations = [];

  Object.keys(record.translations).map((key) => {
    return translations.push(record.translations[key]);
  });
  const TextFields = translations.reduce((accum, object) => {
    if (object.property !== source) {
      return accum;
    }

    return {
      ...accum,
      [object.locale]: (
        <div key={object.locale} className="ml-1r">
          <Labeled label={object.locale}>
            <TextField record={object} source="value" />
          </Labeled>
        </div>
      ),
    };
  }, {});

  const orderedTextFields = orderObjectByKeys(TextFields);

  return (
    <div>
      <Typography className="mt-1r">
        {translate(label) || AbstractTranslatableInput.Capitalize(source)}
      </Typography>
      {Object.values(orderedTextFields)}
    </div>
  );
};

export default TranslatableTextField;
