import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const AddressList = () => {
  return (
    <List>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.name" source="name" />
        <TextField label="ra.label.phone" source="phoneNumber" />
        <TextField label="ra.label.country" source="country" />
        <TextField label="ra.label.area" source="area" />
        <TextField label="ra.label.locality" source="locality" />
        <TextField label="ra.label.street" source="street" />
        <TextField label="ra.label.house" source="house" />
        <TextField label="ra.label.notes" source="notes" />
        <TextField label="ra.label.status" source="status" />
        <ReferenceField
          label="ra.label.supplier"
          source="supplierId"
          reference="supplier"
          linkType="show"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="ra.label.external" source="externalId" />
      </Datagrid>
    </List>
  );
};

export default AddressList;
