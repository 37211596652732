import { makeStyles, styled } from '@mui/styles';
import { Chip as MuiChip } from '@mui/material';

export const Chip = styled(MuiChip)({
  borderRadius: '4px',
  color: '#ffffff',
  fontWeight: 'bold',
  fontSize: '14px',
  textTransform: 'uppercase',
  marginRight: '10px',
  '& > span': {
    userSelect: 'auto',
  },
});

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  today: {
    fontWeight: 'bold',
    marginRight: '10px',
    textTransform: 'uppercase',
    transform: 'translateY(-2px)',
  },
  new: {
    backgroundColor: '#1D84B5',
  },
  scheduled: {
    backgroundColor: '#F7C137',
  },
  awaitingPayment: {
    color: '#000000',
  },
  delivered: {
    backgroundColor: '#41B48A',
  },
  canceled: {
    backgroundColor: '#E0777D',
  },
  total: {
    backgroundColor: '#477890',
  },
  sum: {
    background: '#19AC4B',
  },
});

export default useStyles;
