import React from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';

const validateRequired = required();

const LocaleEdit = () => {
  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <TextInput label="ra.label.locale" source="locale" resettable validate={validateRequired} />
        <TextInput label="ra.label.long" source="long" resettable validate={validateRequired} />
        <TextInput label="ra.label.short" source="short" resettable validate={validateRequired} />
      </SimpleForm>
    </Edit>
  );
};

export default LocaleEdit;
