import React from 'react';
import { ChipField, Datagrid, List, TextField } from 'react-admin';
import {
  ALL_USER_ROLES_IDS as roleIds,
  ALL_USER_ROLES_NAMES as roleNames,
} from '../../../config/user.config';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const role = localStorage.getItem('role');
const supplierId = localStorage.getItem('supplierId');

const CourierList = () => {
  let filter = {};

  if (role !== roleNames.ADMIN) {
    filter = { filter: { supplierId, role: roleIds.SUPPLIER_BRANCH } };
  }

  return (
    <List {...filter}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.first_name" source="firstName" />
        <TextField label="ra.label.last_name" source="lastName" />
        <TextField label="ra.label.phone" source="phone" />
        <ChipField label="ra.label.status" color="secondary" source="status" />
      </Datagrid>
    </List>
  );
};

export default CourierList;
