/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { useRecordContext, useRefresh, useTranslate } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';
import { patchMenu } from './utils';

const useStyles = makeStyles({
  active: (props) => ({
    '&:hover': {
      backgroundColor: `rgba(${props.backgroundColor}, 0.6)`,
    },
    backgroundColor: `rgba(${props.backgroundColor}, 1)`,
  }),
  inactive: (props) => ({
    '&:hover': {
      backgroundColor: `rgba(${props.backgroundColor}, 0.6)`,
    },
    backgroundColor: `rgba(${props.backgroundColor}, 1)`,
  }),
});

const ActivationCheckbox = ({ source }) => {
  const record = useRecordContext();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const value = !!record[source];

  const isActive = record?.isActive;
  const name = record?.name;

  const chipColor = {
    backgroundColor: isActive ? ' 76,187,23' : '255, 32, 47',
    color: 'white',
  };

  const classes = useStyles(chipColor);
  const translate = useTranslate();
  const refresh = useRefresh();

  const handleConfirmationClose = (e) => {
    e.stopPropagation();
    setIsConfirmationOpen(false);
  };

  const onClick = (e) => {
    e.stopPropagation();
    setIsConfirmationOpen(true);
  };

  const handleChange = (e) => {
    e.stopPropagation();

    patchMenu(record.id, {
      isActive: !value,
    }).then(() => refresh());
    setIsConfirmationOpen(false);
  };

  const props = {
    classes,
    onClick,
    checked: value,
  };

  return (
    <>
      <Chip
        {...props}
        label={translate(isActive ? 'ra.label.active' : 'ra.label.inactive')}
        className={isActive ? classes.active : classes.inactive}
        sx={{
          textTransform: 'uppercase',
          width: '170px',
          fontWeight: '700',
          fontSize: '13px',
          color: '#FFFFFF',
          lineHeight: '1.2',
          letterSpacing: '0.5px',
          paddingTop: '1px',
        }}
      />
      <Dialog
        onBackdropClick={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        open={isConfirmationOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {value
            ? `${translate('ra.message.menu_deactivate')}`
            : `${translate('ra.message.menu_activate')}`}
          <strong>{`${name}`}</strong>?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary">
            {translate('ra.action.disagree')}
          </Button>
          <Button onClick={handleChange} color="primary" autoFocus>
            {translate('ra.action.agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivationCheckbox;
