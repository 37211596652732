import { ORDER_STATUS_NAMES } from '../../../config/statuses.config';

export const courierAssignStatuses = [
  ORDER_STATUS_NAMES.PREPARING,
  ORDER_STATUS_NAMES.PREPARED,
  ORDER_STATUS_NAMES.CONFIRMED,
  ORDER_STATUS_NAMES.DELIVERING,
  ORDER_STATUS_NAMES.SCHEDULED,
];

export const courierConfirmedStatuses = [
  ORDER_STATUS_NAMES.COURIER_CONFIRMED,
  ORDER_STATUS_NAMES.COURIER_IS_IN_RESTAURANT,
];

export const STATUS_BTN_NAME_MAP = {
  [ORDER_STATUS_NAMES.PROCESSING]: 'processing',
  [ORDER_STATUS_NAMES.CONFIRMED]: 'confirmed',
  [ORDER_STATUS_NAMES.PREPARING]: 'preparing',
  [ORDER_STATUS_NAMES.PREPARED]: 'prepared',
  [ORDER_STATUS_NAMES.DELIVERING]: 'delivering',
  [ORDER_STATUS_NAMES.DELIVERED]: 'delivered',
  [ORDER_STATUS_NAMES.ACCEPTED]: 'accepted',
  [ORDER_STATUS_NAMES.CANCELED]: 'canceled',
  [ORDER_STATUS_NAMES.SCHEDULED]: 'scheduled',
};

export const courierSourceDetails = {
  STANDART: 'STANDART',
};
