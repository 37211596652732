import React from 'react';
import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { capitalize } from 'lodash';

const TranslatableLabel = ({ source, label }) => {
  const translate = useTranslate();

  if (label) {
    return (
      <Typography className="mt-1r">
        {translate(label) || capitalize(source)}
      </Typography>
    );
  }

  return null;
};

export default TranslatableLabel;
