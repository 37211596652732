import React from 'react';
import { List, Datagrid } from 'react-admin';
import ReferenceFieldCustome from '../ReferenceField';
import ReferenceAmountField from '../ReferenceAmountField';
import FilterReport from '../Filter';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import { ListTopToolBar } from '../../../components/ListTopToolBar';

const DiscountsList = () => {
  return (
    <List
      filters={<FilterReport />}
      bulkActionButtons={<ListDeleteConfirmationButton />}
      actions={<ListTopToolBar />}
    >
      <Datagrid>
        <ReferenceFieldCustome label="ra.label.name" />
        <ReferenceAmountField label="ra.label.amount_currency" />
      </Datagrid>
    </List>
  );
};

export default DiscountsList;
