import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { FormControlLabel, Checkbox, TextField } from '@mui/material';
import { DEFAULT_FILTER_VALUES } from '../../../config';
import { CURRENCY_NAME } from '../../../../config/currency.config';

const PercentFilter = ({ type, handleFilterCheck, checkedFilters, disabled }) => {
  const translate = useTranslate();
  const [options, setOptions] = useState(DEFAULT_FILTER_VALUES.SUM_FILTER);
  const [checked, setChecked] = useState(false);

  const currentFilter =
    checkedFilters && checkedFilters.find((filterObject) => filterObject.type === type);

  useEffect(() => {
    setOptions(currentFilter || DEFAULT_FILTER_VALUES.SUM_FILTER);
    setChecked(!!currentFilter);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const e = {
      target: {
        value: type,
        checked,
      },
    };
    const filterOptions = {
      type,
      ...options,
    };

    handleFilterCheck(e, filterOptions);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, checked]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() => setChecked(!checked)}
            color="primary"
            disabled={disabled}
          />
        }
        label={type}
      />
      <span>{translate('ra.discount.sumFilter.set')}</span>
      <TextField
        id="minimumAmount"
        value={options.minimumAmount / 100}
        onChange={({ target: { value } }) => {
          setOptions(() => ({ minimumAmount: value < 1 ? 1 : value * 100 }));
        }}
        disabled={disabled}
        type="text"
        sx={{ width: '50px', textAlign: 'center', marginLeft: '10px' }}
        variant="standard"
      />
      <span style={{ marginLeft: '10px' }}>{CURRENCY_NAME}</span>
    </div>
  );
};

export default PercentFilter;
