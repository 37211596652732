import React, { useEffect, useState } from 'react';
import { AutocompleteInput, required } from 'react-admin';
import { baseUrl } from '../../config/connection';
import { fetchWithAuthorization } from '../../utils/fetchWithAuthorization';

const validateRequired = required();

const SupplierAutocomplete = ({ sx: styles, validate, ...props }) => {
  const [choices, setChoices] = useState([]);

  const hasStylesProp = styles !== undefined;

  const applyStyles = () => {
    return hasStylesProp ? styles : { maxWidth: '218px', width: '100%' };
  };

  useEffect(() => {
    fetchWithAuthorization(`${baseUrl}/admin/supplier`)
      .then((res) => res.json())
      .then((res) => setChoices(res));
  }, []);

  return (
    <AutocompleteInput
      {...props}
      alwaysOn
      emptyText=""
      clearOnBlur
      clearOnEscape
      label="ra.label.supplier"
      optionText="name"
      optionValue="id"
      source="supplierId"
      noOptionsText="Loading..."
      suggestionLimit={30}
      choices={[
        {
          id: null,
          name: 'ALL/Empty',
        },
        ...choices,
      ]}
      validate={validate ? validateRequired : false}
      sx={applyStyles()}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id && option.name === value.name;
      }}
    />
  );
};

export default SupplierAutocomplete;
