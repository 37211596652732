export const SUPPLIER_PAYOUT = {
  BRANCH: 'BRANCH',
  MAINOFFICE: 'MAINOFFICE',
};

export const supplierTypes = [
  { id: 'RESTAURANT', name: 'Restaurant' },
  { id: 'SHOP', name: 'Shop' },
];
export const supplierPayout = [
  { id: SUPPLIER_PAYOUT.BRANCH, name: 'Branch' },
  { id: SUPPLIER_PAYOUT.MAINOFFICE, name: 'Main Office' },
];
export const courierSource = [
  { id: 'STANDART', name: 'STANDART' },
  { id: 'SUPPLIER', name: 'SUPPLIER' },
];
export const statuses = [
  { id: 'HIDDEN', name: 'HIDDEN' },
  { id: 'IN_PROGRESS', name: 'IN_PROGRESS' },
  { id: 'OPEN', name: 'OPEN' },
  { id: 'CLOSED', name: 'CLOSED' },
  { id: 'CLOSED_OWNER', name: 'CLOSED_OWNER' },
  { id: 'CLOSED_ADMIN', name: 'CLOSED_ADMIN' },
];
