import React, { useEffect, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DiscountSection from './DiscountSection/index';

import commonStyles from '../styles';
import useStyles from './styles';
import { baseUrl } from '../../config/connection';
import { fetchWithAuthorization, patchWithAuthorization } from '../../utils/fetchWithAuthorization';

const requestTypes = {
  apply: 'APPLY',
  dismiss: 'DISMISS',
};

const useCommonStyles = makeStyles(commonStyles);

const countItems = (sections) => sections.reduce((acc, { goods }) => acc + goods.length, 0);

const getIdsFromSection = ({ goods }) => goods.map(({ id }) => id);

const getAllIds = (sections) => new Set(sections.flatMap(getIdsFromSection));

const addSectionToSelected = (selected, goods) =>
  new Set([...selected, ...goods.map(({ id }) => id)]);

const DiscountTab = ({ sections: initialSections, menuId }) => {
  const [sections, setSections] = useState(initialSections);
  const [selected, setSelected] = useState(new Set());
  const [discount, setDiscount] = useState('');
  const [discounts, setDiscounts] = useState([]);
  const [formErr, setFormErr] = useState(false);
  const commCls = useCommonStyles();
  const styles = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  useEffect(() => {
    fetchWithAuthorization(`${baseUrl}/admin/discount/`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error();
      })
      .then((res) => setDiscounts(res.data.filter((disc) => disc.isActive)))
      .catch(() => notify(translate('ra.notification.get_discounts_err'), { type: 'warning' }));
  }, [notify, translate]);

  const selectAll = () => {
    setSelected(getAllIds(sections));
  };
  const selectSection = ({ goods }) => {
    setSelected(addSectionToSelected(selected, goods));
  };
  const removeSection = ({ goods }) => {
    const result = new Set(selected);
    goods.forEach(({ id }) => result.delete(id));

    setSelected(result);
  };
  const selectItem = (id) => {
    const result = new Set(selected);
    result.has(id) ? result.delete(id) : result.add(id);

    setSelected(result);
  };

  const selectDiscount = (e) => setDiscount(e.target.value);

  const changeDiscount = (type) => {
    const isApply = type === requestTypes.apply;

    if (isApply && !discount) {
      setFormErr(true);

      return;
    }
    setFormErr(false);

    if (!selected.size) {
      notify(translate('ra.notification.no_selected_discount_items'), { type: 'warning' });

      return;
    }

    const options = {
      method: 'PATCH',
      body: JSON.stringify({
        goodsIds: [...selected],
      }),
    };

    const requestBase = `${baseUrl}/admin/menu/${menuId}/discount/`;
    const requestSpecifics = isApply ? `${discount}/apply` : 'dismiss';

    patchWithAuthorization(`${requestBase}${requestSpecifics}`, options)
      .then((res) => {
        if (res.ok) {
          const notificationName = isApply ? 'set_discount' : 'remove_discount';
          notify(translate(`ra.notification.${notificationName}`));

          return res.json();
        }

        throw new Error();
      })
      .then((res) => {
        setSections(res.sections);
        setSelected(new Set());
      })
      .catch(() => notify(translate('ra.notification.http_error'), { type: 'warning' }));
  };

  return (
    <div>
      <div className={`${styles.actions} ${commCls.sectionWrapper}`}>
        <Button
          onClick={() => changeDiscount(requestTypes.dismiss)}
          variant="contained"
          color="primary"
          className={commCls.actionButton}
        >
          {translate('ra.action.remove_discount')}
        </Button>
        <Button
          onClick={() => changeDiscount(requestTypes.apply)}
          variant="contained"
          color="primary"
          className={commCls.actionButton}
        >
          {translate('ra.action.set_discount')}
        </Button>
        <div className={styles.selectorWrapper}>
          <FormControl className={styles.discountSelector} required error={formErr}>
            <InputLabel>{translate('ra.label.discount')}</InputLabel>
            <Select value={discount} onChange={selectDiscount} variant="standard">
              {discounts.map(({ id, name }) => (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            {formErr && (
              <FormHelperText>{translate('ra.label.set_discount_form_err')}</FormHelperText>
            )}
          </FormControl>
        </div>
        <Box mr={2}>
          <Typography variant="h6">
            {translate('ra.label.selected_count', {
              count: selected.size,
              all: countItems(sections),
            })}
          </Typography>
        </Box>
        <Button
          onClick={selectAll}
          variant="contained"
          color="primary"
          className={commCls.actionButton}
        >
          {translate('ra.action.select_all')}
        </Button>
        <Button
          onClick={() => setSelected(new Set())}
          variant="contained"
          color="primary"
          className={commCls.actionButton}
        >
          {translate('ra.action.remove_all')}
        </Button>
      </div>
      <div>
        {sections.map((section) => {
          return (
            <DiscountSection
              section={section}
              discounts={discounts}
              key={section.id}
              selectSection={(e) => {
                e.stopPropagation();
                selectSection(section);
              }}
              removeSection={(e) => {
                e.stopPropagation();
                removeSection(section);
              }}
              selectItem={selectItem}
              selected={selected}
              setSections={setSections}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DiscountTab;
