import React from 'react';
import { BooleanField, Datagrid, List, ReferenceField, TextField } from 'react-admin';
import PriceField from '../../../components/PriceField';
import SupplierFilter from '../../../components/SupplierFilter';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import { ListTopToolBar } from '../../../components/ListTopToolBar';
import { useUiSettings } from '../../../hooks/useUiSettings';

const OptionsList = () => {
  const uiSettings = useUiSettings();

  return (
    <List
      filters={<SupplierFilter />}
      bulkActionButtons={<ListDeleteConfirmationButton />}
      actions={<ListTopToolBar />}
    >
      <Datagrid rowClick="show">
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.name" source="name" />
        <PriceField
          label="ra.label.price"
          source="price"
          parse={(v) => v / 100}
          options={{
            style: 'currency',
            currency: uiSettings.currency,
          }}
        />
        <ReferenceField label="ra.label.supplier" source="supplierId" reference="supplier">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="ra.label.external" source="externalId" />
        <BooleanField label="ra.label.active" source="isActive" />
        <BooleanField label="ra.label.is_free_option" source="isFreeOption" />
      </Datagrid>
    </List>
  );
};

export default OptionsList;
