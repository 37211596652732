import React from 'react';
import {
  BooleanField,
  NumberField,
  ReferenceField,
  Show,
  TextField,
  SimpleShowLayout,
} from 'react-admin';

const PromoCodeShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="title" name="title" />
        <ReferenceField source="discount._id" reference="discount">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="maxUsage" name="maxUsage" />
        <NumberField source="maxUsagePerUser" name="maxUsagePerUser" />
        <BooleanField source="isActive" name="isActive" />
      </SimpleShowLayout>
    </Show>
  );
};

export default PromoCodeShow;
