import React from 'react';
import { BooleanField, Datagrid, List, TextField, ReferenceField } from 'react-admin';

const PromoCodeList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="title" />
      <ReferenceField source="discount" reference="discount">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="isActive" />
    </Datagrid>
  </List>
);

export default PromoCodeList;
