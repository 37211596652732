import React from 'react';
import { BooleanInput, Edit, minValue, NumberInput, required, SimpleForm } from 'react-admin';
import { TranslatableTextInput } from '../../../components/Translatable';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();

const ServiceFeesEdit = () => (
  <Edit actions={<CancelButton />}>
    <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
      <TranslatableTextInput label="ra.label.title" source="title" validate={validateRequired} />
      <NumberInput
        parse={(v) => v * 100 || ''}
        format={(v) => v / 100 || ''}
        label="ra.label.feeAmount"
        source="feeAmount"
        validate={minValue(0)}
        sx={{ maxWidth: '270px', width: '100%' }}
      />
      <NumberInput
        parse={(v) => v * 100 || ''}
        format={(v) => v / 100 || ''}
        label="ra.label.feeThreshold"
        source="feeThreshold"
        validate={minValue(1)}
        sx={{ maxWidth: '270px', width: '100%' }}
      />
      <BooleanInput label="ra.label.active" source="isActive" />
    </SimpleForm>
  </Edit>
);

export default ServiceFeesEdit;
