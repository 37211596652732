import RESOURCES_NAMES from '../../../config/resources.config';

export const MAIN_PART = [
  RESOURCES_NAMES.ORDER,
  RESOURCES_NAMES.ORDER_SCHEDULED,
  RESOURCES_NAMES.ORDER_HISTORY,
];

export const SUPPLIER_MANAGEMENT = [
  RESOURCES_NAMES.SUPPLIER,
  // RESOURCES_NAMES.ADDRESS,
  // RESOURCES_NAMES.STORE,
];

export const MENU_MANAGEMENT = [
  RESOURCES_NAMES.CATEGORY,
  // RESOURCES_NAMES.GOOD,
  // RESOURCES_NAMES.OPTION_GROUP,
  // RESOURCES_NAMES.OPTION,
  RESOURCES_NAMES.MENU,
];

export const CMS = [
  RESOURCES_NAMES.TAGS,
  RESOURCES_NAMES.NAVIGATION,
  RESOURCES_NAMES.STATICS,
  RESOURCES_NAMES.BRANDING,
  RESOURCES_NAMES.BANNERS,
  RESOURCES_NAMES.HTML_SEO,
  RESOURCES_NAMES.FOOTER,
  RESOURCES_NAMES.SETTINGS,
  RESOURCES_NAMES.SERVICE_FEES,
];

export const MARKETING = [
  RESOURCES_NAMES.PROMOCODE,
  RESOURCES_NAMES.DISCOUNT,
  RESOURCES_NAMES.DISCOUNT_REPORT,
  RESOURCES_NAMES.CAMPAIGNS,
  RESOURCES_NAMES.RUN,
];

export const USER_MANAGEMENT = [
  RESOURCES_NAMES.USER,
  RESOURCES_NAMES.USER_ADDRESS,
  RESOURCES_NAMES.USER_DELETING,
];

export const COMPANY_MANAGEMENT = [RESOURCES_NAMES.COMPANY];

export const I18 = [
  RESOURCES_NAMES.LOCALE,
  RESOURCES_NAMES.TRANSLATIONS,
];

export const PAYMENT_CONFIG = [
  RESOURCES_NAMES.TRANSACTION,
  // RESOURCES_NAMES.PAYMENT_CONFIG,
];

export const DELIVERY_MANAGEMENT = [
  RESOURCES_NAMES.DELIVERY_SETTINGS,
  RESOURCES_NAMES.COURIER,
  RESOURCES_NAMES.POLICY,
];

export const NOTIFICATIONS_MANAGEMENT = [
  RESOURCES_NAMES.NOTIFICATION,
  RESOURCES_NAMES.NOTIFICATION_LOGGING,
];

export const DOCUMENT_MANAGEMENT = [RESOURCES_NAMES.DOCUMENT];
