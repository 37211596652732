import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha } from '@mui/material/styles';
import { useDeleteMany } from 'react-admin';

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    selectedOptionGroupIds,
    handleChangeOptionGroup: setOptionGroups,
    handleSelectedItems: setSelected,
  } = props;

  const [deleteMany] = useDeleteMany();

  const handleDelete = () => {
    deleteMany(
      'optionGroup',
      { ids: selectedOptionGroupIds },
      {
        onSuccess: () => {
          setOptionGroups((prev) => {
            return prev.filter((item) => !selectedOptionGroupIds.includes(item.id));
          });
          setSelected([]);
        },
      },
    );
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected}
          &nbsp;selected
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete} color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
