import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import NotificationHtmlForm from '../common/Form';

const NotificationCreate = () => {
  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm redirect="show">
        <NotificationHtmlForm />
      </SimpleForm>
    </Create>
  );
};

export default NotificationCreate;
