import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, FormControl, Button } from '@mui/material';
import { useTranslate } from 'react-admin';
import styles from './styles';
import { useOrganizationLocales } from '../hooks/useOrganizationLocales';

const SectionTitleEditDialog = ({ translatable, sectionId, onClose }) => {
  const [translations, setTranslations] = useState(translatable || []);
  const locales = useOrganizationLocales();
  const translate = useTranslate();

  useEffect(() => {
    if (translations.length < 3) {
      const newTranslations = locales.map((locale) => ({
        locale,
        className: 'Section',
        objectId: sectionId,
        property: 'label',
        value: '',
      }));

      setTranslations(newTranslations);
    }
  }, [locales, sectionId, translations.length]);

  const handleChange = (locale, value) => {
    const idx = translations.findIndex((translation) => translation.locale === locale);
    const translation = translations[idx];
    translation.value = value;

    const newTranslations = [...translations];
    newTranslations[idx] = translation;

    setTranslations([...newTranslations]);
  };

  return (
    <Dialog open={!!sectionId} onClose={() => onClose(translations)}>
      <DialogTitle>{translate('ra.action.menu_edit_section_title')}</DialogTitle>
      <DialogContent style={styles.flexColumn}>
        <FormControl>
          {translations.map((translation) => (
            <TextField
              variant="standard"
              sx={{ marginTop: '20px' }}
              key={translation.locale}
              label={translation.locale}
              placeholder={translation.locale}
              value={translation.value}
              onChange={(e) => {
                handleChange(translation.locale, e.target.value);
              }}
            />
          ))}
        </FormControl>
        <FormControl style={styles.modalSaveBtn}>
          <Button variant="contained" color="primary" onClick={() => onClose(translations)}>
            {translate('ra.action.save')}
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default SectionTitleEditDialog;
