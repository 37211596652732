import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import IOSSwitch from './styles';
import { postWithAuthorization } from '../../../../utils/fetchWithAuthorization';
import url from '../../../../config/connection';

export default function ActiveOptionGroupSwitcher({ record, goodId, handleChangeOptionGroup }) {
  const handleChange = (checked, optionGroupId) => {
    postWithAuthorization(`${url}/optionGroup/good/assign`, {
      body: JSON.stringify({
        goodId,
        assigned: checked,
        optionGroupId,
      }),
    }).then(() =>
      handleChangeOptionGroup((prev) => {
        return prev.map((item) => {
          if (item.id === optionGroupId) {
            item.isActive = checked;
          }

          return item;
        });
      }));
  };

  return (
    <FormControlLabel
      control={<IOSSwitch sx={{ m: 1 }} defaultChecked={record.isActive} />}
      onChange={(e) => handleChange(e.target.checked, record.id)}
    />
  );
}
