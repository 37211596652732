import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const CompanyList = () => {
  return (
    <List>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.name" source="legalName" />
        <TextField label="ra.label.email" source="email" />
        <TextField label="ra.label.phone" source="phoneNumber" />
        <TextField label="ra.label.contract" source="contractNumber" />
      </Datagrid>
    </List>
  );
};

export default CompanyList;
