import React from 'react';
import { List, Datagrid, TextField, ChipField, FunctionField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import formatDateTime from '../../../utils/formatDateTime';
import ProcessTransactionButton from '../ProcessTransaction';
import TransactionDataDropdown from '../TransactionDataDropdown';

const divideAmount = (amount) => {
  return (amount / 100).toFixed(2).replace('.', ',');
};

const TransactionList = () => {
  return (
    <List sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid
        expand={<TransactionDataDropdown />}
        bulkActionButtons={<ListDeleteConfirmationButton />}
      >
        <ChipField label="ra.label.status" source="status" sortable={false} />
        <FunctionField
          label="ra.label.rrn"
          render={({ transactionData }) => {
            return transactionData.rrn || transactionData.RRN;
          }}
        />
        <FunctionField
          label="ra.label.sum"
          render={({ transactionData }) => {
            return divideAmount(transactionData.amount || transactionData.AMOUNT);
          }}
        />
        <FunctionField
          label="ra.label.created_at"
          render={({ createdAt }) => {
            const { time, date } = formatDateTime(createdAt, -2);

            return `${date} ${time}`;
          }}
        />
        <TextField label="ra.label.order_id" source="orderId" sortable={false} />
        <ProcessTransactionButton />
      </Datagrid>
    </List>
  );
};

export default TransactionList;
