import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import {
  Form,
  useNotify,
} from 'react-admin';
import Editor from '@monaco-editor/react';
import {
  fetchWithAuthorization,
  putWithAuthorization,
} from '../utils/fetchWithAuthorization';
import url from '../config/connection';
import FormWithPreview from '../components/FormWithPreview';
import { FooterSchema } from './constants';

const UIFooter = () => {
  const [footerJSON, setFooterJSON] = useState('');
  const [isValid, setIsValid] = useState(true);

  const previewData = useMemo(() => {
    let footerData;

    try {
      footerData = JSON.parse(footerJSON);
    } catch {
      footerData = { desktop: [], mobile: [] };
    }

    return { footerData };
  }, [footerJSON]);

  const notify = useNotify();

  useEffect(() => {
    const getFooterJSON = async () => {
      const response = await fetchWithAuthorization(`${url}/ui/footer`);

      if (!response.ok) {
        notify('Could not fetch the footer data', { type: 'error' });

        return;
      }

      const data = await response.json();

      const stringifiedData = JSON.stringify(data, null, 2);

      setFooterJSON(stringifiedData);
    };

    getFooterJSON();
  }, [notify]);

  const handleSaveFooter = () => {
    return putWithAuthorization(`${url}/ui/footer`, {
      body: JSON.stringify({
        file: footerJSON,
      }),
    })
      .then(() => notify('Saved', { type: 'success' }))
      .catch(() => notify('Something went wrong', { type: 'error' }));
  };

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Form onSubmit={handleSaveFooter}>
        <FormWithPreview
          orientation="column-reverse"
          previewData={previewData}
          previewStyles={`
            app-root > *:not(app-footer) {
              display: none !important;
            }`}
        >
          <Editor
            height="750px"
            language="json"
            value={footerJSON}
            onMount={(_, monaco) => {
              monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                validate: true,
                trailingCommas: 'error',
                schemaValidation: 'error',
                allowComments: true,
                schemas: [{
                  uri: '',
                  fileMatch: ['*'],
                  schema: FooterSchema,
                }],
              });
            }}
            onChange={setFooterJSON}
            onValidate={(errorMarkers) => { setIsValid(errorMarkers.length === 0); }}
          />

          <Button
            type="submit"
            variant="contained"
            disabled={!isValid}
            sx={{ margin: '20px 0' }}
          >
            Save
          </Button>
        </FormWithPreview>
      </Form>
    </Box>
  );
};

export default UIFooter;
