import StoreList from './components/List';
import StoreCreate from './components/Create';
import StoreEdit from './components/Edit';
import StoreShow from './components/Show';

export default {
  list: StoreList,
  create: StoreCreate,
  edit: StoreEdit,
  show: StoreShow,
};
