import React from 'react';
import { Button } from '@mui/material';
import { useRecordContext, useRefresh, useTranslate } from 'react-admin';
import url from '../../../config/connection';
import { postWithAuthorization } from '../../../utils/fetchWithAuthorization';

const ProcessTransactionButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const translate = useTranslate();

  const processTransaction = (e) => {
    postWithAuthorization(`${url}/transaction/${record?.id}/process`)
      .then((res) => res.json())
      .then(() => refresh())
      .catch((error) => {
        throw new Error(error);
      });
  };

  return record?.status === 'AUTH' && (
    <Button
      variant="contained"
      color="primary"
      style={{ backgroundColor: '#325E8C' }}
      onClick={(e) => processTransaction(e)}
    >
      {translate('ra.action.process_transaction')}
    </Button>
  );
};

export default ProcessTransactionButton;
