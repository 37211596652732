import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  BooleanField,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { ReactSortable } from 'react-sortablejs';

import { Visibility } from '@mui/icons-material';
import AddResourceButton from '../../../components/AddResourceButton';
import { TranslatableTextField } from '../../../components/Translatable';
import url from '../../../config/connection';
import {
  fetchWithAuthorization,
  patchWithAuthorization,
  postWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import OptionGroupTitle from '../OptionGroupTitle';
import ReferenceOptionsField from '../ReferenceOptionsField';
import { useUiSettings } from '../../../hooks/useUiSettings';

const OptionsTab = () => {
  const record = useRecordContext();

  const [options, setOptions] = useState([]);
  const [initialOptions, setInitialOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const translate = useTranslate();
  const redirect = useRedirect();
  const uiSettings = useUiSettings();

  useEffect(() => {
    fetchWithAuthorization(`${url}/option/sortedOptions/${record?.id}`)
      .then((response) => response.json())
      .then((response) => {
        setOptions(response);
        setInitialOptions([...response]);
      });
  }, [record?.id]);

  useEffect(() => {
    const shouldDisableSaveButton = initialOptions.every((_, index) => {
      const initialItem = initialOptions[index];
      const sortedItem = options[index];

      const isSameItem = sortedItem.id === initialItem.id;

      return isSameItem;
    });

    setIsDisabled(shouldDisableSaveButton);
  }, [initialOptions, options, setIsDisabled]);

  const handleUpdate = async () => {
    await postWithAuthorization(`${url}/option/updateSorting`, {
      body: JSON.stringify([...options]),
    }).then(() => {
      setIsDisabled(true);
    });

    await patchWithAuthorization(`${url}/optionGroup/${record?.id}`, {
      body: JSON.stringify(record),
    });
  };

  return (
    <TableContainer>
      <Button
        variant="contained"
        size="medium"
        startIcon={<SaveIcon />}
        disabled={isDisabled}
        onClick={handleUpdate}
        sx={{ display: 'flex', marginLeft: 'auto' }}
      >
        {translate('ra.action.save')}
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{translate('ra.label.name')}</TableCell>
            <TableCell>{translate('ra.label.price')}</TableCell>
            <TableCell sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {translate('ra.action.edit')}
            </TableCell>
          </TableRow>
        </TableHead>
        <ReactSortable list={options} setList={setOptions} animation={100} tag="tbody">
          {options?.map((row) => {
            return (
              <TableRow key={row.id} hover>
                <TableCell>{row.name}</TableCell>
                <TableCell>{`${row.price} ${uiSettings.currency}`}</TableCell>
                <TableCell>
                  <Box sx={{ border: 'none', display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={() => redirect(`/option/${row.id}`)} size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => redirect(`/option/${row.id}/show`)}
                      size="small"
                    >
                      <Visibility />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </ReactSortable>
      </Table>
    </TableContainer>
  );
};

const OptionGroupShow = () => {
  const translate = useTranslate();

  return (
    <Show title={<OptionGroupTitle />}>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TranslatableTextField label="ra.label.name" source="name" />
          <BooleanField label="ra.label.is_multiple" source="isMultiple" />
          <BooleanField label="ra.label.is_required" source="required" />
          <FunctionField
            label="ra.label.maxSelectedOptions"
            render={({ maxSelectedOptions }) => {
              return !maxSelectedOptions ? translate('ra.label.unlimited') : maxSelectedOptions;
            }}
          />
          <TextField label="ra.label.external" source="externalId" />
          <ReferenceOptionsField />
        </Tab>
        <Tab label="ra.tab.options">
          <OptionsTab />
          <AddResourceButton
            label="ra.action.add_option"
            to="/option/create"
            source="optionGroupId"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default OptionGroupShow;
