import React, { useMemo } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { ReactComponent as AssignIcon } from './icons/assignIcon.svg';
import { ReactComponent as ReAssignIcon } from './icons/reassignIcon.svg';
import { ReactComponent as NotConfirmedIcon } from './icons/notConfirmedIcon.svg';
import { setCourierAssignOrder } from '../../../redux/customReducers/utils';
import useStyles from './styles';

const AssignCourierButton = ({ isReAssign, isCourierConfirmed, order, setOrder }) => {
  const courier = useMemo(() => order.courier, [order]);
  const styles = useStyles();
  const translate = useTranslate();

  let button = {
    tooltip: 'ra.title.assign_courier',
    icon: AssignIcon,
  };

  if (isReAssign) {
    button = {
      tooltip: 'ra.title.re_assign_courier',
      icon: ReAssignIcon,
    };
  }

  if (isReAssign && !isCourierConfirmed) {
    button = {
      tooltip: 'ra.title.not_confirmed_courier',
      icon: NotConfirmedIcon,
    };
  }

  const Icon = button.icon;

  return (
    <Tooltip
      title={
        <>
          <div>{translate(button.tooltip)}</div>
          {!!courier && isReAssign && (
            <div>
              {`${translate('ra.label.current_courier')}: ${courier.firstName} ${courier.lastName}`}
            </div>
          )}
        </>
      }
    >
      <IconButton
        className={styles.root}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setOrder(order);
        }}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
};

const mapDispatch = { setOrder: setCourierAssignOrder };

export default connect(null, mapDispatch)(AssignCourierButton);
