import React, { useEffect, useState } from 'react';
import {
  Button,
  Edit,
  required,
  SelectInput,
  TextInput,
  TopToolbar,
  useEditController,
  useRedirect,
  useResourceContext,
  useTranslate,
  useUpdate,
  TabbedForm,
  FormTab,
  NumberInput,
  useGetOne,
} from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import GoogleMap from 'google-map-react';
import { useForm, useWatch } from 'react-hook-form';
import PhoneInput from '../../../components/PhoneInput';
import ResourceTitle from '../../../components/ResourceTitle';
import { ScheduleInput } from '../../../components/Schedule';
import { adressStatuses } from '../../constants';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import ClientPlacemark from '../../../components/maps/ClientPlacemark';
import { DEFAULT_GEO, DEFAULT_ZOOM, GOOGLE_MAPS_API_KEY } from '../../../config/maps.config';
import SupplierAutocomplete from '../../../components/SupplierAutocomplete';
import { SUPPLIER_PAYOUT } from '../../../config/supplier.config';

const validateRequired = required();
const defaultGeo = { lat: DEFAULT_GEO[0], lng: DEFAULT_GEO[1] };

const AddressEditActions = ({ record }) => {
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleRedirect = () => {
    redirect(`/supplier/${record.supplierId}/show/addresses`);
  };

  return (
    <TopToolbar>
      <Button
        sx={{ padding: '10px' }}
        onClick={handleRedirect}
        label={translate('ra.action.cancel')}
      >
        <CloseIcon />
      </Button>
    </TopToolbar>
  );
};

const AddressEdit = () => {
  const [financialTabOpen, setFinancialTabOpen] = useState(false);

  const editControlerProps = useEditController();
  const { data: supplier } = useGetOne('supplier', { id: editControlerProps?.record?.supplierId });

  const { control, setValue } = useForm();
  const resource = useResourceContext();

  const [update] = useUpdate();
  const redirect = useRedirect();

  const values = useWatch({
    control,
  });

  const setGeoForm = (newGeo) => {
    setValue('geo', newGeo);
  };

  const handleUpdate = (data) => {
    update(
      resource,
      {
        id: data.id,
        data: {
          ...editControlerProps?.record,
          ...data,
          ...values,
        },
        previousData: editControlerProps?.record,
      },
      {
        onSuccess: () => {
          redirect(`/supplier/${data.supplierId}/show/addresses`);
        },
      },
    );
  };
  useEffect(() => {
    if (supplier) setFinancialTabOpen(supplier.payout === SUPPLIER_PAYOUT.BRANCH);
  }, [supplier]);

  return (
    <Edit
      title={<ResourceTitle field="name" />}
      redirect={(_, __, data) => {
        return `supplier/${data?.supplierId}/show/addresses`;
      }}
      actions={<AddressEditActions record={editControlerProps?.record} />}
    >
      <TabbedForm toolbar={<EditDeleteConfirmationButton />} onSubmit={handleUpdate}>
        <FormTab label="ra.tab.general">
          <TextInput label="ra.label.name" source="name" validate={validateRequired} />
          <TextInput label="ra.label.notes" source="notes" />
          <PhoneInput source="phoneNumber" shouldValidate />
          <TextInput label="ra.label.external" source="externalId" />
          <SupplierAutocomplete source="supplierId" validate />
          <SelectInput
            label="ra.label.status"
            source="status"
            choices={adressStatuses}
            validate={validateRequired}
          />
          <ScheduleInput source="schedule" setValue={setValue} />
          <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap', gap: 16 }}>
            <TextInput label="ra.label.country" source="country" validate={validateRequired} />
            <TextInput label="ra.label.area" source="area" validate={validateRequired} />
            <TextInput label="ra.label.locality" source="locality" validate={validateRequired} />
            <TextInput label="ra.label.street" source="street" validate={validateRequired} />
            <TextInput label="ra.label.house" source="house" validate={validateRequired} />
          </div>
          <div style={{ display: 'flex', flex: '1', flexWrap: 'wrap', gap: 16 }}>
            <NumberInput
              label="Lat"
              source="geo.lat"
              validate={validateRequired}
              control={control}
            />
            <NumberInput
              label="Lng"
              source="geo.lng"
              validate={validateRequired}
              control={control}
            />
          </div>

          <div style={{ height: '700px', width: '100%' }}>
            <GoogleMap
              bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
              center={values.geo || defaultGeo}
              defaultZoom={DEFAULT_ZOOM}
              onClick={({ lat, lng }) => {
                const geo = { lat, lng };
                setGeoForm(geo);
              }}
              control={control}
            >
              <ClientPlacemark {...values.geo} />
            </GoogleMap>
          </div>
        </FormTab>
        {financialTabOpen && (
          <FormTab label="ra.tab.financial">
            <TextInput label="ra.label.email" source="email" />
            <TextInput
              source="bankAccount"
              label="ra.label.bank_account_number"
            />
            <TextInput label="CVR" source="cvrNumber" />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default AddressEdit;
