import React from 'react';
import { ChipField, ReferenceArrayField, SingleFieldList } from 'react-admin';

const ReferenceOptionsField = ({ record }) => {
  return (
    record?.itemsIds?.length > 0 && (
      <ReferenceArrayField
        source="itemsIds"
        resource="option"
        record={record}
        basePath="/option"
        reference="option"
        allowEmpty
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    )
  );
};

export default ReferenceOptionsField;
