import React, { useCallback, useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import useStyles from './styles';
import FileList from './FileList';
import 'react-image-lightbox/style.css';

const S3FileField = (props) => {
  const { apiRoot, fileCoverImg, input, source } = props;

  const classes = useStyles();
  const record = useRecordContext();

  const [fileList, setFilelist] = useState([]);

  const getFilesFromProps = useCallback(() => {
    if (input) {
      return input.value || [];
    }

    if (record) {
      return record[source] || [];
    }

    return [];
  }, [input, record, source]);

  const deleteFile = useCallback(
    (fileName) => {
      const newFileList = fileList?.filter((file) => file.name !== fileName);
      input.onChange(newFileList);
      setFilelist(newFileList);
    },
    [fileList, input],
  );

  useEffect(() => {
    setFilelist(getFilesFromProps());
  }, [getFilesFromProps]);

  return (
    <div className={classes.wrapper}>
      <FileList
        apiRoot={apiRoot}
        fileCoverImg={fileCoverImg}
        files={fileList}
        deleteFile={deleteFile}
        classes={classes}
        disabled
      />
    </div>
  );
};

export default S3FileField;
