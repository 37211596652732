import React from 'react';
import { TextField, Show, SimpleShowLayout } from 'react-admin';

const LocaleShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ra.label.locale" source="locale" />
      <TextField label="ra.label.long" source="long" />
      <TextField label="ra.label.short" source="short" />
    </SimpleShowLayout>
  </Show>
);

export default LocaleShow;
