import AddressCreate from './components/Create';
import AddressEdit from './components/Edit';
import AddressList from './components/List';
import AddressShow from './components/Show';

export default {
  list: AddressList,
  create: AddressCreate,
  edit: AddressEdit,
  show: AddressShow,
};
