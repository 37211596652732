import styled from 'styled-components';

export const FormWithPreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ orientation }) => orientation};

  & > *:first-child {
    flex: 0 0 20%;
  }

  & > *:last-child {
    flex: 0 0 70%;
    position: ${({ orientation }) => (orientation.includes('column') ? 'static' : 'sticky')};
    top: 16px;
    height: calc(100vh - 140px);
  }
`;
