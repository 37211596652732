import * as React from 'react';
import cn from 'classnames';
import useStyles from './style';
import { ORDER_STATUS_NAMES } from '../../../config/statuses.config';
import formatDateTime from '../../../utils/formatDateTime';

const SHOWED_STATUSES_TIME = [
  ORDER_STATUS_NAMES.COURIER_IS_IN_RESTAURANT,
  ORDER_STATUS_NAMES.DELIVERING,
  ORDER_STATUS_NAMES.DELIVERED,
];

const DeliveryColumn = ({ record }) => {
  const styles = useStyles();

  if (!record) {
    return null;
  }

  const [inRestaurantTime, deliveringTime, deliveredTime] = record.log
    .filter(({ type }) => SHOWED_STATUSES_TIME.includes(type))
    .map(({ timestamp }) => formatDateTime(timestamp).time);

  return (
    <div className={styles.wrapper}>
      <span className={cn(styles.time, styles.inRestaurant)}>{inRestaurantTime}</span>
      <span className={cn(styles.time, styles.delivering)}>{deliveringTime}</span>
      <span className={cn(styles.time, styles.delivered)}>{deliveredTime}</span>
    </div>
  );
};

export default DeliveryColumn;
