import React from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import useAnchor from '../../../hooks/useAnchor';
import useStyles from './styles';
import supplierIcon from '../icons/Restaurant.svg';

const SupplierPlacemark = ({ order }) => {
  const { anchorEl, open, handleClose, handleOpen } = useAnchor();
  const styles = useStyles();
  const translate = useTranslate();

  const {
    preparationDetails: { estimatedDuration },
    supplier: { supplierName },
  } = order;

  return (
    <div>
      <IconButton onClick={handleOpen} className={styles.icon}>
        <img src={supplierIcon} alt="Supplier" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={styles.balloon}>
          <Typography variant="h6">{supplierName}</Typography>
          <Typography variant="body1">
            {`
            ${translate('ra.label.estimatedPreparation')}: 
            ${estimatedDuration}
            ${translate('ra.label.minutes')}
            `}
          </Typography>
        </div>
      </Popover>
    </div>
  );
};

export default SupplierPlacemark;
