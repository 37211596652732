import React from 'react';
import { Datagrid, FunctionField, List, ReferenceField, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import formatDateTime from '../../../utils/formatDateTime';
import { NotificationLoggingFilter } from '../ListFilters';

const NotificationLoggingList = () => (
  <List filters={<NotificationLoggingFilter />}>
    <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
      <TextField label="ra.label.id" source="id" />
      <TextField label="ra.label.action" source="action" />
      <TextField label="ra.label.status" source="status" />
      <ReferenceField
        label="ra.label.notificationEvent"
        source="notificationId"
        reference="notification"
        link="show"
      >
        <TextField source="id" />
      </ReferenceField>
      <TextField label="ra.label.message" source="message" />
      <FunctionField
        label="ra.label.created_at"
        render={({ createdAt }) => {
          const { date, time } = formatDateTime(createdAt);

          return `${date} ${time}`;
        }}
      />
    </Datagrid>
  </List>
);

export default NotificationLoggingList;
