import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import LockIcon from '@mui/icons-material/Lock';
import { Notification, useLogin, useNotify, useTranslate } from 'react-admin';
import { createTheme, ThemeProvider } from '@mui/material';
import { lightTheme, useStyles } from './style';
import TextInput from './TextInput';

const LoginCustom = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const styles = useStyles();
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();

  const loginHandler = (e) => {
    e.preventDefault();

    const sanitizedEmail = email.trim();
    const sanitizedPassword = password.trim();

    if (!sanitizedEmail || !sanitizedPassword) {
      return;
    }

    const credentials = {
      email: sanitizedEmail,
      password: sanitizedPassword,
    };

    login(credentials, '/')
      .catch(() => {
        notify(translate('ra.message.invalid_login'), { type: 'error' });
      });
  };

  return (
    <div className={styles.main}>
      <Card className={styles.card}>
        <div className={styles.avatar}>
          <Avatar className={styles.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <form onSubmit={loginHandler}>
          <div className={styles.form}>
            <div className={styles.input}>
              <TextInput
                name="email"
                value={email}
                label={translate('ra.label.email')}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.input}>
              <TextInput
                name="password"
                type="password"
                value={password}
                label={translate('ra.auth.password')}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className={styles.button}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className={styles.button}
                fullWidth
              >
                {translate('ra.auth.sign_in')}
              </Button>
            </div>
          </div>
        </form>
      </Card>
      <Notification />
    </div>
  );
};

const theme = createTheme(lightTheme);
const LoginWithTheme = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <LoginCustom {...props} />
    </ThemeProvider>
  );
};

export default LoginWithTheme;
