import UIThemeCreate from './components/Create';
import UIThemeEdit from './components/Edit';
import UIThemeList from './components/List';
import UIThemeShow from './components/Show';

export default {
  list: UIThemeList,
  create: UIThemeCreate,
  show: UIThemeShow,
  edit: UIThemeEdit,
};
