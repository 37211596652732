import { FunctionField } from 'react-admin';
import React from 'react';
import { staticUrl } from '../../config/connection';
import {
  ImageColumn,
  ImageRow,
} from './style';

export function LocalizedImageField({ label, source }) {
  return (
    <FunctionField
      label={label}
      source={source}
      render={({ [source]: images }) => (
        <ImageColumn>
          {Object.entries(images).map(([locale, covers]) => (
            covers && locale &&
            <ImageRow>
              <span>{locale.toUpperCase()}</span>
              <img height="128" src={`${staticUrl}/${covers[0].url}`} alt={locale} />
            </ImageRow>
          ))}
        </ImageColumn>
      )}
    />
  );
}
