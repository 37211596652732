const headCells = [
  {
    id: 'name',
    type: 'text',
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'multiple',
    type: 'boolean',
    disablePadding: false,
    label: 'Multiple',
  },
  {
    id: 'required',
    type: 'boolean',
    disablePadding: false,
    label: 'Required',
  },
  {
    id: 'option',
    type: 'text',
    disablePadding: false,
    label: 'Options',
  },
  {
    id: 'isActive',
    type: 'boolean',
    disablePadding: false,
    label: 'Activate options',
  },
  {
    id: 'edit',
    type: 'button',
    disablePadding: false,
    label: 'Edit',
  },
];

export default headCells;
