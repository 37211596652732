import { ALL_USER_ROLES_NAMES } from '../../../config/user.config';
import { isSuperAdmin } from '../../../utils/roles';

export const menuResourceHelper = (resources) => {
  const resourceArrays = {};
  Object.keys(resources).forEach((key) => {
    resourceArrays[key] = resources[key]?.res;
  });
  const menuResources = Object.keys(resourceArrays).reduce((acc, key, idx) => {
    if (isSuperAdmin() && key === 'hyper_admin_management' && resourceArrays?.length > 0) {
      return [
        ...acc,
        {
          resources: resourceArrays[key],
          allowedRoles: [ALL_USER_ROLES_NAMES.ADMIN],
          // options: { icon: icons[key] },
          index: 0,
          navigationTranslateName: key,
        },
      ];
    }
    if (resources[key]?.res?.length > 0) {
      return [
        ...acc,
        {
          resources: resourceArrays[key],
          allowedRoles: [ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN, ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH],
          options: { icon: resources[key]?.icon },
          index: idx,
          navigationTranslateName: key,
        },
      ];
    }

    return acc;
  }, []);

  return menuResources.sort((a, b) => a.index - b.index);
};
