import React from 'react';
import { List, Datagrid, TextField, FunctionField } from 'react-admin';
import formatDateTime from '../../../utils/formatDateTime';

const UserDeletingList = () => {
  return (
    <List>
      <Datagrid bulkActionButtons={false}>
        <TextField label="ra.label.userId" source="id" />
        <FunctionField
          label="ra.label.deletedAt"
          render={({ deletedAt }) => {
            const { date, time } = formatDateTime(deletedAt);

            return `${date} ${time}`;
          }}
        />
      </Datagrid>
    </List>
  );
};

export default UserDeletingList;
