import React, { useEffect, useState } from 'react';

import {
  required,
  SimpleForm,
  AutocompleteInput,
  NumberInput,
  Edit,
} from 'react-admin';
import SupplierAutocomplete from '../../../components/SupplierAutocomplete';
import { baseUrl } from '../../../config/connection';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';

const validateRequired = required();

const StoreSupplierEdit = () => {
  const [storeChoices, setStoreChoices] = useState([]);

  useEffect(() => {
    fetchWithAuthorization(`${baseUrl}/admin/store`)
      .then((res) => res.json())
      .then((res) => {
        setStoreChoices(res);
      });
  }, []);

  return (
    <Edit>
      <SimpleForm>
        <AutocompleteInput
          label="ra.label.store"
          optionText="name"
          optionValue="id"
          source="storeId"
          sx={{ maxWidth: '300px', width: '100%' }}
          shouldRenderSuggestions={(val) => val.trim().length >= 2}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          validate={validateRequired}
          choices={storeChoices}
        />
        <SupplierAutocomplete sx={{ maxWidth: '300px', width: '100%' }} validate />
        <NumberInput
          label="ra.label.rank"
          source="supplierRank"
          sx={{ maxWidth: '300px', width: '100%' }}
          validate={validateRequired}
        />
      </SimpleForm>
    </Edit>
  );
};

export default StoreSupplierEdit;
