import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import DocumentHtmlForm from '../common/Form';

const DocumentEdit = (props) => (
  <Edit {...props} actions={<CancelButton />}>
    <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
      <DocumentHtmlForm />
    </SimpleForm>
  </Edit>
);

export default DocumentEdit;
