import React from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { DEFAULT_GEO } from '../../../config/maps.config';

const markerIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.8.0/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.8.0/dist/images/marker-icon-2x.png',
  iconSize: new L.Point(25, 41),
});

const MapField = ({ geo }) => {
  const orderGeo = geo?.lat && geo?.lng ? [geo?.lat, geo?.lng] : DEFAULT_GEO;

  return (
    <MapContainer style={{ width: '100%' }} center={orderGeo} zoom={50} scrollWheelZoom>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={orderGeo} icon={markerIcon} />
    </MapContainer>
  );
};

export default MapField;
