import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useListContext, useTranslate } from 'react-admin';
import useStyles from './styles';
import TimeRangePicker from '../../../Reports/components/TimeRangePicker';
import Modal from '../../../components/Modal';
import { parseDateToDDMMYYY } from '../../../utils/timeOperations';

const DataRange = () => {
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const translate = useTranslate();
  const { filterValues, setFilters } = useListContext();

  const styles = useStyles();

  useEffect(() => {
    const filterParams = { ...filterValues };

    if (dateRange.from && dateRange.to) {
      filterParams.from = dateRange.from;
      filterParams.to = dateRange.to;
    } else {
      filterParams.from = -1;
      filterParams.to = -1;
    }
    setFilters(filterParams);
  }, [dateRange, filterValues, setFilters]);

  const composeCalendarBtnTitle = () => {
    const { from, to } = dateRange;
    const isSelectedRange = from && to;

    if (!isSelectedRange) {
      return translate('ra.action.select_time_range');
    }

    return `${parseDateToDDMMYYY(from)} - ${parseDateToDDMMYYY(to)}`;
  };

  return (
    <div>
      <Button
        className={styles.button}
        variant="outlined"
        color="primary"
        onClick={() => {
          setCalendarModalOpen(true);
        }}
      >
        {composeCalendarBtnTitle()}
      </Button>

      <Modal
        open={calendarModalOpen}
        onClose={() => setCalendarModalOpen(false)}
        alwaysOn
      >
        <TimeRangePicker range={dateRange} changeRange={setDateRange} />
      </Modal>
    </div>
  );
};

export default DataRange;
