import { deleteWithAuthorization } from '../../utils/fetchWithAuthorization';
import url from '../../config/connection';

const deleteDiscount = (record, refresh, notify) => {
  if (record?.discountId) {
    deleteWithAuthorization(`${url}/owned-discount/${record.id}`)
      .then(() => {
        refresh();
      })
      .catch((e) => {
        notify('Error: Not approved', { type: 'warning' });
      });
  }
};

export default deleteDiscount;
