export function downloadContent(content, fileName) {
  const contentBlob = new Blob([content]);
  const contentUrl = URL.createObjectURL(contentBlob);

  const htmlAnchorElement = document.createElement('a');
  htmlAnchorElement.href = contentUrl;
  htmlAnchorElement.download = fileName;

  document.body.appendChild(htmlAnchorElement);
  htmlAnchorElement.click();

  setTimeout(() => {
    URL.revokeObjectURL(contentUrl);
    htmlAnchorElement.remove();
  });
}
