import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const DocumentList = () => (
  <List>
    <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
      <TextField label="ra.label.id" source="id" />
      <TextField label="ra.label.document_type" source="type" />
    </Datagrid>
  </List>
);

export default DocumentList;
