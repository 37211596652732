import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useInput, useRecordContext } from 'react-admin';
import useStyles from './styles';
import FileList from './FileList';
import Loading from './Loading';
import AddFileBtn from './AddFileBtn';
import 'react-image-lightbox/style.css';

const S3FileInput = (props) => {
  const { disabled, uploadOptions, apiRoot, fileCoverImg, source, multipleFiles } = props;

  const { field: input } = useInput(props);

  const classes = useStyles();
  const record = useRecordContext();

  const [fileList, setFilelist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [percent, setPercent] = useState(0);

  const uploadInputRef = createRef();

  useEffect(() => {
    if (input) {
      return setFilelist(input.value || []);
    }

    if (record) {
      return setFilelist(record[source] || []);
    }

    return setFilelist([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, source]);

  const deleteFile = useCallback(
    (fileName) => {
      const newFileList = fileList?.filter((file) => file.name !== fileName);
      input.onChange(newFileList);
      setFilelist(newFileList);
    },
    [fileList, input],
  );

  const onFinishFileUpload = useCallback(
    (result) => {
      const newFile = { name: result.filename, url: result.fileKey };
      let newFileList;

      if (multipleFiles) {
        newFileList = [...fileList, newFile];
      } else {
        newFileList = [newFile];
      }

      setFilelist(newFileList);
      input.onChange(newFileList);
    },
    [fileList, input, multipleFiles],
  );

  const onFileProgress = useCallback((percentage) => {
    if (percentage === 0) {
      setIsLoading(true);
    }
    if (percentage === 100) {
      setIsLoading(false);
    }
    setPercent(percentage);
  }, []);

  const s3InputOptions = {
    signingUrlMethod: 'GET',
    accept: '*/*',
    onFinish: (result) => onFinishFileUpload(result),
    onProgress: (percentage) => onFileProgress(percentage),
    uploadRequestHeaders: { 'x-amz-acl': 'private' },
    signingUrlWithCredentials: false,
    signingUrlQueryParams: { uploadType: 'avatar' },
    autoUpload: true,
    className: classes.fileInput,
    id: 'filesInput',
    ref: uploadInputRef,
    ...uploadOptions,
  };

  return (
    <div className={classes.wrapper}>
      {!disabled && (
        <AddFileBtn label="Add File" uploadOptions={s3InputOptions} classes={classes} />
      )}
      <FileList
        apiRoot={apiRoot}
        fileCoverImg={fileCoverImg}
        files={fileList}
        deleteFile={deleteFile}
        classes={classes}
        disabled={disabled}
      />
      {isLoading && <Loading percentage={percent} classes={classes} />}
    </div>
  );
};

export default S3FileInput;
