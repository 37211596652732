import React, { useEffect, useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useController } from 'react-hook-form';
import { FILTER_TYPES } from '../../../config';
import FilterType from '../FilterType';
import styles from '../../style';

const FilterTypeList = (props) => {
  const { value, onChange, disabled, record = {} } = props;

  const translate = useTranslate();
  const [checkedFilters, setCheckedFilters] = useState(value);

  useEffect(() => {
    if (checkedFilters) {
      onChange(checkedFilters);
    }
  }, [checkedFilters, onChange]);

  const filterTypeList = useMemo(() => Object.keys(FILTER_TYPES), []);

  const handleFilterCheck = (e, filterOptions) => {
    const { checked, value: type } = e.target;
    let updatedFiltersList = JSON.parse(JSON.stringify(checkedFilters || [])) || [];
    updatedFiltersList = updatedFiltersList.filter((filter) => filter.type !== type);

    if (checked) {
      updatedFiltersList.push(filterOptions);
    }

    setCheckedFilters(() => updatedFiltersList);
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>{translate('ra.label.filters')}</h3>
      <div style={styles.flexColumn}>
        {filterTypeList.map((filterType) => (
          <FilterType
            key={filterType}
            type={filterType}
            handleFilterCheck={handleFilterCheck}
            checkedFilters={checkedFilters}
            disabled={disabled}
            record={record}
          />
        ))}
      </div>
    </div>
  );
};

export const FilterTypeListInput = () => {
  const input = useController({ name: 'filters' });

  return <FilterTypeList {...input.field} />;
};
