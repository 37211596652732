import * as React from 'react';
import {
  Toolbar,
  SaveButton,
} from 'react-admin';

const StaticsEditActions = () => {
  return (
    <Toolbar>
      <SaveButton alwaysEnable />
    </Toolbar>
  );
};

export default StaticsEditActions;
