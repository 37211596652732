import React from 'react';
import {
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  useRecordContext,
  useTranslate,
} from 'react-admin';

const EditDeleteConfirmationButton = ({ disabled = false, ...props }) => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Toolbar {...props} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton alwaysEnable />
      <DeleteWithConfirmButton
        sx={{ padding: '8px 10px' }}
        confirmContent={translate('ra.message.delete_content')}
        translateOptions={{ name: record.name }}
        mutationMode="undoable"
        disabled={disabled}
      />
    </Toolbar>
  );
};

export default EditDeleteConfirmationButton;
