export const UNITS = {
  kg: 'kg',
  g: 'g',
  pcs: 'pcs',
  l: 'l',
  ml: 'ml',
  kcal: 'kcal',
};

export const defaultValues = {
  size: 0,
  unit: UNITS.g,
};
