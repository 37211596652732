import OptionGroupCreate from './components/Create';
import OptionGroupEdit from './components/Edit';
import OptionGroupList from './components/List';
import OptionGroupShow from './components/Show';

export default {
  create: OptionGroupCreate,
  edit: OptionGroupEdit,
  show: OptionGroupShow,
  list: OptionGroupList,
};
