/* eslint-disable react/no-unstable-nested-components */
import { Button } from '@mui/material';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import SupplierReportToPrint from './SupplierReport';

const SupplierPDFReport = ({ supplier, reportData, dateRange, tableHeaders }) => {
  const orderReportRef = useRef(null);

  return (
    <div>
      {/* Button to generate the PDF */}
      <ReactToPrint
        trigger={() => (
          <Button variant="outlined" color="primary">
            Generate PDF report
          </Button>
        )}
        content={() => orderReportRef.current}
        documentTitle="Supplier orders report"
      />
      <div style={{ display: 'none' }}>
        <SupplierReportToPrint
          supplier={supplier}
          ref={orderReportRef}
          reportData={reportData}
          dateRange={dateRange}
          tableHeaders={tableHeaders}
        />
      </div>
    </div>
  );
};

export default SupplierPDFReport;
