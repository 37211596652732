import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  border-bottom: 1px solid #d7d7d7;
  width: 915px;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
`;

const useStyles = makeStyles({
  formIterator: {
    marginTop: 28,
    '& section': {
      display: 'flex',
      flexDirection: 'row !important',
    },
    '& .button-reorder': {
      display: 'none',
    },
    '& li': {
      borderBottom: 'none !important',
    },
  },
  input: {
    marginRight: 10,
    minWidth: 256,
  },
  settingsSection: {
    marginTop: 10,
  },
  orderPriceIterator: {
    '& section': {
      display: 'flex',
      flexDirection: 'row !important',
    },
    '& .button-reorder': {
      display: 'none',
    },
    '& li': {
      borderBottom: 'none !important',
    },
  },
});

export default useStyles;
