import React from 'react';
import { Labeled, useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import AbstractTranslatableInput from '../TranslatableInputs/AbstractTranslatableInput';
import SORTED_LOCALES from '../constants';

const TranslatableTextFieldShow = ({ source, label }) => {
  const translate = useTranslate();
  const record = useRecordContext();

  if (record?.label) {
    return (
      <div>
        <Typography className="mt-1r">
          {translate(label) || AbstractTranslatableInput.Capitalize(source)}
        </Typography>
        {SORTED_LOCALES.map((orderedTranslationKey) => (
          <div key={orderedTranslationKey} className="ml-1r">
            <Labeled label={orderedTranslationKey}>
              <span>{record?.label[orderedTranslationKey]}</span>
            </Labeled>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default TranslatableTextFieldShow;
