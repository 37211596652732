export const ALL_USER_ROLES_IDS = {
  ADMIN: 0,
  SUPPLIER_ADMIN: 1,
  USER: 2,
  COURIER: 3,
  SUPPLIER_BRANCH: 4,
};

export const ALL_USER_ROLES_NAMES = {
  ADMIN: 'ADMIN',
  SUPPLIER_ADMIN: 'SUPPLIER_ADMIN',
  USER: 'USER',
  COURIER: 'COURIER',
  SUPPLIER_BRANCH: 'SUPPLIER_BRANCH',
};

export const adminRoleChoices = [
  {
    name: ALL_USER_ROLES_NAMES.ADMIN,
    value: ALL_USER_ROLES_IDS[ALL_USER_ROLES_NAMES.ADMIN],
  },
  {
    name: ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN,
    value: ALL_USER_ROLES_IDS[ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN],
  },
  {
    name: ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH,
    value: ALL_USER_ROLES_IDS[ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH],
  },
  {
    name: ALL_USER_ROLES_NAMES.USER,
    value: ALL_USER_ROLES_IDS[ALL_USER_ROLES_NAMES.USER],
  },
];

export const roleChoices = [
  {
    name: ALL_USER_ROLES_NAMES.ADMIN,
    id: ALL_USER_ROLES_IDS[ALL_USER_ROLES_NAMES.ADMIN],
  },
  {
    name: ALL_USER_ROLES_NAMES.USER,
    id: ALL_USER_ROLES_IDS[ALL_USER_ROLES_NAMES.USER],
  },
  {
    name: ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN,
    id: ALL_USER_ROLES_IDS[ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN],
  },
  {
    name: ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH,
    id: ALL_USER_ROLES_IDS[ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH],
  },
];

export const authProviders = [
  { id: 'email', name: 'Email' },
  { id: 'phone', name: 'Phone' },
  { id: 'google', name: 'Google' },
  { id: 'appleId', name: 'AppleID' },
  { id: 'vipps', name: 'Vipps' },
];
