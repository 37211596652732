import React from 'react';
import { useRecordContext } from 'react-admin';
import { ItemImage } from '../styles';
import { baseUrl } from '../../../config/connection';

const ImageField = () => {
  const record = useRecordContext();

  return record?.image ? (
    <ItemImage src={`${baseUrl}/s3/uploads/${record?.image[0].url}`} alt="item" width="60" height="60" />
  ) : null;
};

export default ImageField;
