import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useRecordContext, useRedirect, useTranslate } from 'react-admin';
import { ReactSortable } from 'react-sortablejs';

import { Visibility } from '@mui/icons-material';
import ReferenceOptionsField from '../../../OptionGroups/components/ReferenceOptionsField';
import url from '../../../config/connection';
import {
  fetchWithAuthorization,
  patchWithAuthorization,
  postWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import AddOptionGroupDialog from '../AddOptionGroupButton';
import ActiveOptionGroupSwitcher from './ActiveOptionGroupSwitcher';
import EnhancedTableHead from './TableHead';
import EnhancedTableToolbar from './TableToolbar';
import getComparator from './helpers/getComparator';
import stableSort from './helpers/stableSort';
import useStyles from './style';

function createData(options) {
  return {
    ...options,
  };
}

export default function EnhancedTable() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [optionGroups, setOptionGroups] = useState([]);
  const [initialOptionGroups, setInitialOptionGroups] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const translate = useTranslate();
  const record = useRecordContext();
  const styles = useStyles();
  const redirect = useRedirect();

  useEffect(() => {
    fetchWithAuthorization(`${url}/optionGroup/supplier/${record?.supplierId}`)
      .then((response) => response.json())
      .then((fetchedOptionGroups) => {
        const parsedGroups = fetchedOptionGroups.map((option) => {
          const isActive = record.optionGroupIds.includes(option.id);

          return createData({ ...option, isActive: !!isActive });
        });

        setOptionGroups(parsedGroups);
        setInitialOptionGroups([...parsedGroups]);
      });
  }, [record.supplierId, record.optionGroupIds]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const shouldDisableSaveButton = initialOptionGroups.every((_, index) => {
      const initialItem = initialOptionGroups[index];
      const sortedItem = optionGroups[index];

      const isSameItem = sortedItem.id === initialItem.id;

      return isSameItem;
    });

    setIsDisabled(shouldDisableSaveButton);
  }, [initialOptionGroups, optionGroups, setIsDisabled]);

  const handleUpdate = async () => {
    await postWithAuthorization(`${url}/optionGroup/updateSorting`, {
      body: JSON.stringify([...optionGroups]),
    }).then(() => {
      setIsDisabled(true);
    });
    // used own method instead of useUpdate from React Admin to prevent bug with Files from S3
    await patchWithAuthorization(`${url}/goods/${record?.id}`, {
      body: JSON.stringify(record),
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = optionGroups.map((n) => n.id);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            selectedOptionGroupIds={selected}
            handleChangeOptionGroup={setOptionGroups}
            handleSelectedItems={setSelected}
          />

          <TableContainer>
            <Button
              variant="contained"
              size="medium"
              startIcon={<SaveIcon />}
              className={styles.saveButton}
              disabled={isDisabled}
              onClick={handleUpdate}
            >
              {translate('ra.action.save')}
            </Button>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={optionGroups.length}
              />
              <ReactSortable
                list={optionGroups}
                setList={setOptionGroups}
                animation={100}
                tag="tbody"
              >
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(optionGroups, getComparator(order, orderBy)).map((row) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${row.id}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.id)}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        {row.isMultiple ? <CheckIcon /> : <ClearIcon />}
                      </TableCell>
                      <TableCell align="right">
                        {row.required ? <CheckIcon /> : <ClearIcon />}
                      </TableCell>
                      <TableCell align="right">
                        <ReferenceOptionsField record={row} />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ border: 'none', display: 'flex', justifyContent: 'flex-end' }}>
                          <ActiveOptionGroupSwitcher
                            record={row}
                            goodId={record.id}
                            handleChangeOptionGroup={setOptionGroups}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ border: 'none', display: 'flex', justifyContent: 'flex-end' }}>
                          <IconButton
                            onClick={() => redirect(`/optionGroup/${row.id}`)}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => redirect(`/optionGroup/${row.id}/show`)}
                            size="small"
                          >
                            <Visibility />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </ReactSortable>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <AddOptionGroupDialog handleChangeOptionGroup={setOptionGroups} />
    </>
  );
}
