import React, { useState, useEffect } from 'react';
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  FunctionField,
  ArrayField,
  Datagrid,
  useTranslate,
  useShowController,
} from 'react-admin';
import { fetchWithAuthorization } from '../../../../utils/fetchWithAuthorization';
import url from '../../../../config/connection';
import { headers } from '../../utils/csvHeaders';
import CSVExport from '../../../../Reports/components/CSVExport';
import { formatDate } from '../../utils/formatDate';
import RunCampaignActions from '../Actions/runCampaignActions';

const RunCampaignShow = (...props) => {
  const translate = useTranslate();
  const [dataForExport, setDataForExport] = useState([]);

  const { record: campaignRecord } = useShowController();

  const campaignId = campaignRecord?.id;

  useEffect(() => {
    fetchWithAuthorization(`${url}/marketing-campaign-runs/${campaignId}`)
      .then((res) => res.json())
      .then(({ participants }) => {
        setDataForExport((prevData) => [...prevData, ...(participants || [])]);
      })
      .catch(() => {
        throw new Error('failed to fetch');
      });
  }, [campaignId]);

  return (
    <Show {...props} actions={<RunCampaignActions {...props} />}>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.id" source="campaignId" />
          <FunctionField
            label="ra.label.launchDate"
            render={({ launchDate }) => formatDate(launchDate)}
          />
          <FunctionField
            label="ra.label.effectiveDate"
            render={({ effectiveDate }) => formatDate(effectiveDate)}
          />
        </Tab>
        <Tab label={translate('ra.tab.participants', { count: dataForExport.length })}>
          <div style={{ marginTop: '10px', marginLeft: '10px' }}>
            <CSVExport data={dataForExport} headers={headers} />
          </div>

          <ArrayField source="participants" addLabel={false}>
            <Datagrid rowClick={(id, basePath, { userId }) => `/user/${userId}/show`}>
              <TextField label="ra.label.first_name" source="firstName" />
              <TextField label="ra.label.last_name" source="lastName" />
              <TextField label="ra.label.email" source="email" />
              <TextField label="ra.label.phone" source="phone" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="ra.tab.statistics">
          <TextField label="ra.label.discounts_sent" source="statistics.discountsSent" />
          <TextField label="ra.label.discounts_used" source="statistics.discountsUsed" />
          <FunctionField
            label="ra.label.discounts_cost"
            render={(record) => {
              return record?.statistics ? `${Number(record?.statistics?.discountsCost) / 100}` : '';
            }}
          />
          <FunctionField
            label="ra.label.ordersValue"
            render={(record) => {
              return record?.statistics ? `${Number(record?.statistics?.ordersValue) / 100}` : '';
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default RunCampaignShow;
