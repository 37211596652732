import React, { useEffect } from 'react';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import PriceField from '../../../components/PriceField';
import { baseUrl } from '../../../config/connection';
import { initSocket } from '../../../redux/socket';
import ShowActions from '../Actions';
import OrderModals from '../OrderModals';
import { useUiSettings } from '../../../hooks/useUiSettings';

const socketIO = io(baseUrl, { transports: ['websocket'], rejectUnauthorized: false });

const OrderShow = ({ createSocket, socket, ...props }) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const uiSettings = useUiSettings();

  const renderOptions = (options) => {
    if (!options) {
      return '';
    }

    return options.reduce((accum, option) => {
      if (!option) {
        return accum;
      }

      return `${accum} ${option.name},`;
    }, '');
  };

  useEffect(() => {
    if (!socket) {
      createSocket(socketIO);

      return;
    }

    socketIO.connect();
    socketIO.on('updatedOrderList', refresh);

    return () => {
      socketIO.off('updatedOrderList', refresh);
      socketIO.disconnect();
    };
  }, [socket, createSocket, refresh]);

  return (
    <>
      <Show {...props} actions={<ShowActions {...props} />}>
        <TabbedShowLayout>
          <Tab label="ra.tab.summary">
            <FunctionField
              label="ra.label.address"
              render={({ targetAddress }) => {
                const details = targetAddress.details || {};

                return `${targetAddress.area},
                  ${targetAddress.locality},
                  ${targetAddress.street},
                  ${targetAddress.house},
                  ${translate('ra.title.porch')}: ${details.porch},
                  ${translate('ra.title.floor')}: ${details.floor},
                  ${translate('ra.title.apartment')}: ${details.apartment}`;
              }}
            />
            <FunctionField
              label="ra.label.order_instructions"
              render={({ instructions }) => `${instructions}`}
            />
            <FunctionField
              label="ra.label.client"
              render={({ client }) => `${client.firstName} ${client.lastName}, ${client.phone}`}
            />
            <FunctionField
              label="ra.label.supplier"
              render={({ supplier }) => `${supplier.supplierName} ${supplier.branchName}`}
            />
            <FunctionField
              label="ra.label.courier"
              render={({ courier }) => {
                return courier && `${courier.firstName} ${courier.lastName}, ${courier.phone}`;
              }}
            />
            <ArrayField label="ra.title.order_items" source="orderItems">
              <Datagrid bulkActionButtons={false}>
                <FunctionField
                  label="ra.label.name"
                  render={({ quantity, name }) => `${quantity}x ${name}`}
                />
                <PriceField
                  label="ra.label.price"
                  source="price"
                  parse={(v) => v / 100}
                  options={{
                    style: 'currency',
                    currency: uiSettings.currency,
                  }}
                />
                <FunctionField
                  label="ra.label.options"
                  render={({ options }) => renderOptions(options)}
                />
              </Datagrid>
            </ArrayField>
            <FunctionField
              label="ra.title.preparing_time"
              render={({ preparationDetails }) => `${preparationDetails.estimatedDuration} min`}
            />
            <FunctionField
              label="ra.title.delivery_time"
              render={({ deliveringTime }) => {
                return deliveringTime && `${deliveringTime.estimatedDuration} min`;
              }}
            />
            <FunctionField
              label="ra.title.total_time"
              render={({ estimatedTotalTime }) => `${estimatedTotalTime} min`}
            />
            <FunctionField
              label="ra.title.payment_type"
              render={({ payment }) => `${payment.type}`}
            />
            <PriceField
              label="ra.title.total_price"
              source="totalPrice"
              parse={(v) => v / 100}
              options={{
                style: 'currency',
                currency: uiSettings.currency,
              }}
            />
            <PriceField
              label="ra.title.delivery_price"
              source="deliveryPrice"
              parse={(v) => v / 100}
              options={{
                style: 'currency',
                currency: uiSettings.currency,
              }}
            />
          </Tab>
        </TabbedShowLayout>
      </Show>

      <OrderModals />
    </>
  );
};

const mapStateToProps = (state) => ({
  socket: state.socket.client,
});

const mapDispatchToProps = (dispatch) => ({
  createSocket: (socket) => dispatch(initSocket(socket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderShow);
