import React from 'react';
import { useLocaleState, useRecordContext } from 'react-admin';

const TranslatableTextFieldList = (props) => {
  const record = useRecordContext();
  const [locale] = useLocaleState();

  if (record?.label) {
    return <div>{record?.label[locale] || ' '}</div>;
  }

  return <div />;
};

export default TranslatableTextFieldList;
