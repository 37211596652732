import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import DocumentHtmlForm from '../common/Form';

const DocumentCreate = () => {
  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm redirect="show">
        <DocumentHtmlForm />
      </SimpleForm>
    </Create>
  );
};

export default DocumentCreate;
