import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useNotify, useTranslate } from 'react-admin';
import { Menu, MenuItem, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import useStyles from './styles';
import { baseUrl } from '../../../config/connection';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { ORDER_STATUS_IDS, ORDER_STATUS_NAMES } from '../../../config/statuses.config';
import { DEFAULT_ZOOM, GOOGLE_MAPS_API_KEY, DEFAULT_GEO } from '../../../config/maps.config';
import SupplierPlacemark from '../../../components/maps/SupplierPlacemark';
import CourierPlacemark from '../../../components/maps/CourierPlacemark';
import useAnchor from '../../../hooks/useAnchor';
import ClientPlacemark from '../../../components/maps/ClientPlacemark';

const courierStatuses = {
  online: 'ONLINE',
};

const mapOptions = () => ({
  fullscreenControl: false,
});

const DetailsMap = () => {
  const styles = useStyles();
  const { anchorEl, handleOpen, handleClose } = useAnchor();
  const [couriers, setCouriers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [googleMapsApi, setGoogleMapsApi] = useState();
  const [currentCourierPath, setCurrentCourierPath] = useState();
  const [clientGeo, setClientGeo] = useState(null);
  const [courierFilters, setCourierFilters] = useState({
    busy: true,
    available: true,
  });

  const translate = useTranslate();
  const notify = useNotify();

  useEffect(() => {
    const couriersRequest = () =>
      fetchWithAuthorization(`${baseUrl}/admin/courier?&limit=50&page=1&sort[0]=id,ASC&offset=0`)
        .then((res) => res.json())
        .then(({ data }) => {
          setCouriers(
            data.filter(({ status, location }) => status === courierStatuses.online && location),
          );
        })
        .catch((e) => notify(`${translate('ra.label.error')}: ${e}`, { type: 'warning' }));

    couriersRequest();
    const couriersInterval = setInterval(couriersRequest, 1000 * 60);

    return () => clearInterval(couriersInterval);
  }, [translate, notify]);

  useEffect(() => {
    const ordersRequest = () =>
      fetchWithAuthorization(`${baseUrl}/admin/order?&limit=50&page=1&sort[0]=id,ASC&offset=0`)
        .then((res) => res.json())
        .then(({ data }) => {
          setOrders(
            data?.filter(
              ({ orderStatus }) =>
                ![
                  ORDER_STATUS_IDS.NEW,
                  ORDER_STATUS_IDS.PROCESSING,
                  ORDER_STATUS_IDS.CANCELED,
                  ORDER_STATUS_IDS.DELIVERED,
                ].includes(orderStatus?.id),
            ),
          );
        })
        .catch((e) => notify(`${translate('ra.label.error')}: ${e}`, { type: 'warning' }));

    ordersRequest();
    const ordersInterval = setInterval(ordersRequest, 1000 * 60);

    return () => clearInterval(ordersInterval);
  }, [translate, notify]);

  const handleGoogleMapApi = (google) => setGoogleMapsApi(google);

  const clearPath = () => {
    if (currentCourierPath) {
      currentCourierPath.setMap(null);
    }

    setClientGeo(null);
  };

  const drawCourierPath = ({ currentOrderIds, location: { coordinates } }) => {
    clearPath();

    if (!currentOrderIds.length) return;

    const orderId = currentOrderIds[0];
    const currentOrder = orders.find(({ id }) => id === orderId);

    if (!currentOrder) return;

    const {
      sourceAddress: { geo: sourceGeo },
      targetAddress: { geo: targetGeo },
      supplierStatus,
    } = currentOrder;

    const courierGeo = {
      lat: coordinates[1],
      lng: coordinates[0],
    };

    const path = [{ ...courierGeo }, { ...sourceGeo }, { ...targetGeo }];

    if (supplierStatus === ORDER_STATUS_NAMES.DELIVERING) {
      const tmp = path[0];
      path[0] = path[1];
      path[1] = tmp;
    }

    const { map, maps } = googleMapsApi;
    const courierPath = new maps.Polyline({
      path,
      strokeColor: 'orange',
      strokeOpacity: 1,
      strokeWeight: 5,
    });

    setCurrentCourierPath(courierPath);
    courierPath.setMap(map);
    setClientGeo(targetGeo);
  };

  return (
    <div className={styles.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        defaultCenter={DEFAULT_GEO}
        defaultZoom={DEFAULT_ZOOM}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleGoogleMapApi}
      >
        {orders.map((order) => (
          <SupplierPlacemark key={order.id} {...order.sourceAddress.geo} order={order} />
        ))}
        {couriers.map((courier) => {
          const { userId, location, currentOrderIds } = courier;
          const busy = !!currentOrderIds.length;

          if (!((courierFilters.busy && busy) || (courierFilters.available && !busy))) {
            return null;
          }

          return (
            <CourierPlacemark
              key={userId}
              lat={location.coordinates[1]}
              lng={location.coordinates[0]}
              courier={courier}
              iconStyles={busy ? styles.busyCourier : null}
              onClick={() => drawCourierPath(courier)}
            />
          );
        })}
        {clientGeo && <ClientPlacemark {...clientGeo} />}
      </GoogleMapReact>
      <div className={styles.filter}>
        <Button variant="contained" onClick={handleOpen}>
          {translate('ra.label.courier_filters')}
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              setCourierFilters({
                ...courierFilters,
                busy: !courierFilters.busy,
              });
              clearPath();
            }}
            className={styles.filterItem}
          >
            {translate('ra.label.busy')}
            {courierFilters.busy && <CheckIcon color="primary" />}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setCourierFilters({ ...courierFilters, available: !courierFilters.available });
            }}
            className={styles.filterItem}
          >
            {translate('ra.label.available')}
            {courierFilters.available && <CheckIcon color="primary" />}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default DetailsMap;
