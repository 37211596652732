import { CampaignOutlined } from '@mui/icons-material';
import MarketingCampaignCreate from './components/Create';
import MarketingCampaignEdit from './components/Edit';
import MarketingCampaignList from './components/List';
import MarketingCampaignShow from './components/Show';

export default {
  list: MarketingCampaignList,
  create: MarketingCampaignCreate,
  show: MarketingCampaignShow,
  edit: MarketingCampaignEdit,
  icon: CampaignOutlined,
};
