import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  minValue,
  NumberInput,
  required,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  useTranslate,
} from 'react-admin';
import { Typography } from '@mui/material';
import { CURRENCY_NAME } from '../../../config/currency.config';
import useStyles, { Grid } from './styles';
import { settingTypes } from '../../../config/delivery.config';
import TimePicker from '../../../components/TimePeriod';

const validateRequired = required();
const validatePositiveRange = [required(), minValue(0)];

const DeliverySettings = () => {
  const translate = useTranslate();
  const styles = useStyles();

  return (
    <div>
      <Typography variant="h4">{translate('ra.label.delivery_settings')}</Typography>
      <TextInput source="name" label="ra.label.name" validate={validateRequired} />
      <div className={styles.settingsSection}>
        <div>
          <Grid gridTemplate="220px 35px">
            <NumberInput
              source="basePrice"
              label={`${translate('ra.label.base_price')} (${CURRENCY_NAME})`}
              validate={validateRequired}
              parse={(v) => v * 100 || ''}
              format={(v) => v / 100 || ''}
            />
            <BooleanInput label="" source="basePriceEnabled" sx={{ marginLeft: '10px' }} />
          </Grid>
          <Grid gridTemplate="120px 35px">
            <ArrayInput source="orderDistanceRanges" label="ra.label.distances">
              <SimpleFormIterator className={styles.formIterator}>
                <NumberInput
                  source="from"
                  label={`${translate('ra.label.from')} (${translate('ra.navigation.km')})`}
                  validate={validatePositiveRange}
                  className={styles.input}
                  parse={(v) => v * 1000 || ''}
                  format={(v) => v / 1000 || ''}
                />
                <NumberInput
                  source="to"
                  label={`${translate('ra.label.to')} (${translate('ra.navigation.km')})`}
                  validate={minValue(0)}
                  className={styles.input}
                  parse={(v) => v * 1000 || ''}
                  format={(v) => v / 1000 || ''}
                />
                <NumberInput
                  source="modifier"
                  label={`${CURRENCY_NAME}/${translate('ra.navigation.km')}`}
                  validate={validatePositiveRange}
                  className={styles.input}
                  parse={(v) => v * 100 || ''}
                  format={(v) => v / 100 || ''}
                />
              </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput label="" source="orderDistanceEnabled" />
          </Grid>
        </div>
      </div>
      <div className={styles.settingsSection}>
        <Grid gridTemplate="90px 35px">
          <ArrayInput source="peakHours" label="ra.label.peak_hours">
            <SimpleFormIterator className={styles.formIterator}>
              <TimePicker
                label="ra.label.from"
                source="from"
                sx={{ width: '256px', marginRight: '10px' }}
              />
              <TimePicker
                label="ra.label.to"
                source="to"
                sx={{ width: '256px', marginRight: '10px' }}
              />
              <NumberInput
                label={translate('ra.label.modifier', { mdl: CURRENCY_NAME })}
                source="modifier"
                validate={validateRequired}
                className={styles.input}
                parse={(v) => v * 100 || ''}
                format={(v) => v / 100 || ''}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput label="" source="peakHoursEnabled" />
        </Grid>
      </div>
      <div className={styles.settingsSection}>
        <Grid gridTemplate="150px 35px">
          <ArrayInput source="orderPriceRanges" label="ra.label.order_price_ranges">
            <SimpleFormIterator className={styles.orderPriceIterator}>
              <NumberInput
                label={`${translate('ra.label.from')} (${CURRENCY_NAME})`}
                source="from"
                validate={validatePositiveRange}
                className={styles.input}
                parse={(v) => v * 100 || ''}
                format={(v) => v / 100 || ''}
              />
              <NumberInput
                label={`${translate('ra.label.to')} (${CURRENCY_NAME})`}
                source="to"
                validate={minValue(0)}
                className={styles.input}
                parse={(v) => v * 100 || ''}
                format={(v) => v / 100 || ''}
              />
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => {
                  return (
                    scopedFormData &&
                    !scopedFormData.isFree && (
                      <NumberInput
                        label={translate('ra.label.modifier', { mdl: CURRENCY_NAME })}
                        source={getSource('modifier')}
                        validate={validateRequired}
                        className={styles.input}
                        parse={(v) => v * 100 || ''}
                        format={(v) => v / 100 || ''}
                      />
                    )
                  );
                }}
              </FormDataConsumer>
              <BooleanInput label="ra.label.free_delivery" source="isFree" />
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput label="" source="orderPriceEnabled" />
        </Grid>
        <SelectInput source="settingType" choices={settingTypes} validate={validateRequired} />
      </div>
    </div>
  );
};

export default DeliverySettings;
