import styled from 'styled-components';

export const DragIconWrapper = styled.div`
  display: inline-block;
  padding: 0 7px;
  svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
`;
export const ListContainer = styled.div`
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  margin: 1rem 0rem;
  max-width: 40rem;
  padding: 0.5rem 2rem 2rem;
  border-radius: 0.2rem;
  box-shadow: 0.1rem 0.1rem 0.4rem #aaaaaa;
  width: 40%;
`;
export const ListItem = styled.div`
  color: #444444;
  padding: 1rem 1rem;
  border: 1px solid #dddddd;
  margin: 1rem;
  span {
    display: inline-block;
    vertical-align: middle;
  }
  background: white;
`;
export const ItemContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
`;
export const ItemSlug = styled.div`
  max-width: 300px;
  word-wrap: break-word;
  padding: 10px 25px;
`;
export const ItemImage = styled.img`
  border-radius: 10px;
`;
export const AddImage = styled.div`
  margin-top: 10px;
  width: 100%;
`;
