import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const CourierPayoutPolicyList = () => {
  return (
    <List>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.name" source="name" />
        <TextField label="ra.label.id" source="id" />
      </Datagrid>
    </List>
  );
};

export default CourierPayoutPolicyList;
