import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  time: {
    marginTop: '1px',
    fontSize: '12px',
    lineHeight: '14px',
  },
  inRestaurant: {
    color: '#b3b3b3',
  },
  delivering: {
    color: '#848484',
  },
  delivered: {
    color: '#555555',
  },
});

export default useStyles;
