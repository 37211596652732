import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const StoreList = ({ ...props }) => {
  return (
    <List {...props} bulkActionButtons={<ListDeleteConfirmationButton />}>
      <Datagrid rowClick="show">
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.name" source="name" />
      </Datagrid>
    </List>
  );
};

export default StoreList;
