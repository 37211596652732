import React from 'react';
import { TextField, Show, SimpleShowLayout } from 'react-admin';

const UIBannerShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ra.label.title" source="name" />
      <TextField source="script" />
    </SimpleShowLayout>
  </Show>
);

export default UIBannerShow;
