import React, { forwardRef, useMemo, useState, Fragment, useCallback } from 'react';
import cn from 'classnames';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslate } from 'react-admin';
import ManagedGoodCard from './ManagedGoodCard';
import GoodsFilter from './GoodsFilter';
import styles from './styles';

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const useStyles = makeStyles({
  group: {
    marginBottom: 20,
  },
  currentMenu: {
    borderBottom: '3px black solid',
  },
});

const ManageGoodsDialog = ({
  supplierGoods,
  section,
  handleClose,
  setSections,
  parseProducts,
  setIsSavedChanges,
}) => {
  const [filteredGoods, setFilteredGoods] = useState(null);
  const muiStyles = useStyles();
  const translate = useTranslate();

  const groups = useMemo(
    () => (section ? [section, ...parseProducts()] : []),
    [parseProducts, section],
  );

  const onAvailabilityChange = useCallback(
    (goodStatus, idx, goodItemId) => {
      const newSection = JSON.parse(JSON.stringify(section));

      const synchronizedGood = newSection.goods?.find(({ id }) => id === goodItemId);

      synchronizedGood.stopList = { isActive: goodStatus };

      setSections((prev) => {
        const index = prev.findIndex((otherSection) => otherSection.id === section.id);
        const copy = [...prev];

        copy[index] = newSection;

        const sectionsWithItems = prev.filter((item) => item.goods.length);

        const allGoods = sectionsWithItems.reduce((allGoodsItems, category) => {
          let mappedGoods;

          if (filteredGoods?.length) {
            mappedGoods = filteredGoods.map((good) => {
              return {
                good,
              };
            });
          } else {
            mappedGoods = category.goods.map((good) => {
              return {
                good,
              };
            });
          }

          return [...allGoodsItems, ...mappedGoods];
        }, []);

        const goodsGroupedByGoodId = allGoods.reduce((groupedGoods, goodWithSection) => {
          const goodId = goodWithSection.good.id;

          return {
            ...groupedGoods,
            [goodId]: [...(groupedGoods[goodId] || []), goodWithSection],
          };
        }, {});

        const keyOfRepeatedGoods = Object.keys(goodsGroupedByGoodId)
          .filter((key) => goodItemId === Number(key))
          .join();

        goodsGroupedByGoodId[keyOfRepeatedGoods].forEach((item) => {
          item.good.stopList = { isActive: goodStatus };
        });

        return [...copy];
      });

      setIsSavedChanges(false);
    },
    [filteredGoods, section, setIsSavedChanges, setSections],
  );

  return (
    <Dialog fullScreen TransitionComponent={Transition} open={!!section} onClose={handleClose}>
      <DialogTitle>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" style={styles.dialogTitle}>
              {translate('ra.action.manage_product')}
            </Typography>
            <GoodsFilter setFilteredGoods={setFilteredGoods} supplierGoods={supplierGoods} />
            <Button
              autoFocus
              color="inherit"
              onClick={handleClose}
              sx={{ marginLeft: '10px', marginTop: '2px', height: '38px', background: '#4f9deb' }}
            >
              {translate('ra.action.save')}
            </Button>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent style={styles.dialog}>
        {filteredGoods ? (
          <div style={styles.goodsGrid}>
            {filteredGoods.map((good, idx) => (
              <ManagedGoodCard
                key={good.slug}
                good={good}
                goodIdx={idx}
                section={section}
                setSections={setSections}
                onAvailabilityChange={onAvailabilityChange}
                setIsSavedChanges={setIsSavedChanges}
                isFiltered={filteredGoods.length}
              />
            ))}
          </div>
        ) : (
          groups.map(({ label, id, goods }, ind) => {
            const isCurrentMenu = ind === 0;

            return (
              <Fragment key={isCurrentMenu ? 'current-menu' : id}>
                <div className={cn(muiStyles.group, { [muiStyles.currentMenu]: isCurrentMenu })}>
                  <Typography variant={isCurrentMenu ? 'h4' : 'h6'}>{label}</Typography>
                  <div style={styles.goodsGrid}>
                    {goods.map((good, idx) => (
                      <ManagedGoodCard
                        key={good.slug}
                        good={good}
                        goodIdx={idx}
                        section={section}
                        setSections={setSections}
                        onAvailabilityChange={onAvailabilityChange}
                        parsedProduct={ind !== 0}
                        setIsSavedChanges={setIsSavedChanges}
                        isCurrentMenu={isCurrentMenu}
                      />
                    ))}
                  </div>
                </div>
                {isCurrentMenu && (
                  <Typography variant="h4">{translate('ra.label.available_products')}</Typography>
                )}
              </Fragment>
            );
          })
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ManageGoodsDialog;
