import { Tooltip, IconButton } from '@mui/material';
import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import { setOrderCommentId } from '../../../redux/customReducers/utils';
import { ReactComponent as CommentIcon } from './icons/commentIcon.svg';
import useStyles from './styles';

const CommentOrder = ({ setId }) => {
  const styles = useStyles();
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Tooltip title={translate('ra.title.comment_order')}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setId(record.id);
        }}
        className={styles.root}
        size="small"
      >
        <CommentIcon />
      </IconButton>
    </Tooltip>
  );
};

const mapDispatch = {
  setId: setOrderCommentId,
};

export default connect(null, mapDispatch)(CommentOrder);
