import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  tableCell: {
    width: '15px',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'end',
  },
  line: {
    '& ul': {
      '& li': {
        borderBottom: 'unset',
        display: 'flex',
        alignItems: 'center',
        '& section': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& p': {
          display: 'none',
        },
      },
    },
  },
  spaceBetween: {
    margin: '10px 7px',
  },
  marginBottom: {
    marginBottom: '15px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default useStyles;
