import url from '../../../config/connection';
import { postWithAuthorization } from '../../../utils/fetchWithAuthorization';

export const runCampaignInit = (e, id, simulate = false) => {
  e.stopPropagation();

  return postWithAuthorization(`${url}/marketing-campaign-runs/${id}/init`, {
    body: JSON.stringify({ simulate }),
  });
};
