import React, { useState } from 'react';
import { swap } from 'react-grid-dnd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Section from './Section';

const DraggableSections = ({
  setSections,
  sections,
  classes,
  setManagedSection,
  removeSection,
  setIsSavedChanges,
}) => {
  const [isDragDisabled, setIsDragDisabled] = useState(true);

  const onDragEnd = ({ source, destination }) => {
    const nextOrder = swap(sections, source.index, destination.index);

    setSections(() => [...nextOrder]);
  };

  const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="sections">
        {(provided) => {
          return (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sections.map((section, idx) => {
                return (
                  <Draggable
                    isDragDisabled={isDragDisabled}
                    key={section.id + section.label}
                    draggableId={section.id}
                    index={idx}
                  >
                    {(dragProvided) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        style={getItemStyle(dragProvided.draggableProps.style)}
                        onDoubleClick={() => setIsDragDisabled(!isDragDisabled)}
                      >
                        <Section
                          isDragDisabled={!isDragDisabled}
                          key={section.id + section.label}
                          section={section}
                          classes={classes}
                          setManagedSection={setManagedSection}
                          setSections={setSections}
                          removeSection={removeSection}
                          setIsSavedChanges={setIsSavedChanges}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableSections;
