import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton, Dialog, DialogContent, Chip } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useRecordContext, useTranslate, useUpdate } from 'react-admin';
import { LocalizationProvider, StaticTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';

const PickupTimePicker = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [time, setTime] = useState(null);
  const record = useRecordContext();
  const translate = useTranslate();

  const datefnsDate = parseISO(record?.deliveryDetails?.pickupTime);

  const [update] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleAcceptClick = () => {
    // update order
    update(
      'order',
      {
        id: record.id,
        data: {
          ...record,
          deliveryDetails: {
            ...record.deliveryDetails,
            pickupTime: time,
          },
        },
        previousData: record,
      },
      {
        onSuccess: () => {
          setShowDialog(false);
        },
      },
    );
  };

  useEffect(() => {
    setTime(record?.deliveryDetails?.pickupTime);
  }, [record]);

  const handleChange = (value) => {
    setTime(value);
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Chip label={`${format(new Date(time), 'HH:mm')}`} />
        <Tooltip title={translate('ra.title.pickupTime')}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
            size="small"
          >
            <AccessTimeIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Dialog
        open={showDialog}
        onClose={() => handleCloseClick()}
        onClick={(e) => e.stopPropagation()}
        aria-label="Edit pickup time"
        closeAfterTransition
        disableEnforceFocus
      >
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticTimePicker
              orientation="landscape"
              onAccept={() => handleAcceptClick()}
              onClose={() => handleCloseClick()}
              defaultValue={datefnsDate}
              onChange={(value) => handleChange(value.toISOString())}
              ampm={false}
            />
          </LocalizationProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PickupTimePicker;
