import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../../components/ListDeleteConfirmationButton';

const MarketingCampaignList = ({ ...props }) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.name" source="name" />
      </Datagrid>
    </List>
  );
};

export default MarketingCampaignList;
