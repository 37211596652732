import React from 'react';
import { Table, TableCell, TableHead, TableRow, Paper, TableBody, Card } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import { stringify } from 'query-string';
import styles from '../../style';

const TransactionDataDropdown = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  const { transactionData, processData } = record;

  return (
    <div style={styles.dropdownWrapper}>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate('ra.label.terminal')}</TableCell>
              <TableCell>{translate('ra.label.description')}</TableCell>
              <TableCell>{translate('ra.label.message')}</TableCell>
              <TableCell>{translate('ra.label.masked_card')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={record?.id}>
              <TableCell>{transactionData.TERMINAL}</TableCell>
              <TableCell>{transactionData.DESC}</TableCell>
              <TableCell>{transactionData.MESSAGE}</TableCell>
              <TableCell>{transactionData.maskedCard}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      {processData && (
        <Card style={{ maxWidth: '50%', padding: '20px', marginLeft: '20px' }}>
          <code>{stringify(processData.processResponse)}</code>
        </Card>
      )}
    </div>
  );
};

export default TransactionDataDropdown;
