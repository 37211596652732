export const MARKETING_CAMPAIGN_SOURCE_TYPE = [
  {
    id: 'DB_FILTER',
    name: 'DB_FILTER',
  },
  {
    id: 'RAW_LIST',
    name: 'RAW_LIST',
  },
];

export const MARKETING_CAMPAIGN_ACTION_TYPE = [
  { id: 'DISCOUNT', name: 'DISCOUNT' },
];

export const MARKETING_CAMPAIGN_ACTION_DEFAULT_VALUES = [
  { type: null, discountId: null, validityInHours: null },
];
