import PaymentConfigCreate from './components/Create';
import PaymentConfigEdit from './components/Edit';
import PaymentConfigList from './components/List';
import PaymentConfigShow from './components/Show';

export default {
  list: PaymentConfigList,
  create: PaymentConfigCreate,
  edit: PaymentConfigEdit,
  show: PaymentConfigShow,
};
