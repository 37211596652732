import React from 'react';
import { TextField, Show, SimpleShowLayout } from 'react-admin';
import S3FileField from '../../../components/S3FileUploader/Field';
import { baseUrl } from '../../../config/connection';
import ColorField from '../../../components/ColorField';
import { ColorFieldsList } from '../common/Form';

const UIThemeShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ra.label.title" source="title" />
      <S3FileField apiRoot={baseUrl} source="logo" />
      <S3FileField apiRoot={baseUrl} source="logoMobile" />
      <S3FileField apiRoot={baseUrl} source="logoMobileApp" />
      <TextField label="ra.label.phone" source="phone" />
      <TextField label="ra.label.email" source="email" />
      <TextField label="ra.label.app_store_link" source="links.appStoreLink" resettable />
      <TextField label="ra.label.google_play_link" source="links.googlePlayLink" resettable />
      <TextField label="ra.label.category_style" source="flags.categoryStyle" resettable />
      {ColorFieldsList.map((fieldName) => (
        <ColorField label={fieldName} source={`colors.${fieldName}`} />
      ))}
    </SimpleShowLayout>
  </Show>
);

export default UIThemeShow;
