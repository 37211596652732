import React from 'react';
import { ReferenceField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const NotificationLoggingShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="ra.tab.summary">
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.action" source="action" />
        <TextField label="ra.label.status" source="status" />
        <ReferenceField
          label="ra.label.notification"
          source="notificationId"
          reference="notification"
          link="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField label="ra.label.message" source="message" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default NotificationLoggingShow;
