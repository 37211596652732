import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  input: {
    height: '16px',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '30px',
  },
});

export default useStyles;
