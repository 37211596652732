import React from 'react';
import { ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

const StoreSupplierShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="ra.label.store" source="storeId" reference="store">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="ra.label.supplier" source="supplierId" reference="supplier">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="ra.label.rank" source="supplierRank" />
    </SimpleShowLayout>
  </Show>
);

export default StoreSupplierShow;
