import React from 'react';
import { BooleanField, Datagrid, List, TextField, ReferenceField } from 'react-admin';
import SupplierFilter from '../../../components/SupplierFilter';
import { ALL_USER_ROLES_NAMES } from '../../../config/user.config';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import { ListTopToolBar } from '../../../components/ListTopToolBar';

const OptionGroupList = () => {
  const isSuperAdmin = localStorage.getItem('role') === ALL_USER_ROLES_NAMES.ADMIN;
  const localSupplierId = localStorage.getItem('supplierId');
  const constantFilter = isSuperAdmin ? {} : { supplierId: localSupplierId };

  return (
    <List
      filters={<SupplierFilter />}
      filter={constantFilter}
      bulkActionButtons={<ListDeleteConfirmationButton />}
      actions={<ListTopToolBar />}
    >
      <Datagrid rowClick="show">
        <TextField label="ra.label.name" source="name" />
        <BooleanField label="ra.label.is_multiple" source="isMultiple" />
        <BooleanField label="ra.label.is_required" source="required" />
        <ReferenceField label="ra.label.supplier" source="supplierId" reference="supplier">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="ra.label.external" source="externalId" />
      </Datagrid>
    </List>
  );
};

export default OptionGroupList;
