import React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();

const PaymentConfigCreate = () => {
  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm>
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        <TextInput label="ra.label.terminal" source="terminalId" validate={validateRequired} />
      </SimpleForm>
    </Create>
  );
};

export default PaymentConfigCreate;
