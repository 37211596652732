import React from 'react';
import { Typography } from '@mui/material';
import { TextInput, useRecordContext, useTranslate } from 'react-admin';
import SORTED_LOCALES from '../constants';
import TranslatableTextInputMgStructure from './TranslatableTextInputMgStructure';
import { useOrganizationLocales } from '../../../hooks/useOrganizationLocales';

const TranslatableTextInputNav = (props) => {
  const { source, label } = props;
  const translate = useTranslate();
  const record = useRecordContext();

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const defaultLocales = useOrganizationLocales();

  const translations = [];

  if (record?.label) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(record?.label)) {
      translations.push({
        locale: key,
        value,
        property: 'label',
      });
    }
  } else {
    return <TranslatableTextInputMgStructure {...props} />;
  }

  const textInputs = translations.reduce((accum, object, key) => {
    if (object.property !== source) {
      return accum;
    }

    return {
      ...accum,
      [object.locale]: (
        <div key={object.locale} className="ml-1r">
          <TextInput
            {...props}
            sx={{ minWidth: '270px' }}
            label={object.locale}
            source={`label.${object.locale}`}
            type="text"
          />
        </div>
      ),
    };
  }, {});

  const existingLocales = translations.map((object) => {
    return object.property === source ? object.locale : null;
  });

  const necessaryLocales = defaultLocales.filter((locale) => existingLocales.indexOf(locale) < 0);

  let length = existingLocales.length;

  const addonTextInputs = necessaryLocales.map((locale) => {
    if (record.id) {
      translations.push({
        locale,
        value: 'ada',
        property: source,
      });
    }

    const field = (
      <div key={locale} className="ml-1r">
        <TextInput
          {...props}
          label={locale}
          sx={{ minWidth: '270px' }}
          source={`translations[${source}${length}]`}
          parse={(value) => ({ locale, value, property: source })}
          format={(translation) => (translation ? translation.value : '')}
          type="text"
        />
      </div>
    );

    length += 1;

    return field;
  });

  return (
    <div>
      <Typography className="mt-1r">{translate(label) || capitalize(source)}</Typography>
      {SORTED_LOCALES.map((orderedTranslationKey) => textInputs[orderedTranslationKey])}
      {addonTextInputs}
    </div>
  );
};

export default TranslatableTextInputNav;
