import {
  useEffect,
  useState,
} from 'react';
import { fetchWithAuthorization } from '../utils/fetchWithAuthorization';
import url from '../config/connection';
import RESOURCES_NAMES from '../config/resources.config';

/**
 * @returns {string[]}
 */
export const useOrganizationLocales = () => {
  const [defaultLocales, setDefaultLocales] = useState([]);

  useEffect(() => {
    fetchWithAuthorization(`${url}/${RESOURCES_NAMES.LOCALE}`)
      .then((results) => results.json())
      .then((data) => setDefaultLocales(data.map((object) => object.locale)));
  }, []);

  return defaultLocales;
};
