import React from 'react';
import { Filter } from 'react-admin';
import DataRange from './DataRange';
import SupplierArrayInput from './SupplierArrayInput';
import DiscountArrayInput from './DiscountArrayInput';

const FilterReport = ({ ...props }) => {
  return (
    <Filter {...props}>
      <SupplierArrayInput alwaysOn {...props} />
      <DiscountArrayInput alwaysOn {...props} />
      <DataRange alwaysOn resettable {...props} />
    </Filter>
  );
};

export default FilterReport;
