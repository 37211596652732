import React from 'react';
import { Datagrid, List, NumberField, TextField, TextInput } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const CategoryFilter = [
  <TextInput source="name" label="ra.label.name" alwaysOn />,
];

const CategoryList = () => (
  <List filters={CategoryFilter}>
    <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
      <TextField label="ra.label.id" source="id" />
      <TextField label="ra.label.name" source="name" />
      <TextField label="ra.label.slug" source="slug" />
      <NumberField source="rank" />

    </Datagrid>
  </List>
);

export default CategoryList;
