import React from 'react';
import { Typography } from '@mui/material';
import { TextInput, useRecordContext, useTranslate } from 'react-admin';
import { useOrganizationLocales } from '../../../hooks/useOrganizationLocales';
import { orderObjectByKeys } from '../helper';

const AbstractTranslatableInput = ({ source, label, multiline, sx: styles, ...props }) => {
  const translate = useTranslate();
  const record = useRecordContext() || {};

  const hasStylesProp = styles !== undefined;

  const applyStyles = () => {
    return hasStylesProp ? styles : { maxWidth: '270px', width: '100%' };
  };

  if (!record.translations) {
    record.translations = {};
  }

  const translations = Object.values(record.translations);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const textInputs = translations.reduce((accum, object, key) => {
    if (object.property !== source) {
      return accum;
    }

    return {
      ...accum,
      [object.locale]: (
        <div key={object.locale} className="ml-1r">
          <TextInput
            {...props}
            sx={applyStyles()}
            label={object.locale}
            source={`translations[${key}].value`}
            multiline={multiline}
            type="text"
          />
        </div>
      ),
    };
  }, {});

  const orderedTextInputs = orderObjectByKeys(textInputs);

  const existingLocales = translations.map((object) => {
    return object.property === source ? object.locale : null;
  });

  const defaultLocales = useOrganizationLocales();

  const necessaryLocales = defaultLocales.filter((locale) => existingLocales.indexOf(locale) < 0);

  let length = existingLocales.length;

  const addonTextInputs = necessaryLocales.map((locale) => {
    if (record.id) {
      translations.push({
        locale,
        value: '',
        property: source,
      });
    }

    const field = (
      <div key={locale + source} className="ml-1r">
        <TextInput
          {...props}
          label={locale}
          multiline={multiline}
          source={`translations[${source}_${length}]`}
          parse={(value) => ({ locale, value, property: source })}
          format={(translation) => (translation ? translation.value : '')}
          defaultValue={{ locale, value: '', property: source }}
          sx={applyStyles()}
          type="text"
        />
      </div>
    );

    length += 1;

    return field;
  });

  return (
    <div style={applyStyles()}>
      <Typography className="mt-1r">{translate(label) || capitalize(source)}</Typography>
      {Object.values(orderedTextInputs)}
      {addonTextInputs}
    </div>
  );
};

export default AbstractTranslatableInput;
