import React, { useState } from 'react';
import { TextField, Show, SimpleShowLayout, useRecordContext } from 'react-admin';
import { Tab, Tabs } from '@mui/material';
import S3FileField from '../../../components/S3FileUploader/Field';
import { baseUrl } from '../../../config/connection';
import { BannerType } from '../constants';
import { PreviewFrame } from '../../../UIStaticPages/components/styles';
import { TabPanel } from '../../../components/MuiTabPanel';
import { useOrganizationLocales } from '../../../hooks/useOrganizationLocales';
import { a11yProps } from '../../../utils/a11yProps';

const Layout = () => {
  const locales = useOrganizationLocales();
  const [currentLocale, setCurrentLocale] = useState('en');

  const record = useRecordContext();
  const bannerType = record?.bannerType;

  const handleChangeLocale = (_event, newValue) => {
    setCurrentLocale(newValue);
  };

  const labelStyle = { color: 'gray', fontSize: 13 };

  return (
    <SimpleShowLayout>
      <TextField label="ra.label.title" source="title" />
      <TextField label="ra.label.bannerType" source="bannerType" />
      {bannerType === BannerType.Image && (
        <>
          <p style={labelStyle}>Image Desktop</p>
          <S3FileField apiRoot={baseUrl} source="imageDesktop" />
          <p style={labelStyle}>Image Mobile</p>
          <S3FileField apiRoot={baseUrl} source="imageMobile" />
        </>
      )}
      {bannerType === BannerType.HTML && (
        <TabPanel value={currentLocale} index={currentLocale} padding="0">
          <Tabs value={currentLocale} onChange={handleChangeLocale} aria-label="locales tabs">
            {locales.map((locale, idx) => {
              return (
                <Tab
                  value={locale}
                  label={locale}
                  key={locale}
                  sx={{ width: '125px' }}
                  {...a11yProps(idx)}
                />
              );
            })}
          </Tabs>

          <p style={labelStyle}>HTML Desktop</p>
          <PreviewFrame
            height={400}
            title="preview-desktop"
            srcDoc={record.htmlDesktop?.[currentLocale]}
          />
          <p style={labelStyle}>HTML Mobile</p>
          <PreviewFrame
            height={400}
            title="preview-mobile"
            srcDoc={record.htmlMobile?.[currentLocale]}
          />
        </TabPanel>
      )}
    </SimpleShowLayout>
  );
};

const UIBannerShow = () => {
  return (
    <Show>
      <Layout />
    </Show>
  );
};

export default UIBannerShow;
