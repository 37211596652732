import React from 'react';
import { required, Create, TextInput, SimpleForm } from 'react-admin';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();

const LocaleCreate = () => {
  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm>
        <TextInput label="ra.label.locale" source="locale" resettable validate={validateRequired} />
        <TextInput label="ra.label.long" source="long" resettable validate={validateRequired} />
        <TextInput label="ra.label.short" source="short" resettable validate={validateRequired} />
      </SimpleForm>
    </Create>
  );
};

export default LocaleCreate;
