import React, { useMemo } from 'react';
import { MenuItemLink, useResourceDefinitions, useTranslate, Menu } from 'react-admin';
import {
  StorefrontOutlined,
  CollectionsBookmarkOutlined,
  CampaignOutlined,
  LocalShippingOutlined,
  PersonOutlineOutlined,
  ApartmentOutlined,
  CreditCardOutlined,
  NotificationsOutlined,
  DescriptionOutlined,
  TranslateOutlined,
  DashboardOutlined,
} from '@mui/icons-material';
import DefaultIcon from '@mui/icons-material/ViewList';
import useStyles from './styles';
import {
  CMS,
  COMPANY_MANAGEMENT,
  DELIVERY_MANAGEMENT,
  I18,
  MAIN_PART,
  MARKETING,
  MENU_MANAGEMENT,
  PAYMENT_CONFIG,
  SUPPLIER_MANAGEMENT,
  USER_MANAGEMENT,
  NOTIFICATIONS_MANAGEMENT,
  DOCUMENT_MANAGEMENT,
} from './constants';
import RESOURCES_NAMES from '../../../config/resources.config';
import { menuResourceHelper } from './menu-resource.helper';
import MenuResource from './MenuResource';

const getResourcesPart = (resources, part) => resources.filter(({ name }) => part.includes(name));

const MyMenu = ({ onMenuClick }) => {
  const styles = useStyles();
  // const resources = useMemo(() => allResources.filter((r) => r.hasList), [allResources]);
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]);
  const translate = useTranslate();

  const menuResources = useMemo(
    () =>
      [
        MAIN_PART,
        SUPPLIER_MANAGEMENT,
        MENU_MANAGEMENT,
        CMS,
        MARKETING,
        DELIVERY_MANAGEMENT,
        USER_MANAGEMENT,
        COMPANY_MANAGEMENT,
        PAYMENT_CONFIG,
        I18,
        NOTIFICATIONS_MANAGEMENT,
        DOCUMENT_MANAGEMENT,
      ].map((part) => getResourcesPart(resources, part)),
    [resources],
  );

  const [
    mainPart,
    supplierManagement,
    menuManagement,
    cms,
    marketing,
    deliveryManagement,
    userManagement,
    companyManagement,
    paymentConfig,
    i18n,
    notificationManagement,
    documentManagement,
  ] = menuResources;

  const menuResourcesWithoutCustomResources = useMemo(() => {
    // Without custom resources like cms, i18n, etc.
    const resourcesToObject = {
      supplier_management: { res: supplierManagement, icon: StorefrontOutlined },
      menu_management: { res: menuManagement, icon: CollectionsBookmarkOutlined },
      marketing: { res: marketing, icon: CampaignOutlined },
      delivery_management: { res: deliveryManagement, icon: LocalShippingOutlined },
      user_management: { res: userManagement, icon: PersonOutlineOutlined },
      company_management: { res: companyManagement, icon: ApartmentOutlined },
      payment_config: { res: paymentConfig, icon: CreditCardOutlined },
      notification_management: { res: notificationManagement, icon: NotificationsOutlined },
      document_management: { res: documentManagement, icon: DescriptionOutlined },
    };

    return menuResourceHelper(resourcesToObject);
  }, [
    companyManagement,
    deliveryManagement,
    documentManagement,
    marketing,
    menuManagement,
    notificationManagement,
    paymentConfig,
    supplierManagement,
    userManagement,
  ]);

  return (
    <Menu>
      {mainPart.map((res) => {
        const { name, options } = res;

        return (
          <MenuItemLink
            key={name}
            to={`/${name}`}
            primaryText={translate(options?.label || `resources.${name}.name`)}
            leftIcon={res.icon ? <res.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            className={styles.menuItem}
          />
        );
      })}
      {menuResourcesWithoutCustomResources
        .slice(0, 2)
        .map(({ resources: res, navigationTranslateName, options }) => {
          return (
            <MenuResource
              styles={styles}
              onMenuClick={onMenuClick}
              resourcePart={res}
              navigationTranslateName={navigationTranslateName}
              key={navigationTranslateName}
              ops={options}
            />
          );
        })}
      <MenuResource
        styles={styles}
        onMenuClick={onMenuClick}
        resourcePart={i18n}
        navigationTranslateName="i18n"
        ops={{ icon: TranslateOutlined }}
      >
        {/* Custom route Translations */}
        <MenuItemLink
          key={RESOURCES_NAMES.TRANSLATIONS}
          to={`/${RESOURCES_NAMES.TRANSLATIONS}`}
          primaryText={translate(`resources.${RESOURCES_NAMES.TRANSLATIONS}.name`)}
          leftIcon={<TranslateOutlined />}
          onClick={onMenuClick}
          className={styles.menuItem}
        />
      </MenuResource>
      <MenuResource
        styles={styles}
        onMenuClick={onMenuClick}
        resourcePart={cms}
        navigationTranslateName="cms"
        ops={{ icon: DashboardOutlined }}
      >
        {/* Custom route Footer */}
        <MenuItemLink
          key={RESOURCES_NAMES.FOOTER}
          to={`/${RESOURCES_NAMES.FOOTER}`}
          primaryText={translate(`resources.${RESOURCES_NAMES.FOOTER}.name`)}
          leftIcon={<DescriptionOutlined />}
          onClick={onMenuClick}
          className={styles.menuItem}
        />
      </MenuResource>
      {menuResourcesWithoutCustomResources
        .slice(2)
        .map(({ resources: res, navigationTranslateName, options }) => {
          return (
            <MenuResource
              styles={styles}
              onMenuClick={onMenuClick}
              resourcePart={res}
              navigationTranslateName={navigationTranslateName}
              key={navigationTranslateName}
              ops={options}
            />
          );
        })}
    </Menu>
  );
};

export default MyMenu;
