import React from 'react';
import { withStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import styles from './styles';
import { headersObj } from '../../constants';
import { REFUNDED_ORDERS_REPORT_FIELDS } from '../../../config/reportFields.config';

function PreviewData(props) {
  const { classes, data, fields } = props;
  let reportLabels = [];

  if (fields) {
    reportLabels = Object.keys(fields).filter((key) => fields[key]);
  }

  const renderColumns = (columnkey, row) => {
    if (columnkey && columnkey === 'distance') {
      return row[columnkey] ? `${row[columnkey] / 1000} km` : '';
    }

    if (columnkey && [
      REFUNDED_ORDERS_REPORT_FIELDS.REFUNDS,
      REFUNDED_ORDERS_REPORT_FIELDS.REFUNDS_COMMENTS,
      REFUNDED_ORDERS_REPORT_FIELDS.REFUNDS_CULPRITS,
    ].includes(columnkey)) {
      return row[columnkey] ? row[columnkey].map(item =>
        item && <TableRow><TableCell>{item}</TableCell></TableRow>) : '';
    }

    return row[columnkey];
  };

  return data.length ? (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {reportLabels.map((label) => (
              <TableCell key={label}>{headersObj[label]}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={row.orderId}>
                {reportLabels.map((columnkey) => {
                  return (
                    <TableCell key={columnkey}>{renderColumns(columnkey, row)}</TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  ) : null;
}

export default withStyles(styles)(PreviewData);
