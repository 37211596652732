import randomString from 'crypto-random-string';
import React from 'react';
import {
  AutocompleteInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useUpdate,
  useRedirect,
  useRecordContext,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import ResourceTitle from '../../../components/ResourceTitle';
import S3FileInput from '../../../components/S3FileUploader/Input';
import { TranslatableTextInput } from '../../../components/Translatable';
import { baseUrl } from '../../../config/connection';
import ServingSizeInput from '../ServingSizeInput';
import { syncOne } from '../../../utils/syncOne';

const validateRequired = required();

const GoodsEdit = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const postSave = (data) => {
    update(
      'goods',
      { id: data.id, data, previousData: record },
      {
        onSuccess: () => {
          syncOne({ stopPropagation: () => {} }, { id: data.id }, () => {});
          redirect('list', `supplier/${data?.supplierId}/show/goods`);
        },
      },
    );
  };

  return (
    <Edit
      title={<ResourceTitle field="name" />}
      actions={<CancelButton />}
      redirect={(_, __, data) => {
        return `supplier/${data?.supplierId}/show/goods`;
      }}
    >
      <SimpleForm onSubmit={postSave} toolbar={<EditDeleteConfirmationButton />}>
        <TranslatableTextInput label="ra.label.name" source="name" validate={validateRequired} />
        <TranslatableTextInput fullWidth label="ra.label.description" source="description" />
        <TextInput label="ra.label.slug" source="slug" validate={validateRequired} />
        <NumberInput
          parse={(v) => v * 100 || ''}
          format={(v) => v / 100 || ''}
          label="ra.label.price"
          source="price"
          placeholder="Price"
          validate={validateRequired}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
        />
        <ServingSizeInput source="servingData" />
        <ReferenceInput
          label="ra.label.category"
          source="categoryId"
          reference="category"
          perPage={150}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            sx={{ maxWidth: '218px', width: '100%' }}
            validate={validateRequired}
          />
        </ReferenceInput>
        <TextInput label="ra.label.external" source="externalId" />
        <S3FileInput
          apiRoot={baseUrl}
          source="cover"
          validate={validateRequired}
          uploadOptions={{
            signingUrl: `${baseUrl}/s3/sign`,
            s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
            multiple: false,
            accept: 'image/*',
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default GoodsEdit;
