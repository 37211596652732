import React from 'react';
import { Button } from '@mui/material';
import ReactS3Uploader from 'react-s3-uploader';

const AddFileBtn = ({ label, uploadOptions }) => (
  <>
    <label htmlFor={uploadOptions.id}>
      <Button variant="contained" component="span">
        {label}
      </Button>
    </label>
    <ReactS3Uploader {...uploadOptions} />
  </>
);

export default AddFileBtn;
