import React, { useState, useMemo } from 'react';
import { Title, useTranslate } from 'react-admin';
import { withStyles } from '@mui/styles';
import { CardContent, Card, Tabs, Tab } from '@mui/material';
import styles from './style';
import CourierReport from './components/CourierReport';
import SupplierReport from './components/SupplierReport';
import { ALL_USER_ROLES_NAMES } from '../config/user.config';
import RefundedOrdersReport from './components/RefundedOrdersReport';
import InvoiceReport from './components/InvoiceReport';

const getDataHeadings = (data) => {
  return data.reduce((keysList, dataRow) => {
    const rowKeys = Object.keys(dataRow);

    return Array.from(new Set([...rowKeys, ...keysList]));
  }, []);
};

const Report = ({ classes }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const adminRole = useMemo(() => localStorage.getItem('role'), []);
  const supplierId = useMemo(() => localStorage.getItem('supplierId'), []);
  const isIFoodAdmin = adminRole === ALL_USER_ROLES_NAMES.ADMIN;

  const translate = useTranslate();

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  return (
    <Card className={classes.root}>
      <Title title={translate('ra.title.reports')} />
      <CardContent>
        {isIFoodAdmin && (
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: '#477890' } }}
            textColor="primary"
            style={{ color: '#477890' }}
          >
            <Tab label={translate('ra.tab.courier')} style={{ color: '#477890' }} />
            <Tab label={translate('ra.tab.suppliers')} style={{ color: '#477890' }} />
            <Tab label={translate('ra.tab.refundedOrders')} style={{ color: '#477890' }} />
            <Tab label={translate('ra.tab.invoices')} style={{ color: '#477890' }} />
          </Tabs>
        )}
        {isIFoodAdmin && currentTab === 0 && <CourierReport getDataHeadings={getDataHeadings} />}
        {isIFoodAdmin && currentTab === 1 && <SupplierReport getDataHeadings={getDataHeadings} />}
        {isIFoodAdmin && currentTab === 2 && (
          <RefundedOrdersReport getDataHeadings={getDataHeadings} />
        )}
        {isIFoodAdmin && currentTab === 3 && (
          <InvoiceReport getDataHeadings={getDataHeadings} />
        )}
        {!isIFoodAdmin && (
          <SupplierReport getDataHeadings={getDataHeadings} supplierId={supplierId} />
        )}
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(Report);
