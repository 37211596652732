export const courierReportHeaders = [
  { label: 'Order Id', key: 'orderId' },
  { label: 'Supplier', key: 'orderSupplier' },
  { label: 'Courier Id', key: 'courierId' },
  { label: 'Courier First Name', key: 'courierFirstName' },
  { label: 'Courier Last Name', key: 'courierLastName' },
  { label: 'Client Address', key: 'clientAddress' },
  { label: 'Payment Type', key: 'orderPaymentType' },
  { label: 'Distance', key: 'distance' },
  { label: 'Order Original Price', key: 'orderOriginalPrice' },
  { label: 'Items Price', key: 'orderItemsPrice' },
  { label: 'Delivery Price', key: 'orderDeliveryPrice' },
  { label: 'Order creation time', key: 'orderOrderedAt' },
  { label: 'Status of delivery', key: 'deliveryStatus' },
  { label: 'Total Price', key: 'orderTotalPrice' },
  { label: 'Order Comment', key: 'orderComment' },
  { label: 'Order Reference', key: 'orderReference' },
  { label: 'Branch', key: 'orderBranch' },
  { label: 'Courier Cost', key: 'courierCost' },
];

export const adminReportHeaders = [
  { label: 'Order Id', key: 'orderId' },
  { label: 'Supplier', key: 'orderSupplier' },
  { label: 'Branch', key: 'orderBranch' },
  { label: 'Order Creation Time', key: 'orderOrderedAt' },
  { label: 'Client Full Name', key: 'clientFullName' },
  { label: 'Client Phone', key: 'clientPhone' },
  { label: 'Client Address', key: 'clientAddress' },
  { label: 'Payment Type', key: 'orderPaymentType' },
  { label: 'Order Original Price', key: 'orderOriginalPrice' },
  { label: 'Items Price', key: 'orderItemsPrice' },
  { label: 'Delivery Price', key: 'orderDeliveryPrice' },
  { label: 'Total Price', key: 'orderTotalPrice' },
  { label: 'Refunds', key: 'refunds' },
  { label: 'Refunds Comments', key: 'refundsComments' },
  { label: 'Refunds Culprits', key: 'refundsCulprits' },
  { label: 'Order Comment', key: 'orderComment' },
  { label: 'Order Reference', key: 'orderReference' },
  { label: 'Courier Full Name', key: 'courierFullName' },
  { label: 'Validation Duration ', key: 'validationDuration' },
  { label: 'Confirmation Duration', key: 'confirmationDuration' },
  { label: 'Courier Assignment Duration', key: 'courierAssignmentDuration' },
  { label: 'Preparation Duration', key: 'preparationDuration' },
  { label: 'Courier Arrival Duration', key: 'courierArrivalDuration' },
  { label: 'Courier Wait Duration', key: 'courierWaitDuration' },
  { label: 'Restaurant Wait Duration', key: 'restaurantWaitDuration' },
  { label: 'Delivery Duration', key: 'deliveryDuration' },
  { label: 'Total Duration', key: 'totalDuration' },
  { label: 'Status of delivery', key: 'deliveryStatus' },
  { label: 'Branch', key: 'orderBranch' },
  { label: 'Fiscal Difference', key: 'fiscalDifference' },
  { label: 'Distance', key: 'distance' },
  { label: 'Courier Cost', key: 'courierCost' },
  { label: 'Mobile', key: 'isMobile' },
];

export const refundedOrdersReportHeaders = [
  { label: 'Order Id', key: 'orderId' },
  { label: 'Order creation time', key: 'orderOrderedAt' },
  { label: 'Supplier', key: 'orderSupplier' },
  { label: 'Branch', key: 'orderBranch' },
  { label: 'Courier Full Name', key: 'courierFullName' },
  { label: 'Client Full Name', key: 'clientFullName' },
  { label: 'Payment Type', key: 'orderPaymentType' },
  { label: 'Delivery Price', key: 'orderDeliveryPrice' },
  { label: 'Total Price', key: 'orderTotalPrice' },
  { label: 'Returned To Office', key: 'isReturnedToOffice' },
  { label: 'Refunded', key: 'isRefunded' },
];

export const MONTHLY_REPORT_HEADERS = [
  { label: 'Supplier', key: 'orderSupplier' },
  { label: 'Order Creation Time', key: 'orderOrderedAt' },
  { label: 'Payment Type', key: 'orderPaymentType' },
  { label: 'CARD', key: 'cardPrice' },
  { label: 'CASH', key: 'cashPrice' },
  { label: 'Delivery Price', key: 'orderDeliveryPrice' },
  { label: 'Courier Cost', key: 'courierCost' },
];

export const headersObj = {
  courierId: 'Courier Id',
  courierFirstName: 'Courier First Name',
  courierLastName: 'Courier Last Name',
  orderOrderedAt: 'Order creation time',
  orderId: 'Order Id',
  orderPaymentType: 'Payment Type',
  orderDeliveryPrice: 'Delivery Price',
  orderSupplier: 'Supplier',
  orderBranch: 'Branch',
  deliveryStatus: 'Status of delivery',
  clientFullName: 'Client Full Name',
  clientPhone: 'Client Phone',
  clientAddress: 'Client Address',
  orderItemsPrice: 'Items Price',
  orderTotalPrice: 'Total Price',
  orderReference: 'Order Reference',
  orderComment: 'Order Comment',
  orderDeliveredAt: 'Fulfillment Date',
  courierFullName: 'Courier Full Name',
  orderOriginalPrice: 'Order Original Price',
  validationDuration: 'Validation Duration ',
  confirmationDuration: 'Confirmation Duration',
  courierAssignmentDuration: 'Courier Assignment Duration',
  preparationDuration: 'Preparation Duration',
  courierArrivalDuration: 'Courier Arrival Duration',
  courierWaitDuration: 'Courier Wait Duration',
  restaurantWaitDuration: 'Restaurant Wait Duration',
  deliveryDuration: 'Delivery Duration',
  totalDuration: 'Total Duration',
  fiscalDifference: 'Fiscal Difference',
  distance: 'Distance',
  courierCost: 'Courier Cost',
  isReturnedToOffice: 'Returned To Office',
  isRefunded: 'Refunded',
  refunds: 'Refunds',
  refundsComments: 'Refunds Comments',
  refundsCulprits: 'Refunds Culprits',
  isMobile: 'Mobile',
};

export const ON_CHANGE_ACTION = {
  PAYMENT_METHOD: 'changePaymentMethod',
  REPORT_FIELDS: 'changeField',
};
