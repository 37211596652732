import DocumentCreate from './components/Create';
import DocumentEdit from './components/Edit';
import DocumentList from './components/List';
import DocumentShow from './components/Show';

export default {
  create: DocumentCreate,
  edit: DocumentEdit,
  list: DocumentList,
  show: DocumentShow,
};
