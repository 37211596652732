import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Select, FormControl, InputLabel, MenuItem, Button, Box } from '@mui/material';
import 'react-day-picker/lib/style.css';
import { useTranslate } from 'react-admin';
import { TabStyle, exportSection } from '../../style';
import url from '../../../config/connection';
import TimeRangePicker from '../TimeRangePicker';
import Modal from '../../../components/Modal';
import { parseDateToDDMMYYY } from '../../../utils/timeOperations';
import {
  fetchWithAuthorization,
  postWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import formatDateTime from '../../../utils/formatDateTime';
import { DEFAULT_REPORT_FIELDS } from '../../../config/reportFields.config';
import PreviewData from '../PreviewData';
import CSVExport from '../CSVExport';
import { adminReportHeaders } from '../../constants';
import CheckBoxTable from '../CheckBoxTable';
import InvoicePDFReport from '../PDFReports/InvoicePDFReport';

const InvoiceReport = ({ getDataHeadings, companyId }) => {
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [fields, setFields] = useState(DEFAULT_REPORT_FIELDS.INVOICE);

  const [pdfReportData, setPdfReportData] = useState([]);

  const translate = useTranslate();

  const tableHeaders = useMemo(() => adminReportHeaders.filter(({ key }) => fields[key]), [fields]);

  const fetchInvoicesData = useCallback(async () => {
    const result = await postWithAuthorization(`${url}/report/invoices/export`, {
      body: JSON.stringify({
        selectedCompany,
        dateRange,
        reportFields: fields,
      }),
    });

    const data = await result.json();

    data.forEach((row, index) => {
      if (index === data.length - 1) {
        return row;
      }

      const { date, time } = formatDateTime(row.orderOrderedAt);

      row.orderOrderedAt = `${date} ${time}`;
    });

    return data;
  }, [dateRange, fields, selectedCompany]);

  const fetchInvoicesReport = useCallback(async () => {
    if (!(selectedCompany || (dateRange.from && dateRange.to))) {
      return;
    }

    const data = await fetchInvoicesData();

    setReportData(data);
  }, [dateRange, selectedCompany, fetchInvoicesData]);

  useEffect(() => {
    if (!(selectedCompany || (dateRange.from && dateRange.to))) {
      return;
    }

    const getReportData = async () => {
      const data = await fetchInvoicesData();
      setPdfReportData(data);
    };

    getReportData();
  }, [dateRange, selectedCompany, fetchInvoicesData]);

  useEffect(() => {
    if (companyId) {
      setSelectedCompany([Number(companyId)]);
    }
  }, [companyId]);

  const fetchCompanies = useCallback(() => {
    if (companies.length) {
      return;
    }

    fetchWithAuthorization(`${url}/company`)
      .then((res) => res.json())
      .then((data) => setCompanies(data))
      .catch(() => {
        throw new Error('failed to fetch');
      });
  }, [companies]);

  const handleCompanySelect = useCallback((event) => {
    const {
      target: { value },
    } = event;
    setSelectedCompany(value);
  }, []);

  const composeCalendarBtnTitle = () => {
    const { from, to } = dateRange;
    const isSelectedRange = from && to;

    if (!isSelectedRange) {
      return translate('ra.action.select_time_range');
    }

    return `${parseDateToDDMMYYY(from)} - ${parseDateToDDMMYYY(to)}`;
  };

  const changeField = (field, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  return (
    <div style={TabStyle.wrapper}>
      {!companyId && (
        <FormControl style={{ minWidth: '250px', maxWidth: '550px' }}>
          <InputLabel htmlFor="supplierId">{translate('ra.label.company_select')}</InputLabel>
          <Select
            value={selectedCompany}
            onChange={handleCompanySelect}
            onOpen={fetchCompanies}
            inputProps={{
              name: 'company',
              id: 'companyId',
            }}
          >
            {companies.map(({ id, legalName }) => {
              return <MenuItem key={id} value={id}>{`${legalName}`}</MenuItem>;
            })}
          </Select>
        </FormControl>
      )}
      <Button
        variant="outlined"
        color="primary"
        style={{ margin: '15px 0', color: '#477890', borderColor: '#477890' }}
        onClick={() => {
          setCalendarModalOpen(true);
        }}
      >
        {composeCalendarBtnTitle()}
      </Button>
      <Modal open={calendarModalOpen} onClose={() => setCalendarModalOpen(false)}>
        <TimeRangePicker range={dateRange} changeRange={setDateRange} />
      </Modal>
      <div style={exportSection}>
        <CSVExport headers={tableHeaders} data={reportData} />
      </div>
      <CheckBoxTable data={fields} onChangeAction={changeField} type="changeField" />
      <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', minWidth: 400 }}>
        <Button onClick={fetchInvoicesReport} variant="outlined" color="primary">
          {translate('ra.action.generate_report')}
        </Button>
        <InvoicePDFReport
          reportData={pdfReportData}
          dateRange={dateRange}
          tableHeaders={tableHeaders}
        />
      </Box>
      <PreviewData fields={fields} data={reportData} getDataHeadings={getDataHeadings} />
    </div>
  );
};

export default InvoiceReport;
