import React, { useState, useEffect } from 'react';
import {
  BooleanInput,
  Edit,
  ReferenceArrayInput,
  required,
  minValue,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  NumberInput,
  FormDataConsumer,
  useTranslate,
  useRefresh,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { baseUrl } from '../../../config/connection';
import { TranslatableTextInput } from '../../../components/Translatable';
import OptionGroupTitle from '../OptionGroupTitle';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';

const validateRequired = required();
const validatePositiveRange = [minValue(0)];

const OptionGroupEdit = () => {
  const [isMultiple, setIsMultiple] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const translate = useTranslate();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const location = useLocation();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { pathname } = location;

  useEffect(() => {
    fetchWithAuthorization(`${baseUrl}/admin${pathname}`)
      .then((res) => res.json())
      .then((res) => {
        setIsMultiple(res.isMultiple);
      });
  }, [pathname]);

  const handleSave = (values) => {
    setIsLoading(true);
    dataProvider.update('optionGroup', { id: values.id, data: values })
      .then(({ data }) => {
        notify('Option group updated successfully\'', { type: 'success', autoHideDuration: 2000 });
        navigate(-1);
        refresh();
      })
      .catch((error) => {
        notify('Update error', { type: 'error', autoHideDuration: 2000 });
      }).finally(() => setIsLoading(false));
  };

  return (
    <Edit
      title={<OptionGroupTitle />}
      actions={<CancelButton />}
    >
      {isLoading && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          zIndex: 1000,
        }}
        >
          <CircularProgress color="primary" size="40px" />
        </div>
      )}

      <SimpleForm toolbar={<EditDeleteConfirmationButton />} onSubmit={handleSave}>
        <TranslatableTextInput label="ra.label.name" source="name" validate={validateRequired} />
        <ReferenceArrayInput label="ra.label.option" source="itemsIds" reference="option">
          <SelectArrayInput optionText="name" optionValue="id" sx={{ minWidth: '270px' }} />
        </ReferenceArrayInput>
        <TextInput label="ra.label.external" source="externalId" sx={{ minWidth: '270px' }} />
        {isMultiple && (
          <>
            <NumberInput
              label="ra.label.maxSelectedOptions"
              source="maxSelectedOptions"
              validate={validatePositiveRange}
              sx={{ minWidth: '270px' }}
            />
            <span style={{ fontSize: '13px', color: '#0000008a', display: 'block' }}>
              {translate('ra.label.unlimitedOptions')}
            </span>
          </>
        )}
        <BooleanInput
          label="ra.label.is_multiple"
          source="isMultiple"
          onChange={(e) => setIsMultiple(e.target.checked)}
        />
        <BooleanInput label="ra.label.is_required" source="required" />
        <FormDataConsumer>
          {({ formData }) => {
            formData.maxSelectedOptions = formData.isMultiple ? formData.maxSelectedOptions : null;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default OptionGroupEdit;
