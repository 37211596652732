export default {
  container: {
    border: '1px solid #ddd',
    padding: '20px 10px',
    borderRadius: '3px',
    position: 'relative',
    margin: '30px 0',
    fontWeight: '500',
  },
  heading: {
    position: 'absolute',
    top: '-29px',
    padding: '0 10px',
    backgroundColor: '#fff',
    fontWeight: '500',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  // TimeFilter styles
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  formControlLabel: {
    marginRight: '0px',
  },
  addCardButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '320px',
    minHeight: '336px',
  },
  buttonWrapper: {
    width: '100%',
    height: '100%',
  },
  buttonIcon: {
    fontSize: 100,
    color: '#ccc',
  },
  cardItemCommon: {
    margin: '0 10px',
  },
  scheduleTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
