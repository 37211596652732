import React from 'react';
import { List, useListController, useResourceContext } from 'react-admin';
import OrderListDatagrid from '../OrderListDatagrid';
import PostPagination from '../PostPagination';

const listResourceMap = {
  'order/v1/scheduled': 'order/v1/scheduled',
};

const ScheduledOrderList = () => {
  const listContext = useListController();
  const resource = useResourceContext();

  return (
    <List
      pagination={<PostPagination />}
      resource={listResourceMap[resource]}
      actions={false}
      queryOptions={{
        refetchInterval: 10000, // 10 seconds
        refetchIntervalInBackground: 10000, // 10 seconds
      }}
      sx={{
        paddingTop: '50px',
      }}
    >
      <OrderListDatagrid listProps={listContext} />
    </List>
  );
};

export default ScheduledOrderList;
