import React from 'react';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  useRefresh,
} from 'react-admin';
import SyncIcon from '@mui/icons-material/Sync';
import { IconButton, Tooltip } from '@mui/material';
import PriceField from '../../../components/PriceField';
import SupplierFilter from '../../../components/SupplierFilter';
import { syncOne } from '../../../utils/syncOne';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import { ListTopToolBar } from '../../../components/ListTopToolBar';
import { useUiSettings } from '../../../hooks/useUiSettings';

const GoodsList = () => {
  const refresh = useRefresh();
  const uiSettings = useUiSettings();

  return (
    <List filters={<SupplierFilter />} perPage={25} actions={<ListTopToolBar />}>
      <Datagrid
        rowClick="show"
        bulkActionButtons={<ListDeleteConfirmationButton updateDeletedAt />}
      >
        <TextField label="ra.label.name" source="name" />
        <PriceField
          label="ra.label.price"
          source="price"
          parse={(v) => v / 100}
          options={{
            style: 'currency',
            currency: uiSettings?.currency || '',
          }}
        />
        <NumberField label="ra.label.weight" source="weight" />
        <ReferenceField
          label="ra.label.category"
          source="categoryId"
          reference="category"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="ra.label.supplier"
          source="supplierId"
          reference="supplier"
          linkType="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="ra.label.external" source="externalId" />
        <BooleanField label="ra.label.available" source="isActive" />
        <FunctionField
          label="Syncronize"
          render={(record) => {
            return record.isSynced ? (
              <span>Synced</span>
            ) : (
              <Tooltip title="Sync good">
                <IconButton
                  onClick={(e) => syncOne(e, record, refresh)}
                  size="small"
                  color="success"
                >
                  <SyncIcon />
                </IconButton>
              </Tooltip>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default GoodsList;
