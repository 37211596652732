export const styles = {
  default: {
    fontWeight: 'bold',
    width: '151px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  servingSizeField: {
    width: 190,
  },
  unitField: {
    marginLeft: '4px',
    width: 60,
  },
  menu: {
    width: 200,
  },
};

export default styles;
