import React from 'react';
import { Edit, NumberInput, required, SimpleForm, TextInput, useRecordContext } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import { TranslatableTextInput } from '../../../components/Translatable';

const validateRequired = required();

const CategoryTitle = () => {
  const record = useRecordContext();

  return <span>{`${record?.name}`}</span>;
};

const CategoryEdit = (props) => (
  <Edit {...props} title={<CategoryTitle />} actions={<CancelButton />}>
    <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
      <TranslatableTextInput label="ra.label.name" source="name" />
      <TextInput label="ra.label.slug" source="slug" validate={validateRequired} />
      <NumberInput source="rank" />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;
