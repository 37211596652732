import SettingsCreate from './components/Create';
import SettingsEdit from './components/Edit';
import SettingsList from './components/List';
import SettingsShow from './components/Show';

export default {
  list: SettingsList,
  create: SettingsCreate,
  show: SettingsShow,
  edit: SettingsEdit,
};
