export const PERK_TYPES = {
  PERCENT_PERK: 'PERCENT_PERK',
  AMOUNT_PERK: 'AMOUNT_PERK',
  FREE_DELIVERY_PERK: 'FREE_DELIVERY_PERK',
};

export const FILTER_TYPES = {
  PAYMENT_FILTER: 'PAYMENT_FILTER',
  SUM_FILTER: 'SUM_FILTER',
  TIME_FILTER: 'TIME_FILTER',
};

export const PERK_VALUE_KEY = {
  [PERK_TYPES.PERCENT_PERK]: 'amount',
  [PERK_TYPES.AMOUNT_PERK]: 'amount',
};

export const DEFAULT_PERK_VALUES = {
  [PERK_TYPES.PERCENT_PERK]: 15,
  [PERK_TYPES.AMOUNT_PERK]: 69,
};

export const PAYMENT_TYPES = {
  CASH: 'CASH',
  CARD: 'CARD',
};

export const CARD_TYPES = {
  MASTERCARD: 'MASTERCARD',
  VISA: 'VISA',
  OTHER: 'OTHER',
  ALL: 'ALL',
};

export const DEFAULT_FILTER_VALUES = {
  [FILTER_TYPES.PAYMENT_FILTER]: {
    paymentType: PAYMENT_TYPES.CASH,
    cardType: null,
    binsRange: null,
  },
  [FILTER_TYPES.SUM_FILTER]: {
    minimumAmount: 100,
  },
  [FILTER_TYPES.TIME_FILTER]: {
    schedule: [],
  },
};
