import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import OrderList from './components/List';
import OrderShow from './components/Show';
import OrderEdit from './components/Edit';

export default {
  list: OrderList,
  show: OrderShow,
  edit: OrderEdit,
  icon: PlaylistAddCheckOutlinedIcon,
};
