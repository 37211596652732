import React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import UISettingsForm from '../common/Form';

const SettingsCreate = () => {
  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm>
        <UISettingsForm />
      </SimpleForm>
    </Create>
  );
};

export default SettingsCreate;
