import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Button,
  Checkbox,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useTranslate } from 'react-admin';
import Day from '../components/Schedule/Day';
import availabilitySchedule from '../utils/scheduleDefaultValues';

import styles from './styles';

const WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const ScheduleEditDialog = ({ good, onChange, isDialogOpen, onClose }) => {
  const [localScheduleValue, setLocalScheduleValue] = useState(
    good.schedule || availabilitySchedule,
  );

  const [scheduleStatus, setScheduleStatus] = useState(!good.schedule?.isActive);
  const translate = useTranslate();

  const onScheduleChange = ({ day, field, newVal }) => {
    const newLocalValue = {
      ...localScheduleValue,
      [day]: {
        ...localScheduleValue[day],
        [field]: newVal,
      },
    };

    setLocalScheduleValue(newLocalValue);
  };

  const scheduleActionHandler = () => {
    const newLocalValue = {
      ...localScheduleValue,
      isActive: scheduleStatus,
    };

    setLocalScheduleValue(newLocalValue);
    setScheduleStatus(!scheduleStatus);
  };

  return (
    <Dialog open={!!isDialogOpen} onClose={() => onClose(localScheduleValue)}>
      <DialogTitle>
        <div style={styles.goodCardDialogTitle}>
          <span>{translate('ra.action.edit_schedule')}</span>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => onClose(localScheduleValue)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <Checkbox checked={!scheduleStatus} onChange={scheduleActionHandler} />
        <span>{translate('ra.action.use_schedule')}</span>

        {WEEK.map((day) => (
          <Day
            key={`${day}`}
            day={day}
            data={localScheduleValue[day]}
            onChange={onScheduleChange}
            disabled={scheduleStatus}
          >
            {translate(`ra.week_day.${day}`)}
          </Day>
        ))}
        <FormControl style={styles.goodCardScheduleActions}>
          <Button
            style={styles.modalScheduleButton}
            variant="contained"
            color="primary"
            onClick={() => onClose(localScheduleValue)}
          >
            {translate('ra.action.save')}
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default ScheduleEditDialog;
