import React from 'react';
import {
  AutocompleteArrayInput,
  Edit,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import ResourceTitle from '../../../components/ResourceTitle';

const validateRequired = required();

const CompanyEdit = () => {
  return (
    <Edit title={<ResourceTitle field="legalName" />} actions={<CancelButton />}>
      <SimpleForm>
        <TextInput label="ra.label.name" source="legalName" validate={validateRequired} />
        <TextInput label="ra.label.phone" source="phoneNumber" validate={validateRequired} />
        <TextInput label="ra.label.email" source="email" validate={validateRequired} />
        <TextInput label="ra.label.contract" source="contractNumber" validate={validateRequired} />
        <ReferenceArrayInput source="users_ids" reference="user" perPage={100}>
          <AutocompleteArrayInput
            label="Users"
            optionValue="id"
            filterToQuery={(searchText) => ({ email: searchText })}
            optionText={(record) => {
              return record.email || record.phone;
            }}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default CompanyEdit;
