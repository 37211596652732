import React from 'react';
import { TextInput, Filter, DateInput, NumberInput } from 'react-admin';

export const NotificationLoggingFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="ra.label.status" source="status" alwaysOn />
      <NumberInput label="ra.label.notificationEventId" source="notificationId" alwaysOn />
      <DateInput label="ra.label.created_at" source="createdAt" alwaysOn ressetable />
    </Filter>
  );
};
