import React from 'react';
import { TextField, Show, TabbedShowLayout, Tab, ArrayField, SingleFieldList } from 'react-admin';

const MarketingCampaignShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="ra.tab.campaign">
        <TextField label="ra.label.name" source="name" />
        <ArrayField source="actions" label="ra.label.marketing_action_type">
          <SingleFieldList>
            <TextField source="type" />
          </SingleFieldList>
        </ArrayField>
        <TextField label="ra.label.marketing_source_filter_type" source="source.type" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default MarketingCampaignShow;
