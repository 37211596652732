import DiscountShow from './components/Show';
import DiscountEdit from './components/Edit';
import DiscountList from './components/List';
import DiscountCreate from './components/Create';

export default {
  list: DiscountList,
  create: DiscountCreate,
  edit: DiscountEdit,
  show: DiscountShow,
};
