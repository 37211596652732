import styled from 'styled-components';

export const ColorPreview = styled.div`
  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: ${({ color }) => color};
    border: 1px solid;
    border-radius: 4px;
    top: 5px;
    margin-right: 8px;
    position: relative;
  }
`;
