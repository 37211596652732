import React from 'react';
import { Button, useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { withStyles } from '@mui/styles';
import deleteDiscount from '../../utils/deleteDiscount';

const styles = {
  button: {
    backgroundColor: '#eb020e',
    textAlign: 'center',
    '&:hover': { backgroundColor: '#ff0512' },
  },
};

const DeleteDiscountButton = ({ classes }) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const translate = useTranslate();
  const notify = useNotify();

  return (
    <Button
      onClick={() => {
        deleteDiscount(record, refresh, notify);
      }}
      className={classes.button}
      variant="raised"
      label={translate('ra.action.delete')}
      title={translate('ra.action.delete')}
    />
  );
};

export default withStyles(styles)(DeleteDiscountButton);
