import StoreSupplierCreate from './components/Create';
import StoreSupplierEdit from './components/Edit';
import StoreSupplierList from './components/List';
import StoreSupplierShow from './components/Show';

export default {
  create: StoreSupplierCreate,
  edit: StoreSupplierEdit,
  list: StoreSupplierList,
  show: StoreSupplierShow,
};
