import React from 'react';
import { Datagrid, List, TextField, TextInput } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const TagFilter = [
  <TextInput source="name" label="ra.label.name" alwaysOn />,
];

const TagsList = () => {
  return (
    <List filters={TagFilter}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.name" source="name" />
        <TextField label="ra.label.label" source="label" />
        <TextField label="ra.label.slug" source="slug" />
      </Datagrid>
    </List>
  );
};

export default TagsList;
