import React, { useMemo } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Card } from '@mui/material';
import { ESTIMATED_PROCESSING_TIME } from '../../config';
import formatDateTime from '../../../../../utils/formatDateTime';
import StatusLabel from '../../../../../components/StatusLabel';
import { ORDER_STATUS_NAMES as OSN } from '../../../../../config/statuses.config';
import useCommonStyles from '../../styles';
import useStyles from './styles';

const getStatusesTimeDiff = (log, startStatusType, endStatusType) => {
  const startStatus = log.find(({ type }) => type === startStatusType);
  const endStatus = log.find(({ type }) => type === endStatusType);

  if (!startStatus || !endStatus) {
    return;
  }

  const startTime = new Date(startStatus.timestamp);
  const endTime = new Date(endStatus.timestamp);

  return Math.ceil((endTime - startTime) / 1000 / 60);
};

const getActualTimes = (log) => {
  const processing = getStatusesTimeDiff(log, OSN.NEW, OSN.PREPARING);
  const preparing = getStatusesTimeDiff(log, OSN.PREPARING, OSN.PREPARED);
  const preparingWithoutPreparedStatus = getStatusesTimeDiff(log, OSN.PREPARING, OSN.DELIVERING);
  const preparingWithoutStatuses = getStatusesTimeDiff(log, OSN.CONFIRMED, OSN.DELIVERING);
  const delivery = getStatusesTimeDiff(log, OSN.DELIVERING, OSN.DELIVERED);
  const total = getStatusesTimeDiff(log, OSN.NEW, OSN.DELIVERED);

  let finalPreparingTime = preparing;

  if (!preparing) {
    finalPreparingTime = `| ${preparingWithoutPreparedStatus}`;
  }

  if (!preparingWithoutPreparedStatus) {
    finalPreparingTime = preparingWithoutStatuses;
  }

  if (preparingWithoutStatuses && !preparing && !preparingWithoutPreparedStatus) {
    finalPreparingTime = `| ${preparingWithoutStatuses}`;
  }

  if (!preparing && !preparingWithoutPreparedStatus && !preparingWithoutStatuses) {
    finalPreparingTime = '';
  }

  const convertedProcessing = processing || 0;
  const convertedPreparing = preparing || 0;
  const convertedDelivery = delivery || 0;

  const finalTotalTime = Math.ceil(convertedProcessing + convertedPreparing + convertedDelivery);

  return {
    processing: processing ? `| ${processing}` : '',
    preparing: preparing ? `| ${preparing}` : finalPreparingTime,
    delivery: delivery ? `| ${delivery}` : '',
    total: total ? `| ${finalTotalTime}` : '',
  };
};

const TimeInfo = () => {
  const commonCls = useCommonStyles();
  const styles = useStyles();
  const translate = useTranslate();
  const record = useRecordContext();
  const { preparationDetails, deliveryDetails } = record;

  const actualTimes = useMemo(() => getActualTimes(record?.log), [record?.log]);

  const preparationEstimation = Number(preparationDetails?.estimatedDuration);
  const deliveryEstimation = Number(deliveryDetails?.estimatedDuration);
  const totalEstimatedTime = preparationEstimation + deliveryEstimation + ESTIMATED_PROCESSING_TIME;

  const parsedDeliveryEstimationTime = Number.parseInt(Math.ceil(deliveryEstimation), 10);
  const parsedTotalEstimatedTime = Number.parseInt(Math.ceil(totalEstimatedTime), 10);

  const actualTotal =
    Number(actualTimes.delivery.split('| ')[1] || 0) +
    Number(actualTimes.preparing.split('| ')[1] || 0) +
    Number(actualTimes.processing.split('| ')[1] || 0);

  const finalTotal = actualTotal ? `| ${actualTotal}` : '';

  return (
    <Card className={commonCls.cardStyle}>
      <div className={commonCls.cardTitle}>{translate('ra.title.block.time_data')}</div>
      <div className={styles.timeBlock}>
        <div className={styles.blockTitle}>
          {`${translate('ra.label.estimated')} | ${translate('ra.label.actual')}`}
        </div>
        <div>
          <b>{`${translate('ra.title.processing_time')}: `}</b>
          <span>
            {`${ESTIMATED_PROCESSING_TIME} ${actualTimes.processing} ${translate(
              'ra.navigation.min',
            )}`}
          </span>
        </div>
        <div>
          <b>{`${translate('ra.title.preparing_time')}: `}</b>
          <span>
            {`${preparationEstimation} ${actualTimes.preparing} ${translate('ra.navigation.min')}`}
          </span>
        </div>
        <div>
          <b>{`${translate('ra.title.delivery_time')}: `}</b>
          <span>
            {`${parsedDeliveryEstimationTime} ${actualTimes.delivery} ${translate(
              'ra.navigation.min',
            )}`}
          </span>
        </div>
        <div>
          <b>{`${translate('ra.title.total_time')}: `}</b>
          <span>
            {`${parsedTotalEstimatedTime} ${finalTotal} ${translate('ra.navigation.min')}`}
          </span>
        </div>
      </div>
      <div className={styles.statusHistory}>
        <div className={styles.sectionTitle}>{translate('ra.title.status_history')}</div>
        <div>
          {record?.log.map(({ type, timestamp }) => {
            const { time } = formatDateTime(timestamp);

            return (
              <div key={timestamp} className={styles.statusHistoryItem}>
                <StatusLabel status={type} className={styles.statusName} />
                <span>: </span>
                <span>{time}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default TimeInfo;
