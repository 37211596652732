import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    minWidth: '25px',
    '& > *': {
      minWidth: '25px',
    },
  },
});

export default useStyles;
