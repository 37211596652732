/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Button,
} from '@mui/material';

import availabilitySchedule from '../../../../utils/scheduleDefaultValues';
import { DEFAULT_FILTER_VALUES } from '../../../config';

import Day from '../../../../components/Schedule/Day';

import styles from '../../style';
const WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const TimeFilter = ({ type, handleFilterCheck, checkedFilters, disabled }) => {
  const translate = useTranslate();
  const record = useRecordContext();

  const timeFilterSchedules = useMemo(
    () =>
      record?.filters?.find(
        (elem) => elem.type === 'TIME_FILTER' && Array.isArray(elem.schedule),
      ),
    [record],
  );
  const [options, setOptions] = useState(DEFAULT_FILTER_VALUES.TIME_FILTER);
  const [checked, setChecked] = useState(false);
  const [localScheduleValue, setLocalScheduleValue] = useState(timeFilterSchedules?.schedule || []);

  const onScheduleChange = useCallback(
    (schedule, { day, field, newVal }) => {
      schedule[day][field] = newVal;

      setOptions((prev) => ({ ...prev, schedule: localScheduleValue }));

      setLocalScheduleValue([...localScheduleValue]);
    },
    [localScheduleValue],
  );

  const scheduleActivityHandler = useCallback(
    (schedule) => {
      schedule.isActive = !schedule.isActive;

      setOptions(() => ({ schedule }));
      setLocalScheduleValue([...localScheduleValue]);
    },
    [localScheduleValue],
  );

  const addScheduleHandler = useCallback(() => {
    const scheduleTemplateCopy = JSON.parse(JSON.stringify(availabilitySchedule));
    setLocalScheduleValue((prev) => [...prev, scheduleTemplateCopy]);
  });

  const deleteScheduleHandler = useCallback(
    (scheduleIdx) => {
      const updatedLocalSchedule = localScheduleValue.filter((_, idx) => idx !== scheduleIdx);
      setLocalScheduleValue(() => [...updatedLocalSchedule]);
      setOptions((prev) => ({ ...prev, schedule: updatedLocalSchedule }));
    },
    [localScheduleValue],
  );

  const currentFilter =
    checkedFilters && checkedFilters.find((filterObject) => filterObject.type === type);

  useEffect(() => {
    setOptions(currentFilter || DEFAULT_FILTER_VALUES.TIME_FILTER);
    setChecked(!!currentFilter);
  }, []);

  useEffect(() => {
    const e = {
      target: {
        value: type,
        checked,
      },
    };
    const filterOptions = {
      type,
      ...options,
    };

    handleFilterCheck(e, filterOptions);
  }, [options, checked]);

  return (
    <div style={styles.mainWrapper}>
      <FormControlLabel
        style={styles.formControlLabel}
        control={
          <Checkbox
            checked={checked}
            onChange={() => setChecked(!checked)}
            color="primary"
            disabled={disabled}
          />
        }
        label={type}
      />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div>{type}</div>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex' }}>
          {localScheduleValue.length > 0 &&
            localScheduleValue?.map((scheduleItem, scheduleIndex) => {
              return (
                <Card style={styles.cardItemCommon} key={scheduleItem}>
                  <div style={styles.scheduleTop}>
                    <div>
                      <Checkbox
                        checked={scheduleItem.isActive}
                        onChange={scheduleActivityHandler.bind(this, scheduleItem)}
                      />
                      <span>{translate('ra.action.use_schedule')}</span>
                    </div>
                    <div>
                      <Button onClick={deleteScheduleHandler.bind(this, scheduleIndex)}>
                        <DeleteIcon color="error" />
                      </Button>
                    </div>
                  </div>

                  {WEEK?.map((day) => (
                    <Day
                      key={`${day}`}
                      day={day}
                      data={scheduleItem[day]}
                      onChange={onScheduleChange.bind(this, scheduleItem)}
                      disabled={!scheduleItem.isActive}
                    >
                      {translate(`ra.week_day.${day}`)}
                    </Day>
                  ))}
                </Card>
              );
            })}
          {localScheduleValue.length < 3 && (
            <Card style={{ ...styles.addCardButton, ...styles.cardItemCommon }}>
              <Button style={styles.buttonWrapper} onClick={addScheduleHandler}>
                <AddCircleOutlineIcon fontSize="large" style={styles.buttonIcon} />
              </Button>
            </Card>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TimeFilter;
