/* eslint-disable no-console */
import history from '../../history';
import { baseUrl } from '../../config/connection';
import { postWithAuthorization, patchWithAuthorization } from '../../utils/fetchWithAuthorization';

export const saveNavMenu = (navElement) => {
  postWithAuthorization(`${baseUrl}/admin/navigation`, {
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(navElement),
  })
    .then((res) => {
      history.push('/navigation');
    })
    .catch((e) => {
      console.error(e);
    });
};

export const editNavMenu = (navElement, values) => {
  patchWithAuthorization(`${baseUrl}/admin/navigation/${values.id}`, {
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(navElement),
  })
    .then((res) => {
      history.push('/navigation');
    })
    .catch((e) => {
      console.error(e);
    });
};

export default { saveNavMenu, editNavMenu };
