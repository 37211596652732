import styled from 'styled-components';

export const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageRow = styled.div`
  display: flex;
  align-items: center;
  
  &:not(:first-child) {
    margin-top: 8px;
  }

  & > span {
    margin-right: 8px;
    font-weight: 700;
  }
`;
