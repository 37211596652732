import React, { useEffect } from 'react';
import {
  Edit,
  required,
  TextInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  DateInput,
  TabbedForm,
  useTranslate,
  FormTab,
  useUpdate,
  useRedirect,
  useEditController,
} from 'react-admin';
import randomString from 'crypto-random-string';
import { useForm, useWatch } from 'react-hook-form';
import { TranslatableTextInput, TranslatableLongTextInput } from '../../../components/Translatable';
import { PaymentMethodInput } from '../../../components/PaymentMethod';
import { statuses, supplierPayout } from '../../../config/supplier.config';
import ResourceTitle from '../../../components/ResourceTitle';
import S3FileInput from '../../../components/S3FileUploader/Input';
import { baseUrl } from '../../../config/connection';
import CancelButton from '../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';

const validateRequired = required();

const inputStyle = {
  maxWidth: '270px',
  width: '100%',
};
const FileLabel = ({ children }) => (
  <span style={{ margin: '0 0 15px 0', display: 'block' }}>{children}</span>
);

const SupplierEdit = () => {
  const translate = useTranslate();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const controller = useEditController();
  const record = controller?.record;

  const { control, reset } = useForm();

  const translationValues = useWatch({
    control,
    defaultValue: {
      traslations: record?.translations,
    },
  });

  useEffect(() => {
    if (!controller?.isFetching) {
      reset(controller?.record);
    }
  }, [controller?.isFetching, controller?.record, reset]);

  const onSubmit = (data) => {
    // Get new translations from translationValues
    const newTranslations = translationValues.translations;

    update(
      'supplier',
      {
        id: record.id,
        data: {
          ...data,
          translations: newTranslations,
        },
        previousData: record,
      },
      {
        onSuccess: () => {
          redirect('list', 'supplier');
        },
      },
    );
  };

  return (
    <Edit title={<ResourceTitle field="name" />} actions={<CancelButton />}>
      <TabbedForm onSubmit={onSubmit} toolbar={<EditDeleteConfirmationButton />}>
        <FormTab label="ra.tab.general">
          <TextInput
            source="slug"
            label="ra.label.slug"
            sx={inputStyle}
            validate={validateRequired}
          />
          <TranslatableTextInput
            sx={inputStyle}
            source="name"
            label="ra.label.name"
            validate={validateRequired}
            control={control}
          />
          <ReferenceInput source="deliverySettingsId" reference="deliverySettings" perPage={150}>
            <SelectInput
              optionText="name"
              optionValue="id"
              validate={validateRequired}
              label="ra.label.delivery_settings"
              sx={inputStyle}
            />
          </ReferenceInput>
          <ReferenceInput
            source="courierPayoutPolicyId"
            reference="policy"
            allowEmpty={false}
            resettable
            alwaysOn
          >
            <SelectInput
              optionText="name"
              label="ra.label.payout_policy"
              validate={validateRequired}
              sx={inputStyle}
            />
          </ReferenceInput>
          <SelectInput
            label="ra.label.status"
            source="status"
            choices={statuses}
            validate={validateRequired}
            sx={inputStyle}
          />
          <NumberInput
            source="rank"
            label="ra.label.rank"
            validate={validateRequired}
            sx={inputStyle}
          />

          <DateInput
            source="launchDate"
            label="ra.label.launch_date"
            validate={validateRequired}
            sx={inputStyle}
          />
          <BooleanInput label="ra.label.allow_under_age" source="underAgeAllowed" />
        </FormTab>

        <FormTab label="ra.tab.branding">
          <TranslatableLongTextInput
            source="description"
            label="ra.label.description"
            validate={validateRequired}
            control={control}
          />
          <TranslatableTextInput
            source="highlight"
            label="ra.label.highlight"
            validate={validateRequired}
            control={control}
          />
          <ReferenceArrayInput source="tagIds" reference="tag">
            <SelectArrayInput
              optionText="name"
              validate={validateRequired}
              label="resources.tag.name"
              sx={inputStyle}
            />
          </ReferenceArrayInput>
          <FileLabel validate={validateRequired}>{translate('ra.label.cover_image')}</FileLabel>
          <S3FileInput
            apiRoot={baseUrl}
            source="cover"
            validate={validateRequired}
            uploadOptions={{
              id: 'cover',
              signingUrl: `${baseUrl}/s3/sign`,
              s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
              multiple: false,
              accept: 'image/*',
            }}
          />
          <FileLabel>{translate('ra.label.card_image')}</FileLabel>
          <S3FileInput
            apiRoot={baseUrl}
            source="cardImg"
            uploadOptions={{
              id: 'cardImg',
              signingUrl: `${baseUrl}/s3/sign`,
              s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
              multiple: false,
              accept: 'image/*',
            }}
          />
          <FileLabel>{translate('ra.label.hero_image')}</FileLabel>
          <S3FileInput
            apiRoot={baseUrl}
            source="heroImg"
            uploadOptions={{
              id: 'heroImg',
              signingUrl: `${baseUrl}/s3/sign`,
              s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
              multiple: false,
              accept: 'image/*',
            }}
          />
        </FormTab>

        <FormTab label="ra.tab.financial">
          <TextInput source="cvrNumber" label="ra.label.cvr" sx={inputStyle} />
          <TextInput source="bankAccount" label="ra.label.bank_account_number" sx={inputStyle} />
          <TextInput source="email" label="ra.label.email" sx={inputStyle} />
          <NumberInput
            source="platformCommission"
            label="ra.label.commission"
            sx={inputStyle}
            validate={validateRequired}
          />
          <SelectInput
            source="payout"
            validate={validateRequired}
            label="ra.label.payout"
            choices={supplierPayout}
            sx={inputStyle}
          />
          <PaymentMethodInput
            source="paymentTypes"
            label="ra.label.payment_methods"
            validate={validateRequired}
            sx={inputStyle}
          />
        </FormTab>

        <FormTab label="ra.tab.integrations">
          <TextInput
            source="externalSupplierId"
            label="ra.label.externalSupplierId"
            sx={inputStyle}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default SupplierEdit;
