export const ORDER_STATUS_NAMES = {
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  CONFIRMED: 'CONFIRMED',
  PREPARING: 'PREPARING',
  PREPARED: 'PREPARED',
  DELIVERING: 'DELIVERING',
  DELIVERED: 'DELIVERED',
  SCHEDULED: 'SCHEDULED',
  REFUSED: 'REFUSED',
  COURIER_ASSIGN: 'COURIER_ASSIGN',
  COURIER_ASSIGNED: 'COURIER_ASSIGNED',
  COURIER_REASSIGN: 'COURIER_REASSIGN',
  COURIER_CONFIRMED: 'COURIER_CONFIRMED',
  COURIER_IS_IN_RESTAURANT: 'COURIER_IS_IN_RESTAURANT',
  IN_PROGRESS: 'IN_PROGRESS',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
};

export const ORDER_STATUS_IDS = {
  NEW: 7,
  PROCESSING: 1,
  CANCELED: 2,
  CONFIRMED: 3,
  PREPARING: 4,
  DELIVERING: 5,
  DELIVERED: 6,
  PREPARED: 8,
  COURIER_CONFIRMED: 9,
  COURIER_IS_IN_RESTAURANT: 10,
  ACCEPTED: 11,
};

export const ROLE_STATUSES = {
  STANDART: {
    ADMIN: [ORDER_STATUS_NAMES.NEW, ORDER_STATUS_NAMES.ACCEPTED],
    SUPPLIER_ADMIN: [
      ORDER_STATUS_NAMES.NEW,
      ORDER_STATUS_NAMES.PROCESSING,
      ORDER_STATUS_NAMES.CONFIRMED,
      ORDER_STATUS_NAMES.PREPARING,
    ],
    SUPPLIER_BRANCH: [
      ORDER_STATUS_NAMES.NEW,
      ORDER_STATUS_NAMES.PROCESSING,
      ORDER_STATUS_NAMES.CONFIRMED,
      ORDER_STATUS_NAMES.PREPARING,
    ],
  },
  SUPPLIER: {
    ADMIN: [ORDER_STATUS_NAMES.NEW, ORDER_STATUS_NAMES.ACCEPTED],
    SUPPLIER_ADMIN: [
      ORDER_STATUS_NAMES.PROCESSING,
      ORDER_STATUS_NAMES.CONFIRMED,
      ORDER_STATUS_NAMES.PREPARING,
      ORDER_STATUS_NAMES.PREPARED,
      ORDER_STATUS_NAMES.DELIVERING,
    ],
    SUPPLIER_BRANCH: [
      ORDER_STATUS_NAMES.PROCESSING,
      ORDER_STATUS_NAMES.CONFIRMED,
      ORDER_STATUS_NAMES.PREPARING,
      ORDER_STATUS_NAMES.PREPARED,
      ORDER_STATUS_NAMES.DELIVERING,
    ],
  },
};

export const MODAL_STATUSES = [ORDER_STATUS_NAMES.CANCELED, ORDER_STATUS_NAMES.ACCEPTED];

export const ORDER_STATUSES = [
  { id: 'NEW', name: 'New' },
  { id: 'PROCESSING', name: 'Processing' },
  { id: 'CANCELED', name: 'Canceled' },
  { id: 'CONFIRMED', name: 'Confirmed' },
  { id: 'PREPARING', name: 'Preparing' },
  { id: 'DELIVERING', name: 'Delivering' },
  { id: 'DELIVERED', name: 'Delivered' },
  { id: 'PREPARED', name: 'Prepared' },
  { id: 'ACCEPTED', name: 'Accepted' },
  { id: 'SCHEDULED', name: 'Scheduled' },
];

export const ORDER_STATUSES_TRANSLATIONS = {
  [ORDER_STATUS_NAMES.NEW]: 'new',
  [ORDER_STATUS_NAMES.PROCESSING]: 'processing',
  [ORDER_STATUS_NAMES.AWAITING_PAYMENT]: 'awaiting_payment',
  [ORDER_STATUS_NAMES.ACCEPTED]: 'accepted',
  [ORDER_STATUS_NAMES.CANCELED]: 'canceled',
  [ORDER_STATUS_NAMES.CONFIRMED]: 'confirmed',
  [ORDER_STATUS_NAMES.PREPARING]: 'preparing',
  [ORDER_STATUS_NAMES.PREPARED]: 'prepared',
  [ORDER_STATUS_NAMES.DELIVERING]: 'delivering',
  [ORDER_STATUS_NAMES.DELIVERED]: 'delivered',
  [ORDER_STATUS_NAMES.COURIER_ASSIGN]: 'courier_assign',
  [ORDER_STATUS_NAMES.COURIER_ASSIGNED]: 'courier_assigned',
  [ORDER_STATUS_NAMES.COURIER_REASSIGN]: 'courier_reassign',
  [ORDER_STATUS_NAMES.COURIER_CONFIRMED]: 'courier_confirmed',
  [ORDER_STATUS_NAMES.COURIER_IS_IN_RESTAURANT]: 'courier_in_restaurant',
  [ORDER_STATUS_NAMES.IN_PROGRESS]: 'in_progress',
  [ORDER_STATUS_NAMES.PAYMENT_FAILED]: 'payment_failed',
  [ORDER_STATUS_NAMES.SCHEDULED]: 'scheduled',
  [ORDER_STATUS_NAMES.REFUSED]: 'refused',
};

export const ORDER_STATUSES_COLOR = {
  [ORDER_STATUS_NAMES.NEW]: '#0055FF',
  [ORDER_STATUS_NAMES.PROCESSING]: '#0055FF',
  [ORDER_STATUS_NAMES.AWAITING_PAYMENT]: '#FF4E33',
  [ORDER_STATUS_NAMES.ACCEPTED]: '#7DB807',
  [ORDER_STATUS_NAMES.CANCELED]: '#FA0064',
  [ORDER_STATUS_NAMES.CONFIRMED]: '#7DB807',
  [ORDER_STATUS_NAMES.PREPARING]: '#FFAA00',
  [ORDER_STATUS_NAMES.PREPARED]: '#00A66F',
  [ORDER_STATUS_NAMES.DELIVERING]: '#00A66F',
  [ORDER_STATUS_NAMES.DELIVERED]: '#A3A3A3',
  [ORDER_STATUS_NAMES.COURIER_ASSIGN]: '#FFAA00',
  [ORDER_STATUS_NAMES.COURIER_ASSIGNED]: '#FFAA00',
  [ORDER_STATUS_NAMES.COURIER_REASSIGN]: '#FFAA00',
  [ORDER_STATUS_NAMES.COURIER_CONFIRMED]: '#FFAA00',
  [ORDER_STATUS_NAMES.COURIER_IS_IN_RESTAURANT]: '#FFAA00',
  [ORDER_STATUS_NAMES.IN_PROGRESS]: '#0055FF',
  [ORDER_STATUS_NAMES.PAYMENT_FAILED]: '#FF4E33',
  [ORDER_STATUS_NAMES.SCHEDULED]: '#05CCB0',
  [ORDER_STATUS_NAMES.REFUSED]: '#ef6414',
};
