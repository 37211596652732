import React from 'react';
import {
  ChipField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import PriceField from '../../../components/PriceField';
import { TranslatableTextField } from '../../../components/Translatable';
import OptionGroupTable from '../OptionGroupTable';
import ResourceTitle from '../../../components/ResourceTitle';
import { baseUrl } from '../../../config/connection';
import S3FileField from '../../../components/S3FileUploader/Field';
import { useUiSettings } from '../../../hooks/useUiSettings';

const GoodsShow = () => {
  const { record } = useShowController();
  const uiSettings = useUiSettings();

  return (
    <Show title={<ResourceTitle field="name" />}>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TranslatableTextField label="ra.label.name" source="name" />
          <TranslatableTextField label="ra.label.description" source="description" />
          <TextField label="ra.label.slug" source="slug" />
          {record?.ingredientIds && record?.ingredientIds.length && (
            <ReferenceArrayField
              label="ra.input.ingredients"
              source="ingredientIds"
              reference="ingredient"
            >
              <SingleFieldList linkType="show">
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          )}
          <ReferenceField
            label="ra.label.category"
            source="categoryId"
            reference="category"
            linkType="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="ra.label.supplier"
            source="supplierId"
            reference="supplier"
            linkType="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField label="ra.label.external" source="externalId" />
          {
            uiSettings?.currency && (
              <PriceField
                label="ra.label.price"
                source="price"
                parse={(v) => v / 100}
                options={{
                  style: 'currency',
                  currency: uiSettings.currency,
                }}
              />
            )
          }
          <TextField label="ra.label.serving_size" source="servingSize" />
          <TextField label="ra.label.unit" source="servingSizeUnit" />
          <NumberField source="weight" />
          <S3FileField apiRoot={baseUrl} source="cover" />
        </Tab>
        <Tab label="ra.tab.option_groups">
          <OptionGroupTable />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default GoodsShow;
