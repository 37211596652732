import React, { useCallback, useEffect, useState } from 'react';
import {
  useEditController,
  Title,
  SimpleForm,
  SelectInput,
  useTranslate,
  useUpdate,
  useResourceContext,
  useNotify,
  useRedirect,
  useRefresh,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  TextInput,
  Edit,
  DateTimeInput,
} from 'react-admin';
import { Card, TextField } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import GoogleMap from 'google-map-react';
import useStyles from './styles';
import ClientPlacemark from '../../../components/maps/ClientPlacemark';
import { GOOGLE_MAPS_API_KEY } from '../../../config/maps.config';
import { ORDER_STATUSES, ORDER_STATUS_NAMES } from '../../../config/statuses.config';
import { ALL_USER_ROLES_NAMES } from '../../../config/user.config';
import url from '../../../config/connection';
import {
  fetchWithAuthorization,
  patchWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import CancelButton from '../../../components/CancelButton';

const OrderEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable />
  </Toolbar>
);

const listResourceMap = {
  'order/today': 'order/today',
  'order/history': 'order/history',
  'order/future': 'order/v1/scheduled',
};

const OrderEdit = () => {
  const { control, reset, setValue } = useForm();
  const { id } = useParams();

  const resource = useResourceContext();
  const editContext = useEditController();
  const styles = useStyles();

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const [order, setOrder] = useState(null);
  const [orderComment, setOrderComment] = useState('');
  const [cancelCommentErr, setCancelCommentErr] = useState(true);
  const [branches, setBranches] = useState([]);

  const [update] = useUpdate();

  const values = useWatch({
    defaultValue: editContext?.record,
    control,
  });
  const isOrderHistory = listResourceMap[resource] === 'order/history';

  useEffect(() => {
    if (!editContext?.isFetching) {
      reset(editContext?.record);
    }
  }, [editContext?.isFetching, editContext?.record, reset]);

  const isSuperAdmin = localStorage.getItem('role') === ALL_USER_ROLES_NAMES.ADMIN;
  const isCancelling = values.supplierStatus === ORDER_STATUS_NAMES.CANCELED;

  useEffect(() => {
    setCancelCommentErr(isCancelling && !orderComment);
  }, [isCancelling, orderComment]);

  const placeCommentToOrder = useCallback(async () => {
    await patchWithAuthorization(`${url}/order/${id}/comment`, {
      body: JSON.stringify({
        comment: orderComment,
      }),
    });
  }, [id, orderComment]);

  const fetchSupplierBranches = (supplierId) => {
    fetchWithAuthorization(`${url}/address?filter=supplierId||in||${supplierId}`)
      .then((res) => res.json())
      .then((data) => setBranches(data))
      .catch(() => {
        throw new Error('failed to fetch');
      });
  };

  const updateOrder = useCallback(() => {
    fetchWithAuthorization(`${url}/order/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setOrder(data);
        fetchSupplierBranches(data?.supplier?.supplierId);
      })
      .catch(() => {
        throw new Error('failed to fetch');
      });
  }, [id]);

  useEffect(() => {
    updateOrder();
  }, [updateOrder]);

  const handleSave = async () => {
    let _scheduled = null;
    const now = new Date();
    if (values?.scheduled?.scheduledAt) {
      const prevDate = new Date(editContext?.record?.scheduled?.scheduledAt);
      const inputDate = new Date(values?.scheduled?.scheduledAt);
      _scheduled = inputDate.toISOString();
      if (inputDate < now && prevDate !== _scheduled && resource !== 'order/history') {
        notify(
          'Scheduled date is earlier than the current date and time',
          { type: 'error', autoHideDuration: 4000 },
        );

        return;
      }
    }
    await update(
      resource,
      {
        id,
        // update with actual data and don't override the old data in objects
        data: {
          ...editContext?.record,
          deliveryDetails: { ...editContext?.record?.deliveryDetails, ...values?.deliveryDetails },
          preparationDetails: {
            ...editContext?.record?.preparationDetails,
            ...values?.preparationDetails,
          },
          supplier: { ...editContext?.record?.supplier, ...values?.supplier },
          targetAddress: { ...editContext?.record?.targetAddress, ...values?.targetAddress },
          supplierStatus: values?.supplierStatus,
          orderItems: order?.orderItems,
          ...(_scheduled && { scheduled: {
            startProcessingAt: _scheduled,
            scheduledAt: _scheduled,
          } }),
        },
        previousData: editContext?.record,
      },
      {
        onSuccess: () => {
          if (isCancelling) placeCommentToOrder();
          redirect('list', resource);
          refresh();
        },
      },
    ).catch(() => {
      notify(translate('ra.notification.http_error'), { type: 'warning' });
    });
  };

  const setGeoForm = (newGeo) => {
    setValue('targetAddress.geo', newGeo);
  };

  if (editContext.isLoading) return null;

  return (
    <Edit actions={<CancelButton />}>
      <div>
        <Title title="Book Edition" />
        <Card>
          <SimpleForm onSubmit={handleSave} toolbar={<OrderEditToolbar />}>
            {isSuperAdmin && !isOrderHistory && (
            <DateTimeInput
              label={translate('ra.title.scheduled_date')}
              source="scheduled.scheduledAt"
              control={control}
            />
            )}
            <SelectInput
              sx={{ maxWidth: '218px', width: '100%' }}
              label="ra.label.status"
              source="supplierStatus"
              choices={ORDER_STATUSES}
              control={control}
            />
            {isCancelling && (
              <TextField
                required={isCancelling}
                label={translate('ra.label.comment')}
                multiline
                rows="4"
                margin="normal"
                value={orderComment}
                onChange={(e) => setOrderComment(e.target.value)}
                error={cancelCommentErr}
                helperText={translate('ra.validation.required')}
              />
            )}
            {editContext.record && (
              <SelectInput
                optionText="name"
                optionValue="id"
                choices={branches}
                label={translate('ra.label.supplierBranch')}
                source="supplier.branchId"
                sx={{ maxWidth: '218px', width: '100%' }}
                control={control}
              />
            )}
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <>
                    <div className={styles.addressFormRow}>
                      {['country', 'area', 'locality', 'street', 'house'].map((part) => (
                        <TextInput
                          control={control}
                          key={part}
                          label={`ra.label.${part}`}
                          source={`targetAddress.${part}`}
                          className={styles.addressInput}
                        />
                      ))}
                    </div>
                    <div className={styles.addressFormRow}>
                      {[
                        'porch',
                        { label: 'porch_code', source: 'porchCode' },
                        'floor',
                        'apartment',
                        { label: 'instruction', source: 'instructions' },
                      ].map((part) => {
                        const label = part.label || part;
                        const source = part.source || part;

                        return (
                          <TextInput
                            control={control}
                            key={source}
                            label={`ra.title.${label}`}
                            source={`targetAddress.details.${source}`}
                            className={styles.addressInput}
                          />
                        );
                      })}
                    </div>
                    <div className={styles.map}>
                      <GoogleMap
                        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                        defaultCenter={editContext?.record?.targetAddress?.geo}
                        defaultZoom={17}
                        onClick={({ lat, lng }) => {
                          setGeoForm({
                            lat,
                            lng,
                          });
                        }}
                        control={control}
                      >
                        <ClientPlacemark {...formData.targetAddress?.geo} />
                      </GoogleMap>
                    </div>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </Card>
      </div>
    </Edit>
  );
};

export default OrderEdit;
