import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import url from '../../../config/connection';

const OptionGroupTitle = () => {
  const [supplierName, setSupplierName] = useState('');

  const record = useRecordContext();
  const supplierId = record?.supplierId;
  const name = record?.name;

  useEffect(() => {
    fetch(`${url}/supplier/${supplierId}`)
      .then((res) => res.json())
      .then((res) => setSupplierName(res.name));
  }, [supplierId]);

  return <span>{supplierName ? `${supplierName} - ${name}` : name}</span>;
};

export default OptionGroupTitle;
