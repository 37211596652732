import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { required, Create, SimpleForm, AutocompleteInput, NumberInput } from 'react-admin';
import { baseUrl } from '../../../config/connection';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import SupplierAutocomplete from '../../../components/SupplierAutocomplete';

const validateRequired = required();

const StoreSupplierCreate = (props) => {
  const [storeChoices, setStoreChoices] = useState([]);
  const location = useLocation();
  const queryParam = Number(location.search.split('=')[1]);

  useEffect(() => {
    fetchWithAuthorization(`${baseUrl}/admin/store`)
      .then((res) => res.json())
      .then((res) => {
        if (queryParam) {
          const filteredChoice = res.filter(({ id }) => id === queryParam);

          return setStoreChoices(filteredChoice);
        }
        setStoreChoices(res);
      });
  }, [queryParam]);

  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <AutocompleteInput
          label="ra.label.store"
          optionText="name"
          source="storeId"
          validate={validateRequired}
          choices={storeChoices}
          sx={{ maxWidth: '300px', width: '100%' }}
        />
        <SupplierAutocomplete sx={{ maxWidth: '300px', width: '100%' }} validate />
        <NumberInput
          label="ra.label.rank"
          source="supplierRank"
          sx={{ maxWidth: '300px', width: '100%' }}
          validate={validateRequired}
        />
      </SimpleForm>
    </Create>
  );
};

export default StoreSupplierCreate;
