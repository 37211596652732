import React, { useState } from 'react';
import {
  Confirm,
  useListContext,
  useUnselectAll,
  useDeleteMany,
  useResourceContext,
  useTranslate,
  Button,
  useNotify,
  useUpdateMany,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';

const ListDeleteConfirmationButton = ({ updateDeletedAt }) => {
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const resource = useResourceContext();
  const unselectAll = useUnselectAll(resource);
  const translate = useTranslate();
  const [deleteMany] = useDeleteMany();
  const [updateMany] = useUpdateMany();
  const notify = useNotify();

  const handleDelete = () => setOpen(true);
  const handleDialogClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    notify(translate('ra.notification.deleted', { smart_count: selectedIds.length }), {
      undoable: true,
    });
    unselectAll();
  };

  const handleConfirm = () => {
    if (updateDeletedAt) {
      const deletedAt = new Date().toISOString();
      updateMany(
        resource,
        { ids: selectedIds, data: { deletedAt } },
        { onSuccess, mutationMode: 'undoable' },
      );
    } else {
      deleteMany(resource, { ids: selectedIds }, { onSuccess, mutationMode: 'undoable' });
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        label={translate('ra.action.delete')}
        sx={{ padding: '10px', lineHeight: '1 !important' }}
        onClick={handleDelete}
        color="error"
      >
        <DeleteIcon />
      </Button>
      <Confirm
        isOpen={open}
        sx={{ padding: '8px 10px', marginLeft: '10px', textAlign: 'center' }}
        title={translate('ra.message.are_you_sure')}
        content={translate('ra.message.are_you_sure')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        color="error"
      />
    </>
  );
};

export default ListDeleteConfirmationButton;
