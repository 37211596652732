/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { withStyles } from '@mui/styles';
import style, { TimeInputStyles } from './style';
import TimeConfirm from './Timeconfirm';
import {
  convertMinutesToPreparationTime,
} from '../../../utils/timeOperations';
import { ORDER_STATUS_NAMES } from '../../../config/statuses.config';
import { ORDER_MODAL_TYPES } from '../../../config/modalTypes.config';
import CancelOrder from './CancelOrder';

const Modal = (props) => {
  const { classes, handleClose, open, nextStatus, changeOrderStatus, currentOrder, modalType } =
    props;

  const { body } = style;
  let defaultTime = convertMinutesToPreparationTime(30);
  if (currentOrder && currentOrder.preparationDetails.estimatedDuration) {
    const { preparationDetails } = currentOrder;
    defaultTime = convertMinutesToPreparationTime(
      preparationDetails ? preparationDetails.estimatedDuration : 30,
    );
  }
  const [time, setTime] = useState(defaultTime);

  const timeConfirmModal = (
    <TimeConfirm
      changeOrderStatus={changeOrderStatus}
      nextStatus={nextStatus}
      classes={classes}
      time={time}
      setTime={setTime}
      handleClose={handleClose}
      order={currentOrder}
    />
  );

  const renderContent = () => {
    if (nextStatus.key === ORDER_STATUS_NAMES.CANCELED) {
      return (
        <CancelOrder
          changeOrderStatus={changeOrderStatus}
          handleClose={handleClose}
          currentOrder={currentOrder}
        />
      );
    }

    let renderModal = false;

    if (modalType === ORDER_MODAL_TYPES.TIME_CONFIRM) {
      renderModal = timeConfirmModal;
    }

    return <div style={body}>{renderModal}</div>;
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dialog
        onClose={() => handleClose()}
        maxWidth="sm"
        open={open}
        onClick={(e) => e.stopPropagation()}
      >
        {renderContent()}
      </Dialog>
    </div>
  );
};

export default withStyles(TimeInputStyles)(Modal);
