import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const DiscountActionToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export default DiscountActionToolbar;
