import React from 'react';
import {
  required,
  Edit,
  ReferenceInput,
  DateTimeInput,
  SimpleForm,
  SelectInput,
} from 'react-admin';
import CancelButton from '../../../../components/CancelButton';
import EditDeleteConfirmationButton from '../../../../components/EditDeleteConfirmationButton';

const validateRequired = required();

const RunCampaignEdit = (props) => {
  return (
    <Edit {...props} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <ReferenceInput reference="marketing-campaigns" source="campaignId">
          <SelectInput
            optionText="name"
            label="ra.label.marketing_campaigns"
            validate={validateRequired}
            sx={{ maxWidth: '218px', width: '100%' }}
          />
        </ReferenceInput>
        <DateTimeInput
          source="launchDate"
          label="ra.label.launchDate"
          validate={validateRequired}
          sx={{ maxWidth: '218px', width: '100%' }}
        />
        <DateTimeInput
          source="effectiveDate"
          label="ra.label.effectiveDate"
          validate={validateRequired}
          sx={{ maxWidth: '218px', width: '100%' }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default RunCampaignEdit;
