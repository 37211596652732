import React from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';
import useStyles from './styles';

export default function DiscountArrayInput() {
  const styles = useStyles();

  return (
    <div>
      <ReferenceArrayInput
        source="discountId"
        reference="discount"
        label="ra.label.discount"
        className={styles.inputFilter}
      >
        <AutocompleteArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>
    </div>
  );
}
