import React, { useState, useCallback, useEffect } from 'react';
import 'react-day-picker/lib/style.css';
import { Select, FormControl, InputLabel, MenuItem, Button, Box } from '@mui/material';
import { useTranslate } from 'react-admin';
import { TabStyle, exportSection } from '../../style';
import { DEFAULT_PAYMENT_METHODS } from '../../../config/payment.config';
import { DEFAULT_REPORT_FIELDS } from '../../../config/reportFields.config';
import {
  postWithAuthorization,
  fetchWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import url from '../../../config/connection';
import formatDateTime from '../../../utils/formatDateTime';
import { parseDateToDDMMYYY } from '../../../utils/timeOperations';
import { formatCourier } from '../../utils/formatCourier';
import Modal from '../../../components/Modal';
import TimeRangePicker from '../TimeRangePicker';
import CSVExport from '../CSVExport';
import { refundedOrdersReportHeaders } from '../../constants';
import PreviewData from '../PreviewData';
import CheckBoxTable from '../CheckBoxTable';

const RefundedOrdersReport = ({ getDataHeadings, supplierId }) => {
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierBranches, setSupplierBranches] = useState([]);
  const [selectedSupplierBranches, setSelectedSupplierBranches] = useState([]);

  const [selectedCouriers, setSelectedCouriers] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState(DEFAULT_PAYMENT_METHODS);
  const [fields, setFields] = useState(DEFAULT_REPORT_FIELDS.REFUNDED_ORDERS);

  const translate = useTranslate();

  const fetchRefundedOrdersReport = useCallback(() => {
    if (!(selectedSuppliers.length || (dateRange.from && dateRange.to))) {
      return;
    }

    const parsedPaymentMethods = Object.keys(paymentMethods).filter(
      (method) => paymentMethods[method],
    );

    postWithAuthorization(`${url}/report/refundedOrders/export`, {
      body: JSON.stringify({
        selectedSuppliers,
        selectedSupplierBranches,
        selectedCouriers,
        dateRange,
        paymentMethods: parsedPaymentMethods,
        reportFields: fields,
      }),
    })
      .then((data) => data.json())
      .then((report) => {
        report.forEach((row, index) => {
          if (index === report.length - 1) {
            return row;
          }

          const { date, time } = formatDateTime(row.orderOrderedAt);

          row.orderOrderedAt = `${date} ${time}`;
        });

        setReportData(report);
      });
  }, [
    dateRange,
    fields,
    paymentMethods,
    selectedSupplierBranches,
    selectedSuppliers,
    selectedCouriers,
  ]);

  useEffect(() => {
    if (supplierId) {
      setSelectedSuppliers([Number(supplierId)]);
    }
  }, [supplierId]);

  const fetchSuppliers = useCallback(() => {
    if (suppliers.length) {
      return;
    }

    fetchWithAuthorization(`${url}/supplier`)
      .then((res) => res.json())
      .then((data) => setSuppliers(data))
      .catch(() => {
        throw new Error('failed to fetch');
      });
  }, [suppliers]);

  const fetchSupplierBranches = () => {
    fetchWithAuthorization(`${url}/address?filter=supplierId||in||${selectedSuppliers}`)
      .then((res) => res.json())
      .then((data) => {
        setSupplierBranches(data);
      })
      .catch(() => {
        throw new Error('failed to fetch');
      });
  };

  const handleSupplierSelect = useCallback((event) => {
    const {
      target: { value },
    } = event;
    setSelectedSuppliers(value);
    setSelectedSupplierBranches([]);
  }, []);

  const handleSupplierBranchSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSupplierBranches(value);
  };

  const fetchCouriers = useCallback(() => {
    if (couriers.length) {
      return;
    }

    fetchWithAuthorization(`${url}/courier`)
      .then((res) => res.json())
      .then(({ data }) => setCouriers(data))
      .catch(() => {
        throw new Error('failed to fetch');
      });
  }, [couriers]);

  const handleCourierSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCouriers(value);
  };

  const composeCalendarBtnTitle = () => {
    const { from, to } = dateRange;
    const isSelectedRange = from && to;

    if (!isSelectedRange) {
      return translate('ra.action.select_time_range');
    }

    return `${parseDateToDDMMYYY(from)} - ${parseDateToDDMMYYY(to)}`;
  };

  const changePaymentMethod = (method, value) => {
    setPaymentMethods((prevPaymentMethods) => ({
      ...prevPaymentMethods,
      [method]: value,
    }));
  };

  const changeField = (field, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  return (
    <div style={TabStyle.wrapper}>
      <FormControl style={{ minWidth: '250px', maxWidth: '550px' }}>
        <InputLabel htmlFor="courierId">{translate('ra.title.select_courier')}</InputLabel>
        <Select
          multiple
          value={selectedCouriers}
          onChange={handleCourierSelect}
          onOpen={fetchCouriers}
          inputProps={{
            name: 'Courier',
            id: 'courierId',
          }}
        >
          {couriers.map((courier) => {
            return (
              <MenuItem key={courier.id} value={courier.userId}>
                {formatCourier(courier)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {!supplierId && (
        <FormControl style={{ minWidth: '250px', maxWidth: '550px' }}>
          <InputLabel htmlFor="supplierId">{translate('ra.label.supplier_select')}</InputLabel>
          <Select
            multiple
            value={selectedSuppliers}
            onChange={handleSupplierSelect}
            onOpen={fetchSuppliers}
            inputProps={{
              name: 'Supplier',
              id: 'supplierId',
            }}
          >
            {suppliers.map(({ id, name }) => {
              return <MenuItem key={id} value={id}>{`${name}`}</MenuItem>;
            })}
          </Select>
        </FormControl>
      )}
      {!!selectedSuppliers.length && (
        <FormControl style={{ minWidth: '250px', maxWidth: '550px' }}>
          <InputLabel htmlFor="supplierBranchId">{translate('ra.label.branch')}</InputLabel>
          <Select
            multiple
            value={selectedSupplierBranches}
            onChange={handleSupplierBranchSelect}
            onOpen={fetchSupplierBranches}
            inputProps={{
              name: 'Supplier Branch',
              id: 'supplierBranchId',
            }}
          >
            {supplierBranches.map(({ id, name }) => {
              return <MenuItem key={id} value={id}>{`${name}`}</MenuItem>;
            })}
          </Select>
        </FormControl>
      )}
      <Button
        variant="outlined"
        color="primary"
        style={{ margin: '15px 0', color: '#477890', borderColor: '#477890' }}
        onClick={() => {
          setCalendarModalOpen(true);
        }}
      >
        {composeCalendarBtnTitle()}
      </Button>
      <Modal open={calendarModalOpen} onClose={() => setCalendarModalOpen(false)}>
        <TimeRangePicker range={dateRange} changeRange={setDateRange} />
      </Modal>
      <CheckBoxTable
        data={paymentMethods}
        onChangeAction={changePaymentMethod}
        type="changePaymentMethod"
      />
      <div style={exportSection}>
        <CSVExport
          headers={refundedOrdersReportHeaders.filter(({ key }) => fields[key])}
          data={reportData}
        />
      </div>
      <CheckBoxTable
        data={fields}
        onChangeAction={changeField}
        type="changeField"
      />
      <Box mt={2}>
        <Button onClick={fetchRefundedOrdersReport} variant="outlined" color="primary">
          {translate('ra.action.generate_report')}
        </Button>
      </Box>
      <PreviewData fields={fields} data={reportData} getDataHeadings={getDataHeadings} />
    </div>
  );
};

export default RefundedOrdersReport;
