import OptionCreate from './components/Create';
import OptionEdit from './components/Edit';
import OptionsList from './components/List';
import OptionShow from './components/Show';

export default {
  create: OptionCreate,
  edit: OptionEdit,
  show: OptionShow,
  list: OptionsList,
};
