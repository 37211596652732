import React from 'react';
import {
  Datagrid,
  List,
  TextField,
} from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const UIBannerList = ({ ...props }) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.title" source="name" />
      </Datagrid>
    </List>
  );
};

export default UIBannerList;
