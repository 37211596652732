/* eslint-disable prefer-promise-reject-errors */
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import url, { urlAuth } from '../config/connection';
import { ALL_USER_ROLES_NAMES } from '../config/user.config';
import { isUser, getRole } from '../utils/roles';
import history from '../history';

const setSelfManagement = async (supplierId) => {
  if (supplierId) {
    const res = await fetch(`${url}/supplier/${supplierId}`);
    const { allowSelfManagement } = await res.json();
    localStorage.setItem('allowSelfManagement', allowSelfManagement);
  }
};

const auth = getAuth();

auth.onIdTokenChanged(async (updatedUser) => {
  const idToken = await updatedUser?.getIdToken();

  localStorage.setItem('token', idToken);
});

function waitForAuthStateChangeOnce() {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(
      async (user) => {
        await user?.getIdToken();
        resolve();
        unsubscribe();
      },
      () => {
        reject();
        unsubscribe();
      },
    );
  });
}

const authProvider = {
  login: async (params) => {
    const { email, password } = params;

    if (email && password) {
      try {
        await setPersistence(auth, browserLocalPersistence);

        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        const errorMessage = error.message;
        // eslint-disable-next-line no-console
        console.error(errorMessage);

        return Promise.reject(errorMessage);
      }

      const idToken = await getAuth().currentUser?.getIdToken();

      const response = await fetch(`${urlAuth}/loginByEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.status < 200 || response.status >= 400) {
        return Promise.reject(new Error(response.statusText));
      }

      const { role, supplierId } = await response.json();
      const userRole = getRole(role);

      if (isUser(role)) {
        setTimeout(() => history.push('/'), 1000);

        return Promise.reject(new Error('Forbidden!'));
      }

      await setSelfManagement(supplierId);

      localStorage.setItem('role', userRole);
      localStorage.setItem('token', idToken);
      localStorage.setItem('supplierId', supplierId);
    }

    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('allowSelfManagement');

    getAuth().signOut();

    return Promise.resolve();
  },
  checkError: async (error) => {
    const { status } = error;

    const token = localStorage.getItem('token');

    if (token === 'undefined') {
      return Promise.reject({ redirectTo: '/login' });
    }

    if (status === 401 || status === 403) {
      await getAuth().signOut();
      window.location.reload();

      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: async () => {
    const currentUser = await auth.currentUser?.getIdTokenResult();

    if (!currentUser) {
      return Promise.reject();
    }

    const expirationTime = new Date(currentUser?.expirationTime).getTime();
    const currentTime = new Date().getTime();
    const twoMinutes = 2 * 60 * 1000;

    const remainingTime = expirationTime - currentTime;

    if (remainingTime < twoMinutes) {
      // Returns the current token if it has not expired or if it will not expire in the next five minutes.
      // Otherwise, it refreshes the token and returns the new one.
      const idToken = (await auth.currentUser?.getIdTokenResult(true))?.token;
      localStorage.setItem('token', idToken);

      return Promise.resolve();
    }
  },
  getPermissions: async () => {
    await waitForAuthStateChangeOnce();
    const role = localStorage.getItem('role');
    const isAdmin =
      [
        ALL_USER_ROLES_NAMES.ADMIN,
        ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN,
        ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH,
      ].indexOf(role) !== -1;

    return isAdmin ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
