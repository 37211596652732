import React from 'react';
import {
  required,
  TextInput,
  SimpleForm,
  Edit,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  ReferenceInput,
} from 'react-admin';
import { TRANSPORT_TYPES } from '../../constants';
import useStyles from '../../styles';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();

const CourierPayoutPolicyEdit = () => {
  const styles = useStyles();

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <TextInput
          label="ra.label.name"
          source="name"
          sx={{ marginLeft: '15px' }}
          validate={validateRequired}
        />
        <ArrayInput
          source="courierPayoutSettings"
          className={styles.line}
          label="ra.label.payout_setting"
        >
          <SimpleFormIterator disableAdd disableRemove disableReordering>
            <SelectInput
              label="ra.label.transport_type"
              source="type"
              choices={TRANSPORT_TYPES}
              className={styles.spaceBetween}
              validate={validateRequired}
            />
            <NumberInput
              label="ra.label.coefficient_value"
              source="coefficient"
              className={styles.spaceBetween}
              validate={validateRequired}
            />
            <ReferenceInput
              label="ra.label.delivery_settings"
              source="deliverySettingId"
              reference="deliverySettings"
              perPage={150}
              className={styles.spaceBetween}
              validate={validateRequired}
            >
              <SelectInput optionText="name" optionValue="id" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default CourierPayoutPolicyEdit;
