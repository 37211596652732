import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
  },
  itemsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    width: '100%',
  },
}));

export default useStyles;
