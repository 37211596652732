import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

const StoreSupplierList = ({ ...props }) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField label="ra.label.id" source="id" />
        <ReferenceField label="ra.label.store" source="storeId" reference="store" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="ra.label.supplier"
          source="supplierId"
          reference="supplier"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default StoreSupplierList;
