import React from 'react';
import { Link } from 'react-router-dom';
import { Button, useRecordContext, useTranslate } from 'react-admin';
import { withStyles } from '@mui/styles';

const styles = {
  button: {
    marginTop: '1em',
    height: '40px',
    maxWidth: '150px',
    width: '100%',
    backgroundColor: '#2196f3',
    color: '#fff',
    border: 'none',
    '&:hover': {
      backgroundColor: '#2196f3d6',
      border: 'none',
    },
  },
};

const AddResourceButton = ({ classes, label, title, to, source }) => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Button
      className={classes.button}
      variant="outlined"
      color="primary"
      component={Link}
      size="small"
      to={to}
      state={{ record: { [source]: record?.id } }}
      label={translate(label || '')}
      title={translate(title || label || '')}
    />
  );
};

export default withStyles(styles)(AddResourceButton);
