import React from 'react';
import Typography from '@mui/material/Typography';
import MUITextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import TimeField from 'react-simple-timefield';
import styles, { TimeStyles } from './style';

const Day = ({ day, data, disabled, onChange, children, classes }) => {
  return (
    <div style={styles.DayStyle}>
      <Checkbox
        disabled={disabled}
        checked={data?.isWorking}
        onChange={(e) => onChange({ day, field: 'isWorking', newVal: !data?.isWorking })}
        value="isWorking"
        color="primary"
      />
      <div style={styles.DayLabel}>
        <Typography variant="body2">{children}</Typography>
      </div>
      <TimeField
        value={data?.from}
        style={{ textAlign: 'center' }}
        disabled={!data?.isWorking || disabled}
        input={<MUITextField classes={{ root: classes.root }} />}
        onChange={(e, newVal) => onChange({ day, field: 'from', newVal })}
        colon=":"
      />
      <span>-</span>
      <TimeField
        value={data?.to}
        style={{ textAlign: 'center' }}
        disabled={!data?.isWorking || disabled}
        input={<MUITextField classes={{ root: classes.root }} />}
        onChange={(event, newVal) => onChange({ day, field: 'to', newVal })}
        colon=":"
      />
    </div>
  );
};

export default withStyles(TimeStyles)(Day);
