import React from 'react';
import Summary from '../OrdersSummary';
import ReportLink from '../ReportLink';
import { ALL_USER_ROLES_NAMES } from '../../../config/user.config';

const OrderListHeader = ({ orderSummary, isTodayOrdersPage }) => {
  const localRole = localStorage.getItem('role');
  const isBranchAdmin = localRole === ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH;
  const isSuperAdmin = localRole === ALL_USER_ROLES_NAMES.ADMIN;

  return (
    !isBranchAdmin && (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          margin: isTodayOrdersPage ? '25px 5px' : '0',
        }}
      >
        {isSuperAdmin &&
        <>
          <Summary orderSummary={orderSummary} />
          <ReportLink />
        </>}
      </div>
    )
  );
};

export default OrderListHeader;
