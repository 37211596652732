import React, { useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useEditController,
  useUpdate,
  useResourceContext,
  useRedirect,
} from 'react-admin';

const validateRequired = required();

const PromoCodeEdit = () => {
  const editControlerProps = useEditController();
  const resource = useResourceContext();
  const redirect = useRedirect();
  const [update] = useUpdate();

  const onSubmit = (data) => {
    const discountId = data.discount._id;
    const newData = { ...data, discount: discountId };
    update(
      resource,
      { id: data.id, data: newData, previousData: editControlerProps?.record },
      {
        onSuccess: () => {
          redirect('list', 'promocode');
        },
      },
    );
  };

  return (
    <Edit redirect="list">
      <SimpleForm onSubmit={onSubmit}>
        <TextInput source="title" name="title" validate={validateRequired} />
        <ReferenceInput reference="discount" source="discount._id">
          <AutocompleteInput label="ra.label.discount" sx={{ width: '206px' }} />
        </ReferenceInput>
        <NumberInput source="maxUsage" name="maxUsage" />
        <NumberInput source="maxUsagePerUser" name="maxUsagePerUser" />
        <BooleanInput defaultValue source="isActive" name="isActive" />
      </SimpleForm>
    </Edit>
  );
};

export default PromoCodeEdit;
