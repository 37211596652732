import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import {
  TabbedShowLayout,
  Tab,
  Show,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  EditButton,
  TextField,
  BooleanField,
  NumberField,
  FunctionField,
  ArrayField,
  DateField,
  ReferenceArrayField,
  useRefresh,
  useShowController,
} from 'react-admin';
import SyncIcon from '@mui/icons-material/Sync';
import ResourceTitle from '../../../components/ResourceTitle';
import syncSupplier from '../../utils/syncSupplier';
import ShowActions from '../ShowActions';
import { TranslatableTextField } from '../../../components/Translatable';
import DeleteDiscountButton from '../DeleteDiscountButton';
import { PaymentMethodField } from '../../../components/PaymentMethod';
import PriceField from '../../../components/PriceField';
import { syncOne } from '../../../utils/syncOne';
import AddResourceButton from '../../../components/AddResourceButton';
import { baseUrl } from '../../../config/connection';
import S3FileField from '../../../components/S3FileUploader/Field';
import { useUiSettings } from '../../../hooks/useUiSettings';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const SupplierShow = () => {
  const refresh = useRefresh();
  const uiSettings = useUiSettings();
  const { record: data } = useShowController();

  return (
    <Show
      title={<ResourceTitle field="name" />}
      actions={<ShowActions data={data} syncSupplier={syncSupplier} />}
    >
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.slug" source="slug" />
          <TranslatableTextField label="ra.label.name" source="name" />
          <TextField label="ra.label.status" source="status" />
          <DateField
            label="ra.label.launch_date"
            source="launchDate"
            options={{ year: 'numeric', month: 'numeric', day: 'numeric' }}
            locales="ro-RO"
          />
          <BooleanField label="ra.label.allow_under_age" source="underAgeAllowed" />
          <ReferenceField
            label="ra.label.delivery_settings"
            source="deliverySettingsId"
            reference="deliverySettings"
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField label="ra.label.rank" source="rank" />
          <ReferenceField
            label="ra.label.payout_setting"
            source="courierPayoutPolicyId"
            reference="policy"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField label="ra.label.externalSupplierId" source="externalSupplierId" />
        </Tab>
        <Tab label="ra.tab.branding" path="branding">
          <TranslatableTextField label="ra.label.highlight" source="highlight" />
          <TranslatableTextField label="ra.label.description" source="description" />
          <ReferenceArrayField label="resources.tag.name" reference="tag" source="tagIds">
            <Datagrid bulkActionButtons={false} sx={{ maxWidth: '50%' }}>
              <TextField source="id" />
              <TextField source="name" />
            </Datagrid>
          </ReferenceArrayField>
          <S3FileField apiRoot={baseUrl} source="cover" />
          <S3FileField apiRoot={baseUrl} source="cardImg" />
          <S3FileField apiRoot={baseUrl} source="heroImg" />
        </Tab>
        <Tab label="ra.tab.financial" path="financial">
          <TextField label="CVR" source="cvrNumber" />
          <TextField label="ra.label.payout" source="payout" />
          <TextField label="ra.label.bank_account_number" source="bankAccount" />
          <TextField label="ra.label.email" source="email" />
          <FunctionField
            label="ra.label.commission"
            render={(record) => `${record.platformCommission}%`}
          />
          <PaymentMethodField label="ra.label.payment_methods" source="paymentTypes" />
          <ArrayField label="ra.label.discount" source="discounts">
            <Datagrid bulkActionButtons={false}>
              <TextField label="ra.label.name" source="name" />
              <TextField label="ra.label.label" source="label" />
              <DateField
                label="ra.label.data"
                source="validUntil"
                options={{ year: 'numeric', month: 'numeric', day: 'numeric' }}
                locales="ro-RO"
              />
              <DeleteDiscountButton />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="ra.tab.supplier_addresses" path="addresses">
          <ReferenceManyField reference="address" target="supplierId" label="">
            <Datagrid>
              <TextField label="ra.label.name" source="name" />
              <TextField label="ra.label.phone" source="phoneNumber" />
              <TextField label="ra.label.country" source="country" />
              <TextField label="ra.label.area" source="area" />
              <TextField label="ra.label.locality" source="locality" />
              <TextField label="ra.label.street" source="street" />
              <TextField label="ra.label.house" source="house" />
              <TextField label="ra.label.notes" source="notes" />
              <TextField label="ra.label.status" source="status" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddResourceButton
            label="ra.action.add_address"
            to="/address/create"
            source="supplierId"
          />
        </Tab>
        <Tab label="ra.tab.goods" path="goods">
          <ReferenceManyField reference="goods" target="supplierId" label="" perPage={1000}>
            <Datagrid
              rowClick="show"
              bulkActionButtons={<ListDeleteConfirmationButton updateDeletedAt />}
            >
              <TextField label="ra.label.id" source="id" />
              <TextField label="ra.label.name" source="name" />
              <PriceField
                label="ra.label.price"
                source="price"
                parse={(v) => v / 100}
                options={{
                  style: 'currency',
                  currency: uiSettings.currency,
                }}
              />
              <ReferenceField
                label="ra.label.category"
                source="categoryId"
                reference="category"
                linkType="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <FunctionField
                label="Syncronize"
                render={(record) => {
                  return record.isSynced ? (
                    <span>Synced</span>
                  ) : (
                    <Tooltip title="Sync good">
                      <IconButton
                        onClick={(e) => syncOne(e, record, refresh)}
                        size="small"
                        color="success"
                      >
                        <SyncIcon />
                      </IconButton>
                    </Tooltip>
                  );
                }}
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddResourceButton label="ra.action.add_good" to="/goods/create" source="supplierId" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default SupplierShow;
