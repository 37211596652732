import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';
import PriceField from '../../../components/PriceField';
import { useUiSettings } from '../../../hooks/useUiSettings';

const ServiceFeesShow = () => {
  const uiSettings = useUiSettings();

  return (
    <Show>
      <SimpleShowLayout>
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.title" source="title" />
        <PriceField
          label="ra.label.feeAmount"
          source="feeAmount"
          parse={(v) => v / 100}
          options={{
            style: 'currency',
            currency: uiSettings.currency,
          }}
        />

        <PriceField
          label="ra.label.feeThreshold"
          source="feeThreshold"
          parse={(v) => v / 100}
          options={{
            style: 'currency',
            currency: uiSettings.currency,
          }}
        />
        <BooleanField label="ra.label.active" source="isActive" />
      </SimpleShowLayout>
    </Show>
  );
};

export default ServiceFeesShow;
