import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Select, FormControl, InputLabel, MenuItem, Button, Box } from '@mui/material';
import 'react-day-picker/lib/style.css';
import { useTranslate } from 'react-admin';
import { TabStyle, exportSection } from '../../style';
import url from '../../../config/connection';
import TimeRangePicker from '../TimeRangePicker';
import Modal from '../../../components/Modal';
import { parseDateToDDMMYYY } from '../../../utils/timeOperations';
import {
  fetchWithAuthorization,
  postWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import formatDateTime from '../../../utils/formatDateTime';
import { DEFAULT_PAYMENT_METHODS } from '../../../config/payment.config';
import { DEFAULT_REPORT_FIELDS } from '../../../config/reportFields.config';
import PreviewData from '../PreviewData';
import CSVExport from '../CSVExport';
import { adminReportHeaders, MONTHLY_REPORT_HEADERS } from '../../constants';
import CheckBoxTable from '../CheckBoxTable';
import { PAYMENT_TYPES } from '../../../Discounts/config';
import SupplierPDFReport from '../PDFReports/SupplierPDFReport';
import { SUPPLIER_REPORT_HEADERS } from '../PDFReports/SupplierPDFReport/helpers/reportHeaders';

const getMonthlyReport = (reportData) => {
  return reportData.map((order) => ({
    ...order,
    cardPrice: order.orderPaymentType === PAYMENT_TYPES.CARD ? order.orderOriginalPrice : '',
    cashPrice: order.orderPaymentType === PAYMENT_TYPES.CASH ? order.orderOriginalPrice : '',
  }));
};

const SupplierReport = ({ getDataHeadings, supplierId }) => {
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplierBranches, setSelectedSupplierBranches] = useState([]);
  const [supplierBranches, setSupplierBranches] = useState([]);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState(DEFAULT_PAYMENT_METHODS);
  const [fields, setFields] = useState(DEFAULT_REPORT_FIELDS.SUPPLIER);

  const [pdfReportData, setPdfReportData] = useState([]);

  const tableHeaders = useMemo(() => adminReportHeaders.filter(({ key }) => fields[key]), [fields]);

  const translate = useTranslate();

  const fetchSuppliersData = useCallback(async () => {
    const parsedPaymentMethods = Object.keys(paymentMethods).filter(
      (method) => paymentMethods[method],
    );

    const result = await postWithAuthorization(`${url}/report/suppliers/export`, {
      body: JSON.stringify({
        selectedSuppliers,
        selectedSupplierBranches,
        dateRange,
        paymentMethods: parsedPaymentMethods,
        reportFields: fields,
      }),
    });

    const data = await result.json();

    data.forEach((row, index) => {
      if (index === data.length - 1) {
        return row;
      }

      const formattedOrderedAt = formatDateTime(row.orderOrderedAt);
      const formattedDeliveredAt = formatDateTime(row.orderDeliveredAt);

      row.orderId = row.orderId.slice(-5);
      row.isMobile = row.isMobile ? 'Yes' : 'No';
      row.orderOrderedAt = `${formattedOrderedAt.date} ${formattedOrderedAt.time}`;
      row.orderDeliveredAt = `${formattedDeliveredAt.date} ${formattedDeliveredAt.time}`;
    });

    return data;
  }, [dateRange, fields, paymentMethods, selectedSupplierBranches, selectedSuppliers]);

  const fetchSuppliersReport = useCallback(async () => {
    if (!(selectedSuppliers.length || (dateRange.from && dateRange.to))) {
      return;
    }

    const data = await fetchSuppliersData();

    setReportData(data);
  }, [dateRange, selectedSuppliers, fetchSuppliersData]);

  useEffect(() => {
    if (!(selectedSuppliers.length || (dateRange.from && dateRange.to))) {
      return;
    }

    const getReportData = async () => {
      const data = await fetchSuppliersData();

      setPdfReportData(data);
    };

    getReportData();
  }, [fetchSuppliersData, dateRange, selectedSuppliers]);

  useEffect(() => {
    if (supplierId) {
      setSelectedSuppliers([Number(supplierId)]);
    }
  }, [supplierId]);

  const fetchSuppliers = useCallback(() => {
    if (suppliers.length) {
      return;
    }

    fetchWithAuthorization(`${url}/supplier`)
      .then((res) => res.json())
      .then((data) => setSuppliers(data))
      .catch(() => {
        throw new Error('failed to fetch');
      });
  }, [suppliers]);

  const fetchSupplierBranches = () => {
    fetchWithAuthorization(`${url}/address?filter=supplierId||in||${selectedSuppliers}`)
      .then((res) => res.json())
      .then((data) => {
        setSupplierBranches(data);
      })
      .catch(() => {
        throw new Error('failed to fetch');
      });
  };

  const handleSupplierSelect = useCallback((event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'all') {
      setSelectedSuppliers(
        selectedSuppliers.length === suppliers.length
          ? []
          : suppliers.map(({ id }) => id),
      );

      return;
    }

    setSelectedSuppliers(value);
    setSelectedSupplierBranches([]);
  }, [suppliers, selectedSuppliers]);

  const handleSupplierBranchSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSupplierBranches(value);
  };

  const composeCalendarBtnTitle = () => {
    const { from, to } = dateRange;
    const isSelectedRange = from && to;

    if (!isSelectedRange) {
      return translate('ra.action.select_time_range');
    }

    return `${parseDateToDDMMYYY(from)} - ${parseDateToDDMMYYY(to)}`;
  };

  const changePaymentMethod = (method, value) => {
    setPaymentMethods((prevPaymentMethods) => ({
      ...prevPaymentMethods,
      [method]: value,
    }));
  };

  const changeField = (field, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const monthlyReportData = useMemo(() => getMonthlyReport(reportData), [reportData]);

  return (
    <div style={TabStyle.wrapper}>
      {!supplierId && (
        <FormControl style={{ minWidth: '250px', maxWidth: '550px' }}>
          <InputLabel htmlFor="supplierId">{translate('ra.label.supplier_select')}</InputLabel>
          <Select
            multiple
            value={selectedSuppliers}
            onChange={handleSupplierSelect}
            onOpen={fetchSuppliers}
            inputProps={{
              name: 'Supplier',
              id: 'supplierId',
            }}
          >
            <MenuItem
              value="all"
            >
              {translate('ra.action.select_all')}
            </MenuItem>
            {suppliers.map(({ id, name }) => {
              return <MenuItem key={id} value={id}>{`${name}`}</MenuItem>;
            })}
          </Select>
        </FormControl>
      )}
      {!!selectedSuppliers.length && (
        <FormControl style={{ minWidth: '250px', maxWidth: '550px' }}>
          <InputLabel htmlFor="supplierBranchId">{translate('ra.label.branch')}</InputLabel>
          <Select
            multiple
            value={selectedSupplierBranches}
            onChange={handleSupplierBranchSelect}
            onOpen={fetchSupplierBranches}
            inputProps={{
              name: 'Supplier Branch',
              id: 'supplierBranchId',
            }}
          >
            {supplierBranches.map(({ id, name }) => {
              return <MenuItem key={id} value={id}>{`${name}`}</MenuItem>;
            })}
          </Select>
        </FormControl>
      )}
      <Button
        variant="outlined"
        color="primary"
        style={{ margin: '15px 0', color: '#477890', borderColor: '#477890' }}
        onClick={() => {
          setCalendarModalOpen(true);
        }}
      >
        {composeCalendarBtnTitle()}
      </Button>
      <Modal open={calendarModalOpen} onClose={() => setCalendarModalOpen(false)}>
        <TimeRangePicker range={dateRange} changeRange={setDateRange} />
      </Modal>
      <CheckBoxTable
        data={paymentMethods}
        onChangeAction={changePaymentMethod}
        type="changePaymentMethod"
        translate={translate}
      />
      <div style={exportSection}>
        <CSVExport
          headers={tableHeaders}
          data={reportData}
        />
        <CSVExport
          headers={MONTHLY_REPORT_HEADERS}
          data={monthlyReportData}
          label="ra.action.export_monthly_report"
        />
      </div>
      <CheckBoxTable data={fields} onChangeAction={changeField} type="changeField" />
      <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', minWidth: 400 }}>
        <Button onClick={fetchSuppliersReport} variant="outlined" color="primary">
          {translate('ra.action.generate_report')}
        </Button>
        <SupplierPDFReport
          supplier={suppliers.find(({ id }) => id === selectedSuppliers[0])}
          reportData={pdfReportData}
          dateRange={dateRange}
          tableHeaders={SUPPLIER_REPORT_HEADERS}
        />
      </Box>
      <PreviewData fields={fields} data={reportData} getDataHeadings={getDataHeadings} />
    </div>
  );
};

export default SupplierReport;
