import React from 'react';
import {
  SimpleShowLayout,
  Show,
  TextField,
  ReferenceField,
  NumberField,
  BooleanField,
  useShowController,
} from 'react-admin';
import { TranslatableTextField } from '../../../components/Translatable';
import PriceField from '../../../components/PriceField';
import ResourceTitle from '../../../components/ResourceTitle';

const OptionShow = () => {
  const ShowProps = useShowController();

  return (
    <Show title={<ResourceTitle field="name" />}>
      <SimpleShowLayout>
        <TranslatableTextField label="ra.label.name" source="name" />
        <BooleanField label="ra.label.is_free_option" source="isFreeOption" />
        <PriceField parse={(v) => v / 100} label="ra.label.price" source="price" />
        <NumberField label="ra.label.maxQuantity" source="maxOptions" />
        {ShowProps.record?.isFreeOption && (
          <NumberField label="ra.label.maxFreeQuantity" source="maxFreeOptions" min={0} />
        )}
        <ReferenceField label="ra.label.supplier" source="supplierId" reference="supplier">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="ra.label.external" source="externalId" />
      </SimpleShowLayout>
    </Show>
  );
};

export default OptionShow;
