import React, { useMemo, useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { PERK_TYPES } from '../../../config';
import PerkType from '../PerkType';
import styles from '../../style';

const PerkTypeList = (props) => {
  const { value, onChange, disabled } = props;

  const [checkedPerks, setCheckedPerks] = useState(value);

  useEffect(() => {
    if (checkedPerks) {
      onChange(checkedPerks);
    }
  }, [checkedPerks, onChange]);

  const perkTypeList = useMemo(() => Object.keys(PERK_TYPES), []);

  const handlePerkCheck = (e, perkOptions) => {
    const { checked, value: type } = e.target;
    let updatedPerksList = JSON.parse(JSON.stringify(checkedPerks || [])) || [];
    updatedPerksList = updatedPerksList.filter((perk) => perk.type !== type);
    if (checked) {
      updatedPerksList.push(perkOptions);
    }

    setCheckedPerks(() => updatedPerksList);
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Perks:</h3>
      <div style={styles.flexColumn}>
        {perkTypeList.map((perkType) => (
          <PerkType
            key={perkType}
            type={perkType}
            handlePerkCheck={handlePerkCheck}
            checkedPerks={checkedPerks}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

export const PerkTypeListInput = () => {
  const input = useController({ name: 'perks' });

  return <PerkTypeList {...input.field} />;
};
