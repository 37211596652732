import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    width: '97%',
    display: 'flex',
    justifyContent: 'end',
    gap: '10px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default useStyles;
