import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Paper, Button, Tabs, Tab } from '@mui/material';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseUrl } from '../config/connection';
import { patchMenu } from './utils';
import DraggableSections from './DraggableSections';
import ManageGoodsDialog from './ManageGoodsDialog';
import DiscountsTab from './DiscountsTab/index';
import styles from './styles';
import { useOrganizationLocales } from '../hooks/useOrganizationLocales';

const useStyles = makeStyles(styles);

const MenuEdit = () => {
  const [supplierGoods, setSupplierGoods] = useState([]);
  const [sections, setSections] = useState([]);
  const [managedSectionId, setManagedSection] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [tab, setTab] = useState(0);
  const [isSavedChanges, setIsSavedChanges] = useState(true);

  const classes = useStyles();
  const translate = useTranslate();
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = (_, newTab) => {
    setTab(newTab);
  };

  const currentLocale = localStorage.getItem('currentLocale');

  const id = location.pathname.split('/')[2];

  const token = localStorage.getItem('token');

  const managedSection = useMemo(
    () => sections?.find(({ id: sectionId }) => sectionId === managedSectionId),
    [managedSectionId, sections],
  );

  useEffect(() => {
    fetch(`${baseUrl}/admin/menu/${id}`, {
      headers: {
        'X-Locale': currentLocale,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setSections(res.sections);
        setSupplierId(res.supplierId);
      });
  }, [id, currentLocale, token]);

  useEffect(() => {
    if (supplierId) {
      fetch(`${baseUrl}/supplier/${supplierId}?switchToMenu=false`, {
        headers: {
          'X-Locale': currentLocale,
          'x-react': true,
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setSupplierGoods(() => [...res.goods]);
        });
    }
  }, [currentLocale, supplierId, token]);

  useEffect(() => {
    if (!managedSection || !supplierGoods.length) {
      return;
    }
    if (managedSection.goods) {
      managedSection.goods.forEach((good) => {
        const goodInSupplierList = supplierGoods.find(
          ({ id: supplierGoodId }) => supplierGoodId === good.id,
        );

        goodInSupplierList.stopList = good.stopList;
        goodInSupplierList.price = good.price;
        goodInSupplierList.schedule = good.schedule;
      });
    }
  }, [managedSection, supplierGoods]);

  const composeNewSection = (label, sectionId, translations = [], goods = []) => ({
    label,
    goods,
    translations,
    id: sectionId,
  });

  const handleManageGoodsClose = useCallback(() => setManagedSection(null), []);

  const locales = useOrganizationLocales();

  const addSection = () => {
    const value = 'New Section';
    const property = 'label';
    const objectId = `${value}${sections.length}`;
    const translations = locales.map((locale) => ({
      objectId,
      property,
      value,
      locale,
      className: 'Section',
    }));
    const newSection = composeNewSection(value, objectId, translations);

    setSections(() => [newSection, ...sections]);
    setIsSavedChanges(false);
  };

  const removeSection = (sectionId) => {
    setSections((prev) => prev.filter((section) => section.id !== sectionId));
    setIsSavedChanges(false);
  };

  const parseProducts = () => {
    const parsedSections = supplierGoods.reduce((acc, curr) => {
      const { category } = curr;
      const { translations } = category;
      const labelTranslations =
        !!translations && translations.filter((translation) => translation.property === 'name');
      const label = labelTranslations.find((translation) => translation.locale === currentLocale);
      const foundSection = acc.find(({ id: sectionId }) => sectionId === `category${category.id}`);

      if (!foundSection) {
        const goods = supplierGoods.filter(
          ({ category: goodCategory }) => goodCategory.id === category.id,
        );

        const newSection = composeNewSection(
          label.value,
          `category${category.id}`,
          labelTranslations,
          goods,
        );

        return [...acc, newSection];
      }

      return acc;
    }, []);

    return parsedSections;
  };

  const handleSave = () => {
    patchMenu(id, {
      sections,
    }).then(() => {
      navigate('/menu');
    });
    setIsSavedChanges(true);
  };

  const sectionProps = {
    setSections,
    setIsSavedChanges,
    sections,
    classes,
    setManagedSection,
    removeSection,
  };

  if (!supplierId) {
    return null;
  }

  return (
    <>
      <Paper square>
        <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
          <Tab label="Menu" />
          <Tab label="Discounts" />
        </Tabs>
      </Paper>
      {tab === 0 && (
        <>
          <ManageGoodsDialog
            supplierGoods={supplierGoods}
            handleClose={handleManageGoodsClose}
            section={managedSection}
            setSections={setSections}
            parseProducts={parseProducts}
            setIsSavedChanges={setIsSavedChanges}
          />
          <Paper className={classes.sectionWrapper} style={styles.flex}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              style={styles.actionButton}
            >
              {translate('ra.action.menu_save')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={addSection}
              style={styles.actionButton}
            >
              {translate('ra.action.menu_add_new_section')}
            </Button>
            {!isSavedChanges && (
              <div style={styles.unSavedChanges}>
                {translate('ra.notification.unsaved_changes')}
              </div>
            )}
            {!sections.length && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSections(parseProducts())}
                style={styles.actionButton}
              >
                {translate('ra.action.menu_parse_products')}
              </Button>
            )}
          </Paper>
          <DraggableSections {...sectionProps} />
        </>
      )}
      {tab === 1 && <DiscountsTab sections={sectionProps.sections} menuId={id} />}
    </>
  );
};

export default MenuEdit;
