import React, { useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  minValue,
  required,
  useCreateController,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import CancelButton from '../../../components/CancelButton';
import SupplierAutocomplete from '../../../components/SupplierAutocomplete';
import { TranslatableTextInput } from '../../../components/Translatable';
import { ALL_USER_ROLES_NAMES } from '../../../config/user.config';

const validateRequired = required();
const validatePositiveRange = [minValue(0)];

const OptionGroupsCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable />
  </Toolbar>
);

const OptionGroupCreate = () => {
  const [isMultiple, setIsMultiple] = useState(false);
  const isSuperAdmin = localStorage.getItem('role') === ALL_USER_ROLES_NAMES.ADMIN;
  const translate = useTranslate();
  const navigate = useNavigate();
  const refresh = useRefresh();

  const { ...createProps } = useCreateController();

  const formRecord = {
    translations: [],
    itemsIds: [],
    items: [],
  };

  return (
    <Create
      record={{ ...formRecord, ...createProps }}
      actions={<CancelButton />}
      redirect={() => {
        navigate(-1);
        refresh();
      }}
    >
      <SimpleForm toolbar={<OptionGroupsCreateToolbar />}>
        <TranslatableTextInput label="ra.label.name" source="name" validate={validateRequired} />
        {isSuperAdmin && <SupplierAutocomplete />}
        <ReferenceInput label="ra.label.goods" reference="goods" source="goodId">
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            sx={{ maxWidth: '218px', width: '100%' }}
          />
        </ReferenceInput>
        <TextInput label="ra.label.external" source="externalId" />
        {isMultiple && (
          <>
            <NumberInput
              label="ra.label.maxSelectedOptions"
              source="maxSelectedOptions"
              validate={validatePositiveRange}
              style={{ minWidth: '270px' }}
            />
            <span style={{ fontSize: '13px', color: '#0000008a', display: 'block' }}>
              {translate('ra.label.unlimitedOptions')}
            </span>
          </>
        )}
        <BooleanInput
          label="ra.label.is_multiple"
          source="isMultiple"
          onChange={(e) => setIsMultiple(e.target.checked)}
        />
        <BooleanInput label="ra.label.is_required" source="required" />
      </SimpleForm>
    </Create>
  );
};

export default OptionGroupCreate;
