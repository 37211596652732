import React, {
  useCallback,
} from 'react';
import {
  required,
  TextInput,
} from 'react-admin';
import Editor from '@monaco-editor/react';
import {
  useController,
  useForm,
} from 'react-hook-form';

export function FormattedTextArea({ language, source, height }) {
  const control = useController({
    name: source,
    defaultValue: '',
  });

  const onChangeCallback = useCallback((value) => {
    control.field.onChange(value);
  }, [control]);

  return (
    <Editor
      language={language}
      height={height}
      value={control.field.value}
      onChange={onChangeCallback}
    />
  );
}

const UIHtmlSeoForm = () => {
  const control = useForm();

  return (
    <>
      <TextInput label="ra.label.title" source="name" validate={required()} />
      <FormattedTextArea
        language="html"
        height={500}
        source="script"
      />
      <div>{control.getValues('script')}</div>
    </>);
};

export default UIHtmlSeoForm;
