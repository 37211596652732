import React, {
  useEffect,
  useRef,
} from 'react';
import MarketplacePreview from '../MarketplacePreview';
import { FormWithPreviewContainer } from './style';

export default function FormWithPreview({
  children,
  previewData,
  previewStyles,
  orientation = 'row',
}) {
  /**
   * @type {React.MutableRefObject<HTMLElement>}
   */
  const selfRef = useRef();

  useEffect(() => {
    try {
      selfRef.current.closest('.MuiPaper-root').style.overflow = 'initial';
    } catch {
      console.log('No blocker found for "position: sticky"');
      // no action needed
    }
  }, []);

  return (
    <FormWithPreviewContainer orientation={orientation} ref={(ref) => { selfRef.current = ref; }}>
      <div>
        { children }
      </div>
      <MarketplacePreview changes={previewData} styles={previewStyles} />
    </FormWithPreviewContainer>
  );
}
