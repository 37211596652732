import {
  useEffect,
  useState,
} from 'react';
import { fetchWithAuthorization } from '../utils/fetchWithAuthorization';
import url from '../config/connection';
import RESOURCES_NAMES from '../config/resources.config';

export const useUiSettings = () => {
  const [uiSettings, setUiSettings] = useState([]);

  useEffect(() => {
    fetchWithAuthorization(`${url}/${RESOURCES_NAMES.SETTINGS}`)
      .then((results) => results.json())
      .then((data) => setUiSettings(data[0]));
  }, []);

  return uiSettings;
};
