const schedule = {
  timezone: '+0100',
  monday: {
    from: '00:00',
    to: '00:00',
    isWorking: false,
  },
  tuesday: {
    from: '00:00',
    to: '00:00',
    isWorking: false,
  },
  wednesday: {
    from: '00:00',
    to: '00:00',
    isWorking: false,
  },
  thursday: {
    from: '00:00',
    to: '00:00',
    isWorking: false,
  },
  friday: {
    from: '00:00',
    to: '00:00',
    isWorking: false,
  },
  saturday: {
    from: '00:00',
    to: '00:00',
    isWorking: false,
  },
  sunday: {
    from: '00:00',
    to: '00:00',
    isWorking: false,
  },
};
export default schedule;
