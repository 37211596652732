import RunCampaignCreate from './components/Create';
import RunCampaignEdit from './components/Edit';
import RunCampaignList from './components/List';
import RunCampaignShow from './components/Show';

export default {
  list: RunCampaignList,
  create: RunCampaignCreate,
  show: RunCampaignShow,
  edit: RunCampaignEdit,
};
