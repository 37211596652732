import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
`;

const useStyles = makeStyles({
  formIterator: {
    '& section': {
      display: 'flex',
      alignItems: 'flex-start',
      width: 456,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  delIcon: {
    color: 'red',
    cursor: 'pointer',
  },
  itemContent: {
    display: 'flex',
  },
  input: {
    marginRight: 10,
    width: 256,
  },
  settingsSection: {
    marginTop: 10,
  },
  orderPriceIterator: {
    '& section': {
      display: 'flex',
      alignItems: 'baseline',
    },
  },
  listContainer: {
    border: '2px solid black',
  },
  itemWrapper: {
    display: 'flex',
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default useStyles;
