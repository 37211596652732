import React from 'react';
import { AutocompleteInput, Filter, ReferenceInput } from 'react-admin';
import { ALL_USER_ROLES_NAMES } from '../../config/user.config';

const SupplierFilter = (props) => {
  const isSuperAdmin = localStorage.getItem('role') === ALL_USER_ROLES_NAMES.ADMIN;

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <Filter {...props}>
      <ReferenceInput
        alwaysOn
        source="supplierId"
        reference="supplier"
        enableGetChoices={({ q }) => q && q.length >= 2}
      >
        <AutocompleteInput
          optionText="name"
          filterToQuery={searchText => { return { 'translations.value': searchText }; }}
        />
      </ReferenceInput>
    </Filter>
  );
};

export default SupplierFilter;
