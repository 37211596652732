import React, { useCallback } from 'react';
import {
  ArrayInput,
  FormDataConsumer,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import Editor from '@monaco-editor/react';
import { useController, useForm } from 'react-hook-form';

const actionChoices = [
  { id: 'PASSWORD_RESET', name: 'PASSWORD_RESET' },
  { id: 'ACCOUNT_CREATION', name: 'ACCOUNT_CREATION' },
  { id: 'PAYMENT_FAILED', name: 'PAYMENT_FAILED' },
  { id: 'CARD_ORDER_CONFIRMED', name: 'CARD_ORDER_CONFIRMED' },
  { id: 'INVOICE_ORDER_CONFIRMED', name: 'INVOICE_ORDER_CONFIRMED' },
  { id: 'ORDER_DELIVERED', name: 'ORDER_DELIVERED' },
  { id: 'DAILY_REPORT', name: 'DAILY_REPORT' },
];

const notificationChannelChoices = [
  { id: 'EMAIL', name: 'EMAIL' },
  { id: 'SMS', name: 'SMS' },
  { id: 'PUSH', name: 'PUSH' },
];

const variables = [
  'CLIENT_FULL_NAME',
  'ORDER_ID',
  'CREATED_AT',
  'SUPPLIER_NAME',
  'ADDRESS',
  'ITEMS_LIST',
  'TOTAL_PRICE',
  'DELIVERY_PRICE',
  'CURRENCY',
  'REPORT_DATE',
  'REPORT_YESTERDAY_TOTAL_ORDERS',
  'REPORT_YESTERDAY_TOTAL_VALUE',
  'REPORT_YESTERDAY_TOTAL_DELIVERY',
  'REPORT_WEEK_NUMBER',
  'REPORT_WEEK_TOTAL_ORDERS',
  'REPORT_WEEK_TOTAL_VALUE',
  'REPORT_WEEK_TOTAL_DELIVERY',
  'REPORT_MONTH_NAME',
  'REPORT_MONTH_TOTAL_ORDERS',
  'REPORT_MONTH_TOTAL_VALUE',
  'REPORT_MONTH_TOTAL_DELIVERY',
];

const validateRequired = required();

export function FormattedTextArea({ language, source, height, width }) {
  const control = useController({
    name: source,
    defaultValue: '',
  });

  const onChangeCallback = useCallback(
    (value) => {
      control.field.onChange(value);
    },
    [control],
  );

  return (
    <Editor
      language={language}
      height={height}
      width={width}
      value={control.field.value}
      onChange={onChangeCallback}
    />
  );
}

const NotificationHtmlForm = () => {
  const control = useForm();

  return (
    <>
      <SelectInput
        label="ra.label.action"
        source="action"
        validate={validateRequired}
        choices={actionChoices}
        sx={{ maxWidth: '218px', width: '100%' }}
      />
      <FormDataConsumer>
        {({ formData }) => {
          return formData.action === 'DAILY_REPORT' && (
            <>
              <ArrayInput source="recipients" label="Recipients">
                <SimpleFormIterator inline disableReordering>
                  <TextInput label="Email" format={(v) => (typeof v === 'string' ? v : '')} />
                </SimpleFormIterator>
              </ArrayInput>
              <TextInput label="Scheduled Time" source="scheduledAt" />
            </>
          );
        }}
      </FormDataConsumer>
      <SelectInput
        label="ra.label.notification_channel"
        source="channel"
        validate={validateRequired}
        choices={notificationChannelChoices}
        sx={{ maxWidth: '218px', width: '100%' }}
      />
      <TextInput label="ra.label.title" source="title" sx={{ maxWidth: '218px', width: '100%' }} />
      <div style={{ display: 'flex' }}>
        <FormattedTextArea language="html" height={500} width={1020} source="template" />
        <ul>
          {variables.map((item) => {
            return <li key={item}>{item}</li>;
          })}
        </ul>
      </div>
      <div>{control.getValues('template')}</div>
    </>
  );
};

export default NotificationHtmlForm;
