import React from 'react';
import { BooleanField, Datagrid, List, NumberField, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import PriceField from '../../../components/PriceField';
import { useUiSettings } from '../../../hooks/useUiSettings';

const ServiceFeesList = () => {
  const uiSettings = useUiSettings();

  return (
    <List>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.title" source="title" />
        <PriceField
          label="ra.label.feeAmount"
          source="feeAmount"
          parse={(v) => v / 100}
          options={{
            style: 'currency',
            currency: uiSettings.currency,
          }}
        />
        <PriceField
          label="ra.label.feeThreshold"
          source="feeThreshold"
          parse={(v) => v / 100}
          options={{
            style: 'currency',
            currency: uiSettings.currency,
          }}
        />
        <BooleanField label="ra.label.active" source="isActive" />
      </Datagrid>
    </List>
  );
};

export default ServiceFeesList;
