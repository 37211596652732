import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Button, TextField } from '@mui/material';
import { useRefresh, useTranslate } from 'react-admin';
import useStyles from './styles';
import { patchWithAuthorization } from '../../../../utils/fetchWithAuthorization';
import url from '../../../../config/connection';
import { ORDER_STATUS_NAMES } from '../../../../config/statuses.config';

const CancelOrder = ({ changeOrderStatus, handleClose, currentOrder }) => {
  const styles = useStyles();
  const translate = useTranslate();
  const refresh = useRefresh();

  const [orderComment, setOrderComment] = useState('');
  const [err, setErr] = useState(true);

  useEffect(() => {
    setErr(!orderComment);
  }, [orderComment]);

  const placeCommentToOrder = useCallback(() => {
    changeOrderStatus(
      'order',
      {
        id: currentOrder.id,
        data: { ...currentOrder, supplierStatus: ORDER_STATUS_NAMES.CANCELED },
        previousData: currentOrder,
      },
      {
        onSuccess: () => {
          patchWithAuthorization(`${url}/order/${currentOrder.id}/comment`, {
            body: JSON.stringify({
              comment: orderComment,
            }),
          });
          handleClose();
          refresh();
        },
      },
    );
  }, [orderComment, changeOrderStatus, currentOrder, handleClose, refresh]);

  const cancelOrder = useCallback(() => {
    if (err) {
      return;
    }

    placeCommentToOrder();
  }, [err, placeCommentToOrder]);

  return (
    <div>
      <div className={styles.titleWrapper}>
        <Typography variant="h2" className={styles.title}>
          {translate('ra.message.cancel_order_confirm')}
        </Typography>
      </div>
      <div className={styles.comment}>
        <TextField
          label={translate('ra.label.comment')}
          multiline
          rows="4"
          margin="normal"
          value={orderComment}
          onChange={(e) => setOrderComment(e.target.value)}
          error={err}
          helperText={translate('ra.validation.required')}
          className={styles.commentInput}
        />
      </div>
      <div className={styles.footer}>
        <Button
          className={styles.cancelBtn}
          onClick={() => {
            cancelOrder();
          }}
        >
          {translate('ra.message.yes_cancel_order')}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            handleClose();
          }}
        >
          {translate('ra.message.not_cancel_order')}
        </Button>
      </div>
    </div>
  );
};

export default CancelOrder;
