import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { useTranslate } from 'react-admin';
import GoodCard from '../../GoodCard';
import commonStyles from '../../styles';
import useStyles from './styles';

const useCommonStyles = makeStyles(commonStyles);

const DiscountSection = ({
  section,
  selectSection,
  selectItem,
  selected,
  removeSection,
  discounts,
}) => {
  const [sectionTranslation, setSectionTranslation] = useState(null);

  const commCls = useCommonStyles();
  const styles = useStyles();
  const translate = useTranslate();

  const currentLocale = localStorage.getItem('currentLocale');

  const selectedItemsCount = section.goods.reduce(
    (acc, { id }) => (selected.has(id) ? acc + 1 : acc),
    0,
  );

  useEffect(() => {
    const currentTranslation = section.translations.find(
      (translation) => translation.locale === currentLocale,
    );

    setSectionTranslation(currentTranslation.value);
  }, [section.translations, currentLocale]);

  return (
    <div className={styles.root}>
      <Accordion className={commCls.expansionPanels}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={commCls.expansionPanelTitle}>{sectionTranslation}</Typography>
          <div className={commCls.sectionActions}>
            <Box mr={2}>
              <Typography variant="h6">
                {translate('ra.label.selected_count', {
                  count: selectedItemsCount,
                  all: section.goods.length,
                })}
              </Typography>
            </Box>
            <Button
              onClick={selectSection}
              className={commCls.manageButton}
              variant="contained"
              color="primary"
            >
              {translate('ra.action.select_section')}
            </Button>
            <Button
              onClick={removeSection}
              className={commCls.manageButton}
              variant="contained"
              color="primary"
            >
              {translate('ra.action.remove_section')}
            </Button>
          </div>
        </AccordionSummary>
        <AccordionDetails className={commCls.section}>
          <div className={styles.itemsContainer}>
            {section.goods.map((good) => {
              const currentDiscount = discounts.find((disc) => disc.id === good.discountId);
              const discountName = currentDiscount && currentDiscount.name;

              return (
                <GoodCard
                  key={good.slug}
                  good={good}
                  onClick={() => selectItem(good.id)}
                  selected={selected.has(good.id)}
                  discountName={discountName}
                  fromDiscountTab
                  shouldDisplayActionButtons
                />
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DiscountSection;
