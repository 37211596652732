import url from '../config/connection';
import { fetchWithAuthorization } from './fetchWithAuthorization';

export const syncOne = (e, record, refresh) => {
  e.stopPropagation();

  fetchWithAuthorization(`${url}/goods/sync/${record.id}`, {
    method: 'POST',
  })
    .then((res) => res.json())
    .then(() => {
      refresh();
    });
};
