import React from 'react';
import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin';
import ResourceTitle from '../../../components/ResourceTitle';

const DiscountShow = () => (
  <Show title={<ResourceTitle field="name" />}>
    <TabbedShowLayout>
      <Tab label="ra.label.name">
        <TextField source="name" />
        <TextField source="label" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default DiscountShow;
