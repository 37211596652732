import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { withStyles } from '@mui/styles';
import { Typography, Button, FormControl, TextField } from '@mui/material';
import style, { CommentOrderStyles } from './style';
import url from '../../../config/connection';
import { patchWithAuthorization } from '../../../utils/fetchWithAuthorization';

const CommentOrder = ({ classes, currentOrder, handleClose }) => {
  const [orderComment, setOrderComment] = useState(false);
  const translate = useTranslate();
  const { cancelBtn, footer } = style;

  const placeCommentToOrder = () => {
    patchWithAuthorization(`${url}/order/${currentOrder.id.toString()}/comment`, {
      body: JSON.stringify({
        comment: orderComment,
      }),
    }).then(() => handleClose());
  };

  return (
    <div className={classes.commentModal}>
      <Typography variant="h2" style={{ fontSize: '20px', opacity: '0.8' }}>
        {translate('ra.title.comment_order')}
      </Typography>
      <FormControl className={classes.formControl}>
        <TextField
          label={translate('ra.label.comment')}
          multiline
          rows="4"
          className={classes.textField}
          margin="normal"
          onChange={(e) => setOrderComment(e.target.value)}
        />
      </FormControl>
      <div style={footer}>
        <Button style={cancelBtn} onClick={handleClose}>
          {translate('ra.action.close')}
        </Button>
        <Button color="primary" onClick={placeCommentToOrder}>
          {translate('ra.action.confirm')}
        </Button>
      </div>
    </div>
  );
};

export default withStyles(CommentOrderStyles)(CommentOrder);
