import NavigationCreate from './components/Create';
import NavigationEdit from './components/Edit';
import NavigationList from './components/List';
import NavigationShow from './components/Show';

export default {
  list: NavigationList,
  create: NavigationCreate,
  edit: NavigationEdit,
  show: NavigationShow,
};
