import url from '../../config/connection';
import { postWithAuthorization } from '../../utils/fetchWithAuthorization';

const syncSupplier = (id) =>
  postWithAuthorization(`${url}/goods/sync`, {
    body: JSON.stringify({
      supplierId: id,
    }),
  });

export default syncSupplier;
