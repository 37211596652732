import React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import { TranslatableTextInput } from '../../../components/Translatable';

const validateRequired = required();

const TagsCreate = () => (
  <Create redirect="list" actions={<CancelButton />}>
    <SimpleForm>
      <TextInput
        label="ra.label.name"
        source="name"
        validate={validateRequired}
        sx={{ maxWidth: '270px', width: '100%' }}
      />
      <TranslatableTextInput label="ra.label.label" source="label" validate={validateRequired} />
      <TextInput
        label="ra.label.slug"
        source="slug"
        validate={validateRequired}
        sx={{ maxWidth: '270px', width: '100%' }}
      />
    </SimpleForm>
  </Create>
);

export default TagsCreate;
