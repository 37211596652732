import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { ReactComponent as EditIcon } from './editIcon.svg';

const useStyles = makeStyles({
  root: {
    minWidth: '25px',
    '& > *': {
      minWidth: '25px',
    },
  },
});

const EditOrder = () => {
  const classes = useStyles();
  const record = useRecordContext();
  const translate = useTranslate();
  const navigate = useNavigate();
  const resource = useResourceContext();

  const recordId = record && record.id;

  const redirectToEdit = (id) => {
    if (id) {
      navigate(`/${resource}/${id}/edit`);
    }
  };

  return (
    <Tooltip title={translate('ra.title.edit_order')}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          redirectToEdit(recordId);
        }}
        className={classes.root}
        size="small"
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};

export default EditOrder;
