import React from 'react';
import { useTranslate } from 'react-admin';
import PercentPerk from './PercentPerk';
import AmountPerk from './AmountPerk';
import FreeDeliveryPerk from './FreeDeliveryPerk';
import { PERK_TYPES } from '../../../config';

const PerkType = (props) => {
  const translate = useTranslate();
  const { type } = props;

  const perkTypeSwitch = {
    [PERK_TYPES.PERCENT_PERK]: <PercentPerk {...props} />,
    [PERK_TYPES.AMOUNT_PERK]: <AmountPerk {...props} />,
    [PERK_TYPES.FREE_DELIVERY_PERK]: <FreeDeliveryPerk {...props} />,
  };

  if (!perkTypeSwitch[type]) {
    return translate('ra.discounts.cannotHandlePerk', { type });
  }

  return perkTypeSwitch[type];
};

export default PerkType;
