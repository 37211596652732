import { baseUrl } from '../config/connection';
import { patchWithAuthorization } from '../utils/fetchWithAuthorization';

// eslint-disable-next-line import/prefer-default-export
export const patchMenu = (id, data) => {
  const url = `${baseUrl}/admin/menu/${id}`;

  return patchWithAuthorization(url, {
    body: JSON.stringify(data),
  });
};
