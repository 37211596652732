import React from 'react';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useRefresh, useUpdate } from 'react-admin';
import url from '../../../config/connection';
import { patchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import AssignCourierButton from '../AssignCourierButton';
import DeliveryColumn from '../DeliveryColumn';
import RefuseOrderButton from '../RefuseOrderButton';
import PartialRefund from './PartialRefund';

const DeliveryActions = ({
  isCurrentUserAdmin,
  isCurrentCourierAssignStatus,
  isStandartCourierSource,
  courierConfirmed,
  record,
}) => {
  const refresh = useRefresh();
  const [update] = useUpdate();

  const unassignCourier = (orderId, courierId) => {
    update(
      'order',
      {
        id: orderId,
        data: { ...record, courier: null },
        previousData: record,
      },
      {
        onSuccess: () => {
          patchWithAuthorization(`${url}/delivery/unassign`, {
            body: JSON.stringify({ userId: courierId, orderId }),
          }).then(() => {
            refresh();
          });
        },
      },
    );
  };

  return (
    <>
      {isCurrentUserAdmin && isCurrentCourierAssignStatus && isStandartCourierSource && (
        <>
          {!!record.courier && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                unassignCourier(record.id, record.courier.id);
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
          <AssignCourierButton
            isReAssign={!!record.courier}
            isCourierConfirmed={courierConfirmed}
            order={record}
          />
          <RefuseOrderButton order={record} />
        </>
      )}
      <DeliveryColumn record={record} />
      {['CARD', 'VIPPS'].includes(record.payment.type) && <PartialRefund record={record} />}
    </>
  );
};

export default DeliveryActions;
