import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  inputFilter: {
    minWidth: 200,
  },
  button: {
    margin: '8px',
  },
});

export default useStyles;
