import React from 'react';
import { Datagrid, List, TextField, useRecordContext } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import { LocalizedImageField } from '../../../components/LocalizedImageField';
import { BannerType } from '../constants';

const ImageFieldOrHTML = ({ label, source }) => {
  const record = useRecordContext();
  const isImageType = record?.bannerType === BannerType.Image;

  return isImageType && <LocalizedImageField label={label} source={source} />;
};

const UIBannerList = ({ ...props }) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.title" source="title" />
        <TextField label="ra.label.bannerType" source="bannerType" />
        <ImageFieldOrHTML label="ra.label.image_desktop" source="imageDesktop" />
        <ImageFieldOrHTML label="ra.label.image_mobile" source="imageMobile" />
      </Datagrid>
    </List>
  );
};

export default UIBannerList;
