import React from 'react';
import {
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';
import MapField from '../../../components/MapMd/Field';
import { ScheduleField } from '../../../components/Schedule';

const AddressShow = () => {
  const showControlerProps = useShowController();

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.name" source="name" />
          <TextField label="ra.label.phone" source="phoneNumber" />
          <TextField label="ra.label.country" source="country" />
          <TextField label="ra.label.area" source="area" />
          <TextField label="ra.label.locality" source="locality" />
          <TextField label="ra.label.street" source="street" />
          <TextField label="ra.label.house" source="house" />
          <TextField label="ra.label.notes" source="notes" />
          <TextField label="ra.label.status" source="status" />
          <TextField label="ra.label.external" source="externalId" />
          <ScheduleField source="schedule" />
          <ReferenceField
            label="ra.label.supplier"
            source="supplierId"
            reference="supplier"
            linkType="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <MapField geo={showControlerProps?.record?.geo} />
        </Tab>
        <Tab label="ra.tab.financial">
          <TextField label="CVR" source="cvrNumber" />
          <TextField label="ra.label.email" source="email" />
          <TextField label="ra.label.bank_account_number" source="bankAccount" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default AddressShow;
