import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  discountSelector: {
    minWidth: 200,
  },
  selectorWrapper: {
    flex: 1,
    marginLeft: 8,
  },
}));

export default useStyles;
