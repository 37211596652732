import React, { Fragment, useEffect, useState } from 'react';

import { Card, CardMedia, Dialog, DialogContent, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRecordContext, useTranslate } from 'react-admin';
import OrderItemsList from './OrderItemsList';
import ExternalOrderItems from './ExternalOrderItems';
import TimeInfo from './orderInfo/TimeInfo';
import useStyles from './styles';
import formatDateTime from '../../../utils/formatDateTime';
import url, { baseUrl } from '../../../config/connection';
import ShowAddressOnMap from '../ShowAddressOnMapButton';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { useUiSettings } from '../../../hooks/useUiSettings';

const getOptionsPrice = (options) =>
  (options ? options.reduce((acc, { price }) => acc + price, 0) : 0);

const getOriginalPrice = (orderItems) => {
  const priceWithoutDiscount = orderItems.reduce(
    (acc, { originalPrice, price, quantity, options }) =>
      acc +
      ((originalPrice !== undefined ? originalPrice : price) + getOptionsPrice(options)) * quantity,
    0,
  );

  return priceWithoutDiscount / 100;
};

const getIsExternal = (externalContent) => !!(externalContent && externalContent.structuredContent);

const getIsExternalId = (externalId) => !!(externalId && externalId.shortCode);

const OrderItems = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const {
    targetAddress,
    client,
    supplier,
    itemsPrice,
    orderItems,
    payment,
    deliveryDetails,
    courier,
    totalPrice,
    totalDiscounts,
    deliveryPrice,
    externalContent,
    externalId,
  } = record;
  const { locality, area, house, street, country, details } = targetAddress;
  const { porch, floor, apartment, instructions } = details || {};
  const styles = useStyles();
  const instructionCardStyle =
    record.instructions && record.instructions.length
      ? styles.orderInstructionCard
      : styles.cardStyle;
  const [supplierPhone, setSupplierPhone] = useState(null);
  const uiSettings = useUiSettings();

  const timeScheduledAt = new Date(record.orderedAt).toString().split(' ');
  const formatedTime = `${timeScheduledAt[2]} ${timeScheduledAt[1]} ${timeScheduledAt[4]}`;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchWithAuthorization(`${url}/address/${supplier.branchId}`)
      .then((response) => response.json())
      .then((data) => {
        setSupplierPhone(data.phoneNumber);
      })
      .catch((error) => {
        throw new Error(error);
      });
  }, [supplier.branchId]);

  return (
    <div className={styles.dropdownWrapper}>
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.address')}</div>
        <div>{`${street}, ${house || ''}`}</div>
        <div>
          {porch ? `${translate('ra.title.porch')}: ${porch} ` : ''}
          {floor ? `${translate('ra.title.floor')}: ${floor} ` : ''}
          {apartment ? `${translate('ra.title.apartment')}: ${apartment} ` : ''}
        </div>
        {`${country}, ${area}, ${locality}`}
        <ShowAddressOnMap targetAddress={targetAddress} />
        <b>
          <div>{`${instructions || ''}`}</div>
        </b>
      </Card>
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.order_staff_comment')}</div>
        {record.staffComments &&
          record.staffComments.map((comment) => {
            const formattedDate = formatDateTime(comment.createdAt);

            return (
              <Fragment key={comment.createdAt}>
                <div>
                  <b>{`${translate('ra.label.email')}: `}</b>
                  {comment.userEmail || ''}
                </div>
                <div className={styles.w200}>
                  <b>{`${translate('ra.label.role')}: `}</b>
                  {comment.userRole || ''}
                </div>
                <div className={styles.w200}>
                  <b>{`${translate('ra.label.added')}: `}</b>
                  {`${formattedDate.date} ${formattedDate.time}`}
                </div>
                <div className={styles.divider}>
                  <b>{`${translate('ra.label.comment')}: `}</b>
                  {comment.comment || ''}
                </div>
              </Fragment>
            );
          })}
      </Card>
      <Card className={instructionCardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.order_comment')}</div>
        <div className={styles.w200}>{`${record.instructions || ''}`}</div>
      </Card>
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.client_data')}</div>
        {`${client.firstName} ${client.lastName}`}
        <div>{client.phone}</div>
      </Card>
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.supplier_data')}</div>
        {supplier.supplierName}
        <br />
        {supplier.branchName}
        <br />
        {supplierPhone ? (
          <a href={` tel:${supplierPhone}`}>{supplierPhone}</a>
        ) : (
          <p>{translate('ra.message.no_number')}</p>
        )}
        {getIsExternalId(externalId) && (
          <>
            <br />
            {`Order ID: ${externalId.shortCode}`}
          </>
        )}
      </Card>
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.order_items')}</div>
        {getIsExternal(externalContent) ? (
          <ExternalOrderItems externalItems={externalContent.structuredContent} />
        ) : (
          <OrderItemsList orderItems={orderItems} />
        )}
      </Card>
      <Card className={styles.cardStyle}>
        <Button onClick={handleClickOpen} className={styles.buttonWithoutPadding}>
          <Card className={styles.courierCarStyle}>
            <div className={styles.cardTitle}>{translate('ra.title.block.courier_data')}</div>
            {courier && (
              <div className={styles.cardDetails}>
                {`${translate('ra.label.first_name')}: ${courier.firstName}`}
                <br />
                {`${translate('ra.label.last_name')}: ${courier.lastName}`}
                <br />
                {`${translate('ra.label.phone')}: ${courier.phone}`}
                <br />
                <span>{`${translate('ra.label.ordered_at')}: ${formatedTime}`}</span>
                {record.fiscalCheck && (
                  <CardMedia
                    image={`${baseUrl}${record.fiscalCheck}`}
                    component="img"
                    classes={{ root: styles.imgLighBoxInButton }}
                  />
                )}
              </div>
            )}
          </Card>
        </Button>
      </Card>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <div className={styles.flexEnd}>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          {record.fiscalCheck && (
            <CardMedia
              image={`${baseUrl}${record.fiscalCheck}`}
              component="img"
              classes={{ root: styles.imgLighBox }}
            />
          )}
        </DialogContent>
      </Dialog>
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.price')}</div>
        <div>
          <b>{`${translate('ra.title.order_price')}:`}</b>
          <span>{` ${itemsPrice / 100} ${uiSettings.currency} (${getOriginalPrice(orderItems)} ${uiSettings.currency})`}</span>
        </div>
        <div>
          <b>{`${translate('ra.title.delivery_price')}:`}</b>
          <span>{` ${deliveryPrice / 100} ${uiSettings.currency} (${deliveryDetails.price / 100} ${uiSettings.currency})`}</span>
        </div>
        <div>
          <b>{`${translate('ra.title.total_discounts')}:`}</b>
          <span>{` ${-totalDiscounts / 100} ${uiSettings.currency}`}</span>
        </div>
        <div>
          <b>{`${translate('ra.title.total_price')}:`}</b>
          <span>{` ${totalPrice / 100} ${uiSettings.currency}`}</span>
        </div>
      </Card>
      <TimeInfo record={record} />
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.payment_type')}</div>
        {payment.type}
      </Card>
    </div>
  );
};

export default OrderItems;
