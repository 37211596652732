import React from 'react';
import { FunctionField } from 'react-admin';
import { get } from 'lodash';
import { ColorPreview } from './style';

export default function ColorField({ label, source }) {
  return <FunctionField
    label={label}
    source={source}
    render={(record) => {
      const value = get(record, source);

      return (
        <ColorPreview color={value}>
          {value}
        </ColorPreview>
      );
    }}
  />;
}
