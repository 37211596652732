import React from 'react';
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  useCreateController,
} from 'react-admin';
import { TranslatableTextInput } from '../../../components/Translatable';

const validateRequired = required();

const VariantCreate = () => {
  const { ...createProps } = useCreateController();
  const formRecord = { supplierId: localStorage.getItem('supplierId'), itemsIds: [], items: [] };

  return (
    <Create record={{ ...formRecord, ...createProps.record }}>
      <SimpleForm>
        <TranslatableTextInput label="ra.label.name" source="name" validate={validateRequired} />
        <NumberInput
          label="ra.label.weight"
          source="weight"
          validate={validateRequired}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
        />
        <NumberInput
          label="ra.label.price"
          parse={(v) => v * 100 || ''}
          format={(v) => v / 100 || ''}
          source="price"
          validate={validateRequired}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
        />
        <ReferenceInput label="ra.label.goods" reference="goods" source="goodId">
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            sx={{ maxWidth: '218px', width: '100%' }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default VariantCreate;
