export const TimeStyles = {
  root: {
    textAlign: 'center',
    margin: '5px',

    '& input': {
      transition: '0.3s',
      textAlign: 'center',
      width: '70px',
      fontSize: '16px',
      padding: '5px 0',
      border: '1px solid transparent',
    },

    '& input:not([disabled]):hover': {
      background: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '3px',
    },

    '& input:focus': {
      background: 'rgba(0,0,0,0.05)',
      borderRadius: '3px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
};

export const style = {
  Main: {
    margin: '20px 0',
  },

  DayStyle: {
    display: 'flex',
    alignItems: 'center',
  },

  DayLabel: {
    textTransform: 'capitalize',
    width: '110px',
  },

};

export default style;
