import React from 'react';
import { TextField, Show, SimpleShowLayout } from 'react-admin';

const UIStaticPagesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ra.label.title" source="title" />
      <TextField label="ra.label.slug" source="slug" />
    </SimpleShowLayout>
  </Show>
);

export default UIStaticPagesShow;
