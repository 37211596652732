/* eslint-disable react/jsx-one-expression-per-line */
import React, { forwardRef, useMemo } from 'react';
import logo from '../../../../../../public/images/roomesrvice.logo.png';
import '../styles.css';

export const InvoiceReport = ({ reportData, dateRange, tableHeaders }) => {
  const generateDateRange = (date) => {
    const day = date?.toString().split(' ')[2];
    const month = date?.toString().split(' ')[1];
    const year = date?.toString().split(' ')[3];

    return `${day}. ${month} ${year}`;
  };

  const totals = useMemo(
    () => ({
      ...reportData[reportData.length - 1],
      orderOrderedAt: '',
    }),
    [reportData],
  );

  const bodyData = useMemo(() => {
    const filteredData = reportData.filter((item) => item.orderId !== 'Total:');
    const mappedData = filteredData.map((item) => ({
      orderId: item.orderId.slice(item.orderId.length - 7, item.orderId.length),
      orderSupplier: item.orderSupplier,
      orderOrderedAt: item.orderOrderedAt,
      clientFullName: item.clientFullName,
      clientPhone: item.clientPhone,
      clientAddress: item.clientAddress,
      orderTotalPrice: item.orderTotalPrice,
      orderComment: item.orderComment,
      orderReference: item.orderReference,
    }));

    return mappedData;
  }, [reportData]);

  return (
    <div className="pdf-main">
      {/* Header */}
      <div className="header">
        <div className="header-left-side">
          <div className="header-left-side-info">
            <span className="header-left-side-info_bold">
              Specifikation til kreditnota/afregningsnr. (selvfacturering)
            </span>
            <br />
            <span className="header-left-side-info_semi_bold">
              {/* For perioden 1. Februar til 28. Februar 2023 */}
              For perioden {generateDateRange(dateRange.from)} til {generateDateRange(dateRange.to)}
            </span>
          </div>
        </div>
        <div className="header-right-side">
          <div className="roomservice-logo">
            <img src={logo} alt="roomservice logo" />
          </div>
          <div>
            Room Service ApS <br />
            Brovej 20A <br />
            8800 Viborg
          </div>
          <div>
            Tlf. 7023 7026 <br />
            CVR: 3188 3172
          </div>
          <div>
            Web: https://www.roomservice.dk <br />
            E-mail: bogholderi@roomservice.dk
          </div>
          <div>
            {/* need to replace with current date */}
            {/* Dato: 28. Februar 2023 <br /> */}
            Dato: {generateDateRange(new Date())} <br />
          </div>
        </div>
      </div>

      <div className="table-container">
        <div className="table-head">
          <div className="table-row">
            {tableHeaders.map((header, idx) => {
              return (
                <div className={`cell-${idx}`} key={header.key}>
                  {header.label}
                </div>
              );
            })}
          </div>
        </div>
        <div className="table-body">
          {/* Map through orders to render the data */}
          {bodyData.map((order, orderIdx) => (
            <div
              className={`table-row ${orderIdx === bodyData.length - 1 ? '' : 'border-bottom'}`}
              key={order.orderId}
            >
              {Object.keys(order).map((item, itemIdx) => {
                return (
                  <div className={`cell-${itemIdx} body-cell-${itemIdx}`} key={item}>
                    {order[item]}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="table-footer">
          <div className="table-row">
            {tableHeaders.map((header, idx) => {
              return (
                <div className={`cell-${idx}`} key={header.key}>
                  {totals[header.key]}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const InvoiceReportToPrint = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <InvoiceReport {...props} />
    </div>
  );
});

export default InvoiceReportToPrint;
