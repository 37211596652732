import React from 'react';
import {
  NumberInput,
  required,
  TextInput,
  minValue,
  AutocompleteArrayInput,
} from 'react-admin';
import { authProviders } from '../../../../config/user.config';

const UISettingsForm = () => {
  return (
    <>
      <AutocompleteArrayInput
        label="ra.label.auth_providers"
        source="authProviders"
        choices={authProviders}
        name="authProviders"
        resettable
      />

      <TextInput
        label="ra.label.locale"
        source="locale"
        name="locale"
        resettable
        validate={required()}
      />

      <TextInput
        label="ra.label.currency"
        source="currency"
        name="currency"
        resettable
        validate={required()}
      />

      <TextInput
        label="ra.label.country"
        source="country"
        name="country"
        resettable
        validate={required()}
      />

      <NumberInput
        label="ra.label.cityVelocity"
        source="cityVelocity"
        resettable
        validate={minValue(0)}
        sx={{ width: '238px' }}
      />

      <NumberInput
        label="ra.label.bufferTime"
        source="bufferTime"
        resettable
        validate={minValue(0)}
        sx={{ width: '238px' }}
      />
    </>
  );
};

export default UISettingsForm;
