import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    whiteSpace: 'nowrap',
  },
  checkboxCol: {
    width: 100,
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: '15px',
  },
  tableHead: {
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
