import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const UIStaticPagesList = ({ ...props }) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.title" source="title" />
        <TextField label="ra.label.slug" source="slug" />
      </Datagrid>
    </List>
  );
};

export default UIStaticPagesList;
