import React from 'react';
import { Edit, required, SimpleForm, TextInput, useRecordContext } from 'react-admin';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();

const PaymentConfigTitle = () => {
  const record = useRecordContext();

  return <span>{`${record?.name}`}</span>;
};

const PaymentConfigEdit = () => (
  <Edit title={<PaymentConfigTitle />} actions={<CancelButton />}>
    <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
      <TextInput label="ra.label.name" source="name" validate={validateRequired} />
      <TextInput label="ra.label.terminal" source="terminalId" validate={validateRequired} />
    </SimpleForm>
  </Edit>
);

export default PaymentConfigEdit;
