import React from 'react';
import { TextField, Show, SimpleShowLayout, FunctionField } from 'react-admin';

const SettingsShow = () => (
  <Show>
    <SimpleShowLayout>
      <FunctionField
        source="authProviders"
        label="ra.label.auth_providers"
        render={(record) => <span>{record.authProviders.join(', ').toUpperCase()}</span>}
      />
      <TextField label="ra.label.locale" source="locale" />
      <TextField label="ra.label.currency" source="currency" />
      <TextField label="ra.label.country" source="country" />
    </SimpleShowLayout>
  </Show>
);

export default SettingsShow;
