import React from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';
import useStyles from './styles';

export default function SupplierArrayInput() {
  const styles = useStyles();

  return (
    <div>
      <ReferenceArrayInput
        source="supplierId"
        reference="supplier"
        label="ra.label.supplier"
        className={styles.inputFilter}
      >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
    </div>
  );
}
