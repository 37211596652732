import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  hideElements: {
    '& ul': {
      '& li': {
        borderBottom: 'unset',
        '& p': {
          display: 'none',
        },
      },
    },
  },
});

export default useStyles;
