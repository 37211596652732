import React from 'react';
import { Create, NumberInput, required, SimpleForm } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import { TranslatableTextInput } from '../../../components/Translatable';

const validateRequired = required();

const CategoryCreate = () => {
  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm redirect="show">
        <TranslatableTextInput label="ra.label.name" source="name" validate={validateRequired} />
        <NumberInput source="rank" />
      </SimpleForm>
    </Create>
  );
};

export default CategoryCreate;
