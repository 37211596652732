import React from 'react';
import { useRecordContext } from 'react-admin';
import formatDateTime from '../../utils/formatDateTime';
import { styles } from './style';

const OrderedAtField = (props) => {
  let { data } = props;
  const record = useRecordContext();
  if (!data) {
    data = record;
  }

  const { orderedAt, updatedAt } = data;
  const dateToFormat = orderedAt || updatedAt;
  const formattedDate = formatDateTime(dateToFormat);

  return (
    <div style={styles.wrapper}>
      {!formattedDate.today && <span style={styles.dateField}>{formattedDate.date}</span>}
      <span style={styles.timeField}>{formattedDate.time}</span>
    </div>
  );
};

export default OrderedAtField;
