import React from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragIconWrapper } from '../styles';

const DragHandle = (props) => {
  return (
    <DragIconWrapper {...props}>
      <DragHandleIcon />
    </DragIconWrapper>
  );
};

export default DragHandle;
