import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  Button,
  IconButton,
} from '@mui/material';
import { useTranslate } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';

import styles from './styles';

const GoodPriceEditDialog = ({ goodIndex, good, onClose }) => {
  const [inputValue, setInputValue] = useState(good.price / 100);
  const translate = useTranslate();

  const handleChange = ({ price }, value) => {
    price = Number(value);
    setInputValue(value);
  };

  return (
    <Dialog open={!!goodIndex} onClose={() => onClose((Number(inputValue)).toFixed(2))}>
      <DialogTitle>
        <div style={styles.goodCardDialogTitle}>
          <span>{translate('ra.action.price_edit')}</span>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => onClose((Number(inputValue)).toFixed(2))}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={styles.flexColumn}>
        <FormControl>
          <TextField
            type="number"
            variant="standard"
            label={translate('ra.label.price')}
            value={inputValue}
            onChange={(e) => {
              handleChange(good, e.target.value);
            }}
          />
        </FormControl>
        <FormControl style={styles.modalSaveBtn}>
          <Button
            disabled={!inputValue}
            variant="contained"
            color="primary"
            onClick={() => onClose((Number(inputValue)).toFixed(2))}
          >
            {translate('ra.action.save')}
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default GoodPriceEditDialog;
