import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const FreeDeliveryPerk = ({ type, handlePerkCheck, checkedPerks, disabled }) => {
  const [checked, setChecked] = useState(false);

  const currentPerk = checkedPerks && checkedPerks.find((perkObject) => perkObject.type === type);

  useEffect(() => {
    setChecked(!!currentPerk);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const e = {
      target: {
        value: type,
        checked,
      },
    };
    const perkOptions = {
      type,
    };

    handlePerkCheck(e, perkOptions);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() => setChecked(!checked)}
            color="primary"
            disabled={disabled}
          />
        }
        label={type}
      />
    </div>
  );
};

export default FreeDeliveryPerk;
