import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import {
  DEFAULT_PAYMENT_METHODS,
  PAYMENT_METHODS_LABELS,
  PAYMENT_METHODS,
} from '../../config/payment.config';
import styles from './styles';

const PaymentMethodBase = (props) => {
  const {
    label,
    source,
    input: { value, onChange },
    disabled,
  } = props;
  const [paymentTypes, setPaymentTypes] = useState(DEFAULT_PAYMENT_METHODS);
  const translate = useTranslate();

  useEffect(() => {
    if (value && Object.keys(value).length) {
      setPaymentTypes(JSON.parse(value));
    }
  }, [value]);

  const handleChange = (e) => {
    const { checked, value: inputValue } = e.target;
    onChange(
      JSON.stringify({
        ...paymentTypes,
        [inputValue]: checked,
      }),
    );
  };

  return (
    <div style={styles.Wrapper}>
      <span style={styles.Label}>{translate(label) || source}</span>
      {paymentTypes &&
        Object.keys(paymentTypes).map((paymentType) => {
          if ([
            PAYMENT_METHODS.CARD,
            PAYMENT_METHODS.CASH,
            PAYMENT_METHODS.INVOICE,
            PAYMENT_METHODS.VIPPS
          ].includes(paymentType)) {
            return (
              <FormControlLabel
                key={paymentType}
                control={
                  <Checkbox
                    checked={paymentTypes[paymentType]}
                    onChange={(e) => handleChange(e)}
                    color="primary"
                    value={paymentType}
                    disabled={disabled}
                  />
                }
                label={PAYMENT_METHODS_LABELS[paymentType]}
              />
            );
          }

          return null;
        })}
    </div>
  );
};

export const PaymentMethodInput = ({ source, ...props }) => {
  const input = useController({ name: source });

  return <PaymentMethodBase {...props} input={input.field} source={source} />;
};

export const PaymentMethodField = ({ source, label, ...props }) => {
  const record = useRecordContext();

  if (!record || !record[source]) {
    return `${label || source} not set`;
  }

  const input = {
    value: record[source],
    onChange: () => {},
  };

  return <PaymentMethodBase {...props} input={input} label={label} source={source} disabled />;
};
