export const SUPPLIER_REPORT_FIELDS = {
  ORDER_ID: 'orderId',
  ORDER_SUPPLIER: 'orderSupplier',
  ORDER_BRANCH: 'orderBranch',
  CLIENT_FULL_NAME: 'clientFullName',
  CLIENT_PHONE: 'clientPhone',
  CLIENT_ADDRESS: 'clientAddress',
  COURIER_FULL_NAME: 'courierFullName',
  COURIER_COST: 'courierCost',
  ORDER_PAYMENT_TYPE: 'orderPaymentType',
  ORDER_ITEMS_PRICE: 'orderItemsPrice',
  ORDER_DELIVERY_PRICE: 'orderDeliveryPrice',
  ORDER_TOTAL_PRICE: 'orderTotalPrice',
  ORDER_ORDERED_AT: 'orderOrderedAt',
  ORDER_DELIVERED_AT: 'orderDeliveredAt',
  ORDER_ORIGINAL_PRICE: 'orderOriginalPrice',
  VALIDATION_DURATION: 'validationDuration',
  CONFIRMATION_DURATION: 'confirmationDuration',
  COURIER_ASSIGNMENT_DURATION: 'courierAssignmentDuration',
  PREPARATION_DURATION: 'preparationDuration',
  COURIER_ARRIVAL_DURATION: 'courierArrivalDuration',
  COURIER_WAIT_DURATION: 'courierWaitDuration',
  RESTAURANT_WAIT_DURATION: 'restaurantWaitDuration',
  DELIVERY_DURATION: 'deliveryDuration',
  TOTAL_DURATION: 'totalDuration',
  DISTANCE: 'distance',
  REFUNDS: 'refunds',
  REFUNDS_COMMENTS: 'refundsComments',
  REFUNDS_CULPRITS: 'refundsCulprits',
  IS_MOBILE: 'isMobile',
};

export const COURIER_REPORT_FIELDS = {
  ORDER_ID: 'orderId',
  ORDER_SUPPLIER: 'orderSupplier',
  COURIER_ID: 'courierId',
  COURIER_FIRST_NAME: 'courierFirstName',
  COURIER_LAST_NAME: 'courierLastName',
  COURIER_COST: 'courierCost',
  CLIENT_ADDRESS: 'clientAddress',
  ORDER_PAYMENT_TYPE: 'orderPaymentType',
  ORDER_DELIVERY_PRICE: 'orderDeliveryPrice',
  ORDER_ORDERED_AT: 'orderOrderedAt',
  ORDER_ORIGINAL_PRICE: 'orderOriginalPrice',
  ORDER_ITEMS_PRICE: 'orderItemsPrice',
  ORDER_DELIVERY_STATUS: 'deliveryStatus',
  ORDER_TOTAL_PRICE: 'orderTotalPrice',
  ORDER_BRANCH: 'orderBranch',
  DISTANCE: 'distance',
};

export const REFUNDED_ORDERS_REPORT_FIELDS = {
  ORDER_ID: 'orderId',
  ORDER_ORDERED_AT: 'orderOrderedAt',
  ORDER_SUPPLIER: 'orderSupplier',
  ORDER_BRANCH: 'orderBranch',
  COURIER_FULL_NAME: 'courierFullName',
  CLIENT_FULL_NAME: 'clientFullName',
  ORDER_PAYMENT_TYPE: 'orderPaymentType',
  ORDER_DELIVERY_PRICE: 'orderDeliveryPrice',
  ORDER_TOTAL_PRICE: 'orderTotalPrice',
  REFUNDS: 'refunds',
  REFUNDS_COMMENTS: 'refundsComments',
  REFUNDS_CULPRITS: 'refundsCulprits',
};

export const INVOICE_REPORT_FIELDS = {
  ORDER_ID: 'orderId',
  ORDER_ORDERED_AT: 'orderOrderedAt',
  ORDER_SUPPLIER: 'orderSupplier',
  CLIENT_FULL_NAME: 'clientFullName',
  CLIENT_PHONE: 'clientPhone',
  CLIENT_ADDRESS: 'clientAddress',
  ORDER_TOTAL_PRICE: 'orderTotalPrice',
  ORDER_COMMENT: 'orderComment',
  ORDER_REFERENCE: 'orderReference',
};

export const DEFAULT_REPORT_FIELDS = {
  SUPPLIER: {
    [SUPPLIER_REPORT_FIELDS.ORDER_ID]: true,
    [SUPPLIER_REPORT_FIELDS.ORDER_SUPPLIER]: true,
    [SUPPLIER_REPORT_FIELDS.ORDER_BRANCH]: true,
    [SUPPLIER_REPORT_FIELDS.ORDER_ORDERED_AT]: true,
    [SUPPLIER_REPORT_FIELDS.ORDER_DELIVERED_AT]: true,
    [SUPPLIER_REPORT_FIELDS.CLIENT_FULL_NAME]: true,
    [SUPPLIER_REPORT_FIELDS.CLIENT_PHONE]: true,
    [SUPPLIER_REPORT_FIELDS.CLIENT_ADDRESS]: true,
    [SUPPLIER_REPORT_FIELDS.ORDER_PAYMENT_TYPE]: true,
    [SUPPLIER_REPORT_FIELDS.ORDER_ORIGINAL_PRICE]: true,
    [SUPPLIER_REPORT_FIELDS.ORDER_ITEMS_PRICE]: true,
    [SUPPLIER_REPORT_FIELDS.ORDER_DELIVERY_PRICE]: true,
    [SUPPLIER_REPORT_FIELDS.ORDER_TOTAL_PRICE]: true,
    [SUPPLIER_REPORT_FIELDS.REFUNDS]: true,
    [SUPPLIER_REPORT_FIELDS.REFUNDS_COMMENTS]: true,
    [SUPPLIER_REPORT_FIELDS.REFUNDS_CULPRITS]: true,
    [SUPPLIER_REPORT_FIELDS.COURIER_FULL_NAME]: true,
    [SUPPLIER_REPORT_FIELDS.VALIDATION_DURATION]: true,
    [SUPPLIER_REPORT_FIELDS.CONFIRMATION_DURATION]: true,
    [SUPPLIER_REPORT_FIELDS.COURIER_ASSIGNMENT_DURATION]: true,
    [SUPPLIER_REPORT_FIELDS.PREPARATION_DURATION]: true,
    [SUPPLIER_REPORT_FIELDS.COURIER_ARRIVAL_DURATION]: true,
    [SUPPLIER_REPORT_FIELDS.COURIER_WAIT_DURATION]: true,
    [SUPPLIER_REPORT_FIELDS.RESTAURANT_WAIT_DURATION]: true,
    [SUPPLIER_REPORT_FIELDS.DELIVERY_DURATION]: true,
    [SUPPLIER_REPORT_FIELDS.TOTAL_DURATION]: true,
    [SUPPLIER_REPORT_FIELDS.DISTANCE]: true,
    [SUPPLIER_REPORT_FIELDS.COURIER_COST]: true,
    [SUPPLIER_REPORT_FIELDS.IS_MOBILE]: true,
  },
  COURIER: {
    [COURIER_REPORT_FIELDS.ORDER_ID]: true,
    [COURIER_REPORT_FIELDS.ORDER_SUPPLIER]: true,
    [COURIER_REPORT_FIELDS.COURIER_ID]: true,
    [COURIER_REPORT_FIELDS.COURIER_FIRST_NAME]: true,
    [COURIER_REPORT_FIELDS.COURIER_LAST_NAME]: true,
    [COURIER_REPORT_FIELDS.CLIENT_ADDRESS]: true,
    [COURIER_REPORT_FIELDS.ORDER_PAYMENT_TYPE]: true,
    [COURIER_REPORT_FIELDS.ORDER_ORIGINAL_PRICE]: true,
    [COURIER_REPORT_FIELDS.ORDER_ITEMS_PRICE]: true,
    [COURIER_REPORT_FIELDS.ORDER_DELIVERY_PRICE]: true,
    [COURIER_REPORT_FIELDS.ORDER_ORDERED_AT]: true,
    [COURIER_REPORT_FIELDS.ORDER_DELIVERY_STATUS]: true,
    [COURIER_REPORT_FIELDS.ORDER_TOTAL_PRICE]: true,
    [COURIER_REPORT_FIELDS.ORDER_BRANCH]: true,
    [COURIER_REPORT_FIELDS.COURIER_COST]: true,
    [COURIER_REPORT_FIELDS.DISTANCE]: true,
  },
  REFUNDED_ORDERS: {
    [REFUNDED_ORDERS_REPORT_FIELDS.ORDER_ID]: true,
    [REFUNDED_ORDERS_REPORT_FIELDS.ORDER_ORDERED_AT]: true,
    [REFUNDED_ORDERS_REPORT_FIELDS.ORDER_SUPPLIER]: true,
    [REFUNDED_ORDERS_REPORT_FIELDS.ORDER_BRANCH]: true,
    [REFUNDED_ORDERS_REPORT_FIELDS.COURIER_FULL_NAME]: true,
    [REFUNDED_ORDERS_REPORT_FIELDS.CLIENT_FULL_NAME]: true,
    [REFUNDED_ORDERS_REPORT_FIELDS.ORDER_PAYMENT_TYPE]: true,
    [REFUNDED_ORDERS_REPORT_FIELDS.ORDER_DELIVERY_PRICE]: true,
    [REFUNDED_ORDERS_REPORT_FIELDS.ORDER_TOTAL_PRICE]: true,
    [SUPPLIER_REPORT_FIELDS.REFUNDS]: true,
    [SUPPLIER_REPORT_FIELDS.REFUNDS_COMMENTS]: true,
    [SUPPLIER_REPORT_FIELDS.REFUNDS_CULPRITS]: true,
  },
  INVOICE: {
    [INVOICE_REPORT_FIELDS.ORDER_ID]: true,
    [INVOICE_REPORT_FIELDS.ORDER_ORDERED_AT]: true,
    [INVOICE_REPORT_FIELDS.ORDER_SUPPLIER]: true,
    [INVOICE_REPORT_FIELDS.CLIENT_FULL_NAME]: true,
    [INVOICE_REPORT_FIELDS.CLIENT_PHONE]: true,
    [INVOICE_REPORT_FIELDS.CLIENT_ADDRESS]: true,
    [INVOICE_REPORT_FIELDS.ORDER_TOTAL_PRICE]: true,
    [INVOICE_REPORT_FIELDS.ORDER_COMMENT]: true,
    [INVOICE_REPORT_FIELDS.ORDER_REFERENCE]: true,
  },
};

export default DEFAULT_REPORT_FIELDS;
