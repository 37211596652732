import React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  useCreateController,
} from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import { ALL_USER_ROLES_NAMES } from '../../../config/user.config';

const validateRequired = required();

const CompanyCreate = () => {
  const { ...createProps } = useCreateController();

  const supplierFromStorage = localStorage.getItem('supplierId');
  const roleFromLocalStorage = localStorage.getItem('role');
  const defaultFormRecord = { isActive: true };

  const formRecord =
    roleFromLocalStorage === ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN
      ? { supplierId: supplierFromStorage, ...defaultFormRecord }
      : defaultFormRecord;

  return (
    <Create
      redirect="list"
      record={{ ...formRecord, ...createProps.record }}
      actions={<CancelButton />}
    >
      <SimpleForm>
        <TextInput label="ra.label.name" source="legalName" validate={validateRequired} />
        <TextInput label="ra.label.phone" source="phoneNumber" validate={validateRequired} />
        <TextInput label="ra.label.email" source="email" validate={validateRequired} />
        <TextInput label="ra.label.contract" source="contractNumber" validate={validateRequired} />
      </SimpleForm>
    </Create>
  );
};

export default CompanyCreate;
