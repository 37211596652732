import { createStyles, makeStyles } from '@mui/styles';

export const style = {
  notesContainer: {
    color: '#555555',
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '100px',
    alignItems: 'center',
  },
};

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginRight: '7px',
      minWidth: '25px',
      '& > *': {
        minWidth: '25px',
      },
    },
  }));
