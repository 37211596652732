import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  required,
  Edit,
  TextInput,
  SimpleForm,
  useRedirect,
  useUpdate,
  regex,
  useEditController,
} from 'react-admin';
import { Box, Tab, Tabs } from '@mui/material';
import Editor from '@monaco-editor/react';
import CancelButton from '../../../components/CancelButton';
import url from '../../../config/connection';
import {
  fetchWithAuthorization,
  putWithAuthorization,
} from '../../../utils/fetchWithAuthorization';
import { a11yProps } from '../../../utils/a11yProps';
import { TabPanel } from '../../../components/MuiTabPanel';
import StaticsEditActions from '../Actions/StaticsEditAction';
import { PreviewFrame } from '../styles';
import { PreviewTemplate } from '../consts';
import { useOrganizationLocales } from '../../../hooks/useOrganizationLocales';

const validateRequired = required();
const pageNameRegex = regex(
  /^(?!-)[a-z]+(?:-[a-z]+)*(?<!-)$/,
  'String should contain only lowercase letters or dashes("-"). Example: abc or abc-def',
);
const pageNameValidator = [validateRequired, pageNameRegex];

const UIStaticPagesEdit = () => {
  const [currentLocale, setCurrentLocale] = useState('en'); // should be one default locale which is always available
  const [pageContentsByLocale, setPageContentsByLocale] = useState({});
  const localeList = useOrganizationLocales();

  const [update] = useUpdate();
  const redirect = useRedirect();
  const controller = useEditController();

  const previewFrameRef = useRef();

  const recordId = controller?.record?.id;
  const slug = controller?.record?.slug;

  const pageSave = (data) => {
    Object.entries(pageContentsByLocale).map(([locale, file]) => {
      return putWithAuthorization(`${url}/ui/static/${data.slug}/${locale}`, {
        body: JSON.stringify({
          file,
        }),
      });
    });

    update(
      'ui/static',
      { id: recordId, data, previousData: controller.record },
      {
        onSuccess: () => {
          redirect('list', 'ui/static');
        },
      },
    );
  };

  useEffect(() => {
    const getLocalesBody = async () => {
      const pagesByLocale = {};
      await Promise.all(
        localeList.map(async (locale) => {
          const response = await fetchWithAuthorization(`${url}/ui/static/${slug}/${locale}`);
          const data = await response.text();

          pagesByLocale[locale] = data.includes('statusCode') ? '' : data;
        }),
      );

      setPageContentsByLocale(pagesByLocale);
    };

    getLocalesBody();
  }, [localeList, slug]);

  const handleChange = (_event, newValue) => {
    setCurrentLocale(newValue);
  };

  const activeDocument = pageContentsByLocale[currentLocale] ?? '';
  useEffect(() => {
    if (!previewFrameRef.current) {
      return;
    }

    previewFrameRef.current.contentDocument.body.innerHTML = activeDocument;
    setTimeout(() => {
      previewFrameRef.current.style.height =
        `${previewFrameRef.current.contentDocument.body?.clientHeight}px`;
    });
  }, [activeDocument]);

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm onSubmit={pageSave} toolbar={<StaticsEditActions />}>
        <TextInput label="ra.label.title" source="title" resettable validate={validateRequired} />
        <TextInput label="ra.label.slug" source="slug" resettable validate={pageNameValidator} />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentLocale} onChange={handleChange} aria-label="locales tabs">
              {localeList.map((locale, idx) => {
                return (
                  <Tab
                    value={locale}
                    label={locale}
                    key={locale}
                    sx={{ width: '125px' }}
                    {...a11yProps(idx)}
                  />
                );
              })}
            </Tabs>
          </Box>
          <TabPanel value={currentLocale} index={currentLocale}>
            {/* TODO: Make Monaco-Editor compatible with react-hook-form */}
            <Editor
              language="html"
              value={activeDocument}
              height={500}
              onChange={(value) => {
                setPageContentsByLocale((localeDefaults) => ({
                  ...localeDefaults,
                  [currentLocale]: value,
                }));
              }}
            />
          </TabPanel>

          <PreviewFrame
            title={`preview-${currentLocale}`}
            srcDoc={PreviewTemplate}
            ref={previewFrameRef}
          />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default UIStaticPagesEdit;
