import React from 'react';
import { FunctionField, ReferenceField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const UserAddressShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="ra.tab.summary">
        <TextField label="ra.label.contact_name" source="contactName" />
        <FunctionField
          label="ra.label.address"
          render={({ street, area, house, country }) => {
            return [street, area, house, country].filter(Boolean).join(', ');
          }}
        />
        <TextField label="ra.label.phone" source="phone" />
        <ReferenceField label="ra.label.user" source="userId" reference="user" linkType="show">
          <TextField source="username" />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default UserAddressShow;
