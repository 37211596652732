export const PAYMENT_METHODS = {
  CARD: 'CARD',
  CASH: 'CASH',
  INVOICE: 'INVOICE',
  VIPPS: 'VIPPS',
};

export const PAYMENT_METHODS_LABELS = {
  CARD: 'Card',
  CASH: 'Cash',
  INVOICE: 'Invoice',
  VIPPS: 'Vipps',
};

export const DEFAULT_PAYMENT_METHODS = {
  [PAYMENT_METHODS.CARD]: true,
  [PAYMENT_METHODS.CASH]: true,
  [PAYMENT_METHODS.INVOICE]: true,
  [PAYMENT_METHODS.VIPPS]: true,
};

export default PAYMENT_METHODS;
