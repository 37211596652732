import React from 'react';
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceField,
  ReferenceManyField,
} from 'react-admin';
import AddResourceButton from '../../../components/AddResourceButton';
import useStyles from '../../style';

const StoreShow = () => {
  const styles = useStyles();

  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TextField label="ra.label.name" source="name" />
          <TextField label="ra.label.label" source="label" />
          <TextField label="ra.label.slug" source="slug" />
        </Tab>
        <Tab label="resources.supplier.name">
          <ReferenceManyField
            reference="store-supplier"
            target="storeId"
            addLabel={false}
            className={styles.marginBottom}
          >
            <Datagrid rowClick={(id) => `/store-supplier/${id}/edit`}>
              <ReferenceField
                label="ra.label.name"
                source="supplierId"
                reference="supplier"
                linkType="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="supplierRank" label="ra.label.rank" />
            </Datagrid>
          </ReferenceManyField>
          <AddResourceButton
            label="ra.action.add_supplier"
            to="/store-supplier/create"
            source="storeId"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default StoreShow;
