import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useInput, useTranslate } from 'react-admin';
import { defaultValues, UNITS } from '../../constants';
import { styles } from './styles';

const ServingSizeInput = (props) => {
  const translate = useTranslate();
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useInput(props);

  const [localValue, setLocalValue] = useState(defaultValues);

  useEffect(() => {
    if (field.value) {
      setLocalValue(JSON.parse(field.value));
    }
  }, [field.value]);

  const handleChange = useCallback(
    (e, key) => {
      const valueToSave = {
        ...localValue,
        [key]: e.target.value,
      };

      field.onChange(JSON.stringify(valueToSave));
    },
    [localValue, field],
  );

  return (
    <div>
      <TextField
        {...field}
        error={(isTouched || isSubmitted) && invalid}
        helperText={(isTouched || isSubmitted) && invalid ? error : ''}
        label={translate('ra.label.serving_size')}
        style={styles.servingSizeField}
        onChange={(e) => handleChange(e, 'size')}
        value={localValue.size}
        sx={{ maxWidth: '150px', width: '100%' }}
        variant="standard"
        id="size"
      />
      <TextField
        {...field}
        error={(isTouched || isSubmitted) && invalid}
        helperText={(isTouched || isSubmitted) && invalid ? error : ''}
        select
        label={translate('ra.label.unit')}
        style={styles.unitField}
        value={localValue.unit}
        onChange={(e) => handleChange(e, 'unit')}
        SelectProps={{
          MenuProps: {
            style: styles.menu,
          },
        }}
        variant="standard"
        id="unit"
      >
        {Object.keys(UNITS).map((unit) => (
          <MenuItem key={unit} value={UNITS[unit]}>
            {UNITS[unit]}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default ServingSizeInput;
