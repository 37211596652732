import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { PreviewIframe } from './style';

const previewOrigin = window._env_.REACT_APP_MARKETPLACE_URL;

export default function MarketplacePreview({ changes, styles }) {
  /**
   * @type {React.MutableRefObject<HTMLIFrameElement>}
   */
  const previewRef = useRef();
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    if (!previewRef.current || !didLoad) {
      return;
    }

    previewRef.current.contentWindow.postMessage(
      { type: 'admin_preview', ...changes },
      previewOrigin,
    );
  }, [changes, didLoad]);

  useEffect(() => {
    if (!previewRef.current || !didLoad) {
      return;
    }

    previewRef.current.contentWindow.postMessage(
      { type: 'admin_inject_styles', styles },
      previewOrigin,
    );
  }, [styles, didLoad]);

  return (
    <PreviewIframe
      onLoad={() => setDidLoad(true)}
      title="Preview"
      src={previewOrigin}
      ref={(iframeElement) => { previewRef.current = iframeElement; }}
    />
  );
}
