export const FooterSchema = {
  $schema: 'https://json-schema.org/draft/07/schema',
  type: 'object',
  $defs: {
    'footer-schema': {
      type: 'array',
      items: {
        type: 'array',
        items: {
          type: 'object',
          required: [
            'title',
          ],
          properties: {
            title: {
              type: 'string',
            },
            orientation: {
              type: 'string',
              enum: [
                'horizontal',
                'vertical',
              ],
            },
            customClass: {
              type: 'string',
            },
            options: {
              type: 'array',
              items: {
                type: 'object',
                oneOf: [
                  {
                    required: [
                      'title',
                    ],
                  },
                  {
                    required: [
                      'image',
                    ],
                  },
                  {
                    required: [
                      'static',
                    ],
                  },
                ],
                properties: {
                  title: {
                    type: 'string',
                  },
                  image: {
                    type: 'string',
                  },
                  imageHeight: {
                    type: 'number',
                  },
                  url: {
                    type: 'string',
                  },
                  external: {
                    type: 'boolean',
                  },
                  static: {
                    type: 'string',
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  properties: {
    desktop: {
      $ref: '#/$defs/footer-schema',
    },
    mobile: {
      $ref: '#/$defs/footer-schema',
    },
  },
};
