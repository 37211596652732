import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const validateRequired = required();

const PromoCodeCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput source="title" name="title" validate={validateRequired} />
        <ReferenceInput reference="discount" source="discount">
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            label="ra.label.discount"
            sx={{ width: '206px' }}
          />
        </ReferenceInput>
        <NumberInput source="maxUsage" name="maxUsage" />
        <NumberInput source="maxUsagePerUser" name="maxUsagePerUser" />
        <BooleanInput defaultValue source="isActive" name="isActive" />
      </SimpleForm>
    </Create>
  );
};

export default PromoCodeCreate;
