import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles from './styles';
import { useUiSettings } from '../../../../hooks/useUiSettings';

const ExternalItem = ({ label, price, sublines }) => {
  const isSublines = !!(sublines && sublines.length);

  const styles = useStyles();
  const [expanded, setExpanded] = useState(isSublines);
  const uiSettings = useUiSettings();

  return (
    <Accordion
      key={label}
      expanded={expanded}
      onClick={() => isSublines && setExpanded((prevState) => !prevState)}
    >
      <AccordionSummary expandIcon={isSublines ? <ExpandMoreIcon /> : null}>
        {`${label} | ${price / 100} ${uiSettings.currency}`}
      </AccordionSummary>
      {isSublines && (
        <AccordionDetails className={styles.sublines}>
          {sublines.map(({ label: sublineLabel, price: sublinePrice }, ind) => {
            const optionDivider = ind < sublines.length - 1 ? ',' : '';
            const optionPrice = sublinePrice ? `| ${sublinePrice / 100} ${uiSettings.currency} ` : '';

            return <div key={sublineLabel}>{`${sublineLabel} ${optionPrice}${optionDivider}`}</div>;
          })}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const ExternalOrderItems = ({ externalItems }) => externalItems.map(ExternalItem);

export default ExternalOrderItems;
