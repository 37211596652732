import { useEffect, useRef } from 'react';

/**
 * Custom hook that executes a callback function repeatedly with a specified delay.
 *
 * @param {Function} callback - The function to be executed repeatedly.
 * @param {number|null} delay - The delay (in milliseconds) between each execution of the callback function. If set to null, the interval will be cleared.
 */
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
};
