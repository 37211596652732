import React from 'react';
import {
  NumberField,
  required,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import S3FileField from '../../../components/S3FileUploader/Field';
import { TranslatableTextField } from '../../../components/Translatable';
import { baseUrl } from '../../../config/connection';

const validateRequired = required();

const CategoryTitle = () => {
  const record = useRecordContext();

  return <span>{`${record?.name}`}</span>;
};

const CategoryShow = () => (
  <Show title={<CategoryTitle />}>
    <TabbedShowLayout>
      <Tab label="ra.tab.summary">
        <TranslatableTextField label="ra.label.name" source="name" validate={validateRequired} />
        <TextField label="ra.label.slug" source="slug" />
        <NumberField source="rank" />
        <S3FileField apiRoot={baseUrl} source="cover" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default CategoryShow;
