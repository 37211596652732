import * as React from 'react';
import { SvgIcon } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { style, useStyles } from './style';
import notificationIcon from './notificationIcon';
import emptyIcon from './emptyIcon';

const NotificationColumn = () => {
  const record = useRecordContext();
  const isStaffComment = record.staffComments && record.staffComments.length;
  const isClientComment = record.instructions && record.instructions.length;
  const classes = useStyles();

  const notesType = [];
  let icon = emptyIcon;

  if (isStaffComment || isClientComment) {
    icon = notificationIcon;
  }

  if (isClientComment) {
    notesType.push('CLIENT');
  }

  if (isStaffComment) {
    notesType.push('STAFF');
  }

  return (
    <div style={style.notesContainer}>
      <SvgIcon className={classes.root} size="small">
        {icon()}
      </SvgIcon>
      {notesType.join(', ')}
    </div>
  );
};

export default NotificationColumn;
