import React from 'react';
import { Edit, NumberInput, required, SimpleForm } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { TranslatableTextInput } from '../../../components/Translatable';
import ResourceTitle from '../../../components/ResourceTitle';

const validateRequired = required();

const VariantEdit = () => {
  const navigate = useNavigate();

  const redirectToVariantsList = (basePath, id, { goodId }) => {
    navigate(`/goods/${goodId}/show/1`);
  };

  return (
    <Edit title={<ResourceTitle field="name" />}>
      <SimpleForm redirect={redirectToVariantsList}>
        <TranslatableTextInput label="ra.label.name" source="name" validate={validateRequired} />
        <NumberInput
          label="ra.label.weight"
          source="weight"
          validate={validateRequired}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
        />
        <NumberInput
          label="ra.label.price"
          parse={(v) => v * 100 || ''}
          format={(v) => v / 100 || ''}
          source="price"
          validate={validateRequired}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default VariantEdit;
