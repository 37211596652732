import React, { useState } from 'react';
import {
  SimpleForm,
  Edit,
  required,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  TextInput,
  number,
  minValue,
  useRefresh,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import ResourceTitle from '../../../components/ResourceTitle';
import SupplierAutocomplete from '../../../components/SupplierAutocomplete';
import { TranslatableTextInput } from '../../../components/Translatable';
import { isSuperAdmin } from '../../../utils/roles';

import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();
const validateStock = [validateRequired, number()];

const OptionFormDataConsumer = ({ formData, ...rest }) => {
  const [isFreeOption, setIsFreeOption] = useState(formData.isFreeOption);

  const handlePriceChange = (e) => {
    setIsFreeOption(e.target.checked);
  };

  return (
    <>
      <BooleanInput label="ra.label.available" source="isActive" />
      <BooleanInput
        label="ra.label.is_free_option"
        source="isFreeOption"
        onChange={(e) => handlePriceChange(e)}
        {...rest}
      />
      <TranslatableTextInput label="ra.label.name" source="name" validate={validateRequired} />
      <div
        style={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <NumberInput
          parse={(v) => v * 100}
          format={(v) => v / 100}
          label="ra.label.price"
          source="price"
          validate={validateStock}
          {...rest}
        />
        <NumberInput
          label="ra.label.maxQuantity"
          source="maxOptions"
          min={0}
          validate={validateStock}
          {...rest}
        />
        {isFreeOption && (
          <>
            <NumberInput
              label="ra.label.maxFreeQuantity"
              source="maxFreeOptions"
              min={0}
              validate={validateStock}
              {...rest}
            />
            <p
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            >
              *Price applies after max free quantity is reached
            </p>
          </>
        )}
      </div>
      {isSuperAdmin() && <SupplierAutocomplete />}
      <TextInput label="ra.label.external" source="externalId" />
    </>
  );
};

const OptionEdit = () => {
  return (
    <Edit title={<ResourceTitle field="name" />} actions={<CancelButton />} redirect="/option">
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <FormDataConsumer>
          {(formDataProps) => {
            return <OptionFormDataConsumer {...formDataProps} />;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default OptionEdit;
