import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';
import { useRecordContext, useTranslate } from 'react-admin';
import styles from './style';
import scheduleDefault from './defaultValue';
import Day from './Day';

const WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const Schedule = ({ disabled, isShow, setValue, ...props }) => {
  const {
    field: { value, onChange },
  } = props;

  const [localValue, setLocalValue] = useState(scheduleDefault);
  const translate = useTranslate();

  useEffect(() => {
    if (value) {
      if (setValue) {
        setValue('schedule', value);
      }
      if (typeof value !== 'string') {
        if (isShow) {
          setLocalValue(value);
        }

        onChange(JSON.stringify(value));
      } else {
        setLocalValue(JSON.parse(value));
      }
    }
  }, [isShow, onChange, value, setValue]);

  const onScheduleChange = ({ day, field, newVal }) => {
    const newLocalValue = {
      ...localValue,
      [day]: {
        ...localValue[day],
        [field]: newVal,
      },
    };

    onChange(JSON.stringify(newLocalValue));
  };

  return localValue ? (
    <div style={styles.Main}>
      <Typography variant="h6">{translate('ra.label.schedule')}</Typography>
      {WEEK.map((day) => (
        <Day
          key={`${day}`}
          day={day}
          data={localValue[day]}
          onChange={onScheduleChange}
          disabled={disabled}
        >
          {translate(`ra.week_day.${day}`)}
        </Day>
      ))}
    </div>
  ) : null;
};

export const ScheduleInput = ({ source, ...props }) => {
  const input = useController({ name: source });

  return <Schedule {...input} {...props} />;
};

export const ScheduleField = ({ source }) => {
  const record = useRecordContext();

  if (!record || !record[source]) {
    return 'No Schedule Provided';
  }

  const input = {
    value: record[source],
    onChange: () => {},
  };

  return <Schedule field={input} isShow disabled />;
};
