import React, { useMemo, useState } from 'react';
import { Paper, Typography, Button, IconButton } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { useTranslate } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { staticUrl } from '../config/connection';
import styles from './styles';

import GoodPriceEditDialog from './GoodPriceEditDialog';
import ScheduleEditDialog from './ScheduleEditDialog';
import { CURRENCY_NAME } from '../config/currency.config';

const useStyles = makeStyles({
  '@keyframes appear': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  selectedIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '55px',
    animation: '$appear 0.1s ease-in',
  },
});

const GoodCard = ({
  good,
  onClick,
  discountName,
  actions,
  className,
  dragStyle = {},
  selected = false,
  goodIdx,
  onAvailabilityChange,
  section,
  setSections,
  setIsSavedChanges,
  shouldDisplayActionButtons = false,
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isScheduleEditDialogOpen, setIsScheduleEditDialogOpen] = useState(false);
  const translate = useTranslate();

  const muiStyles = useStyles();

  const getImageUrl = () => {
    const url = Array.isArray(good.cover) ? good.cover[0]?.url : good.cover;
    const fullUrl = url.startsWith('http') ? url : `${staticUrl}/${url}`;

    return `url(${fullUrl})`;
  };

  const isActiveInStopList = useMemo(() => good.stopList?.isActive, [good.stopList]);

  const changeAvailabilityHandler = () => {
    onAvailabilityChange(!isActiveInStopList, goodIdx, good.id);
  };

  const handleEditOpen = () => {
    setIsEditOpen(good.id);
  };

  const handleScheduleEditOpen = () => {
    setIsScheduleEditDialogOpen(good.id);
  };

  const updateGood = (goodField, newFieldValue) => {
    const newSection = JSON.parse(JSON.stringify(section));

    const synchronizedGood = newSection.goods?.find(({ id }) => id === good.id);

    synchronizedGood[goodField] = newFieldValue;

    setSections((prev) => {
      const index = prev.findIndex((otherSection) => otherSection.id === section.id);
      const copy = [...prev];

      copy[index] = newSection;

      const sectionsWithItems = prev.filter((item) => item.goods.length);

      const allGoods = sectionsWithItems.reduce((allGoodsItems, category) => {
        const mappedGoods = category.goods.map((goodItem) => {
          return {
            good: goodItem,
          };
        });

        return [...allGoodsItems, ...mappedGoods];
      }, []);

      const goodsGroupedByGoodId = allGoods.reduce((groupedGoods, goodWithSection) => {
        const goodId = goodWithSection.good.id;

        return {
          ...groupedGoods,
          [goodId]: [...(groupedGoods[goodId] || []), goodWithSection],
        };
      }, {});

      const keyOfRepeatedGoods = Object.keys(goodsGroupedByGoodId)
        .filter((key) => good.id === Number(key))
        .join();

      goodsGroupedByGoodId[keyOfRepeatedGoods].forEach((item) => {
        item.good[goodField] = newFieldValue;
      });

      return [...copy];
    });
  };

  const handleEditPriceClose = (newPrice) => {
    const unitsPrice = +newPrice * 100;

    updateGood('price', unitsPrice);

    setIsEditOpen(false);
    setIsSavedChanges(false);
  };

  const handleScheduleEditClose = (newScheduleValue) => {
    updateGood('schedule', newScheduleValue);

    setIsScheduleEditDialogOpen(false);
    setIsSavedChanges(false);
  };

  return (
    <Paper
      sx={{ ...styles.paper, ...styles.good, ...dragStyle }}
      className={className}
      onClick={onClick}
    >
      {actions}
      <div
        style={{
          ...styles.img,
          backgroundImage: getImageUrl(),
          filter: isActiveInStopList ? 'grayscale(100%)' : 'none',
          position: 'relative',
        }}
      >
        {selected && <CheckCircleIcon color="primary" className={muiStyles.selectedIcon} />}
      </div>
      <div style={styles.goodDetails}>
        <Typography
          variant="h6"
          sx={{ height: '100px', display: 'flex', alignItems: 'center' }}
        >
          {good?.name}
        </Typography>
        {discountName && (
          <Typography variant="h6">{`Current discount: ${discountName}`}</Typography>
        )}
        <div style={styles.goodCardActions}>
          {shouldDisplayActionButtons && (
            <>
              <Button
                variant="contained"
                color={!isActiveInStopList ? 'error' : 'primary'}
                size="small"
                onClick={changeAvailabilityHandler}
              >
                {!isActiveInStopList ? translate('ra.action.stop') : translate('ra.action.resume')}
              </Button>
              <div>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleScheduleEditOpen();
                  }}
                >
                  <ScheduleIcon
                    fontSize="medium"
                    color={good.schedule?.isActive ? 'primary' : 'inherit'}
                  />
                </IconButton>
                <ScheduleEditDialog
                  isDialogOpen={isScheduleEditDialogOpen}
                  onClose={handleScheduleEditClose}
                  good={good}
                  onChange={() => {}}
                />
              </div>
              <div style={styles.goodCardPriceActions}>
                <span>
                  {good.price / 100}
                  &nbsp;
                  {CURRENCY_NAME}
                </span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditOpen();
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <GoodPriceEditDialog
                  goodIndex={isEditOpen}
                  onClose={handleEditPriceClose}
                  good={good}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default GoodCard;
