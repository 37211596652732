import React, { useEffect, useState } from 'react';
import { Typography, Button, IconButton, Accordion, AccordionSummary } from '@mui/material';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslate } from 'react-admin';
import GoodCard from './GoodCard';
import SectionTitleEditDialog from './SectionTitleEditDialog';
import styles from './styles';

const Section = ({
  classes,
  section,
  setSections,
  setManagedSection,
  removeSection,
  isDragDisabled,
  setIsSavedChanges,
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [updatedLabel, setUpdatedLabel] = useState(null);
  const [sectionTranslation, setSectionTranslation] = useState(null);
  const translate = useTranslate();

  const currentLocale = localStorage.getItem('currentLocale');

  const updateSection = (newSection) => {
    setSections((prev) => {
      const index = prev.findIndex((otherSection) => otherSection.id === section.id);
      const copy = [...prev];

      copy[index] = newSection;

      return [...copy];
    });
  };

  const onChange = (_, sourceIndex, targetIndex) => {
    const nextOrder = swap(section.goods, sourceIndex, targetIndex);
    const newSection = JSON.parse(JSON.stringify(section));
    newSection.goods = nextOrder;

    updateSection(newSection);
    setIsSavedChanges(false);
  };

  const handleEditOpen = () => {
    setIsEditOpen(section.id);
  };

  useEffect(() => {
    const currentTranslation = section.translations.find(
      (translation) => translation.locale === currentLocale,
    );

    setSectionTranslation(currentTranslation.value);
  }, [section.translations, currentLocale]);

  const handleEditClose = (translations) => {
    const translatedLabel =
      translations.find((translation) => translation.locale === currentLocale) || {};

    const labelToUpdate = translatedLabel.value || sectionTranslation;

    const newSection = JSON.parse(JSON.stringify(section));
    newSection.label = sectionTranslation;
    newSection.translations = translations || [];

    updateSection(newSection);
    setUpdatedLabel(labelToUpdate);
    setIsEditOpen(false);
    setIsSavedChanges(false);
  };

  const onAvailabilityChange = (goodStatus, idx, goodItemId) => {
    const newSection = JSON.parse(JSON.stringify(section));

    newSection.goods[idx].stopList = { isActive: goodStatus };

    setSections((prev) => {
      const index = prev.findIndex((otherSection) => otherSection.id === section.id);
      const copy = [...prev];

      copy[index] = newSection;

      const sectionsWithItems = prev.filter((item) => item.goods.length);

      const allGoods = sectionsWithItems.reduce((allGoodsItems, category) => {
        const mappedGoods = category.goods.map((good) => {
          return {
            good,
          };
        });

        return [...allGoodsItems, ...mappedGoods];
      }, []);

      const goodsGroupedByGoodId = allGoods.reduce((groupedGoods, goodWithSection) => {
        const goodId = goodWithSection.good.id;

        return {
          ...groupedGoods,
          [goodId]: [...(groupedGoods[goodId] || []), goodWithSection],
        };
      }, {});

      const keyOfRepeatedGoods = Object.keys(goodsGroupedByGoodId)
        .filter((key) => goodItemId === Number(key))
        .join();

      goodsGroupedByGoodId[keyOfRepeatedGoods].forEach((item) => {
        item.good.stopList = { isActive: goodStatus };
      });

      return [...copy];
    });

    setIsSavedChanges(false);
  };

  return (
    <Accordion className={classes.expansionPanels}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={styles.expansionPanelTitle}>
          {updatedLabel || sectionTranslation}
        </Typography>

        <IconButton
          edge="end"
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            handleEditOpen();
          }}
          aria-label="close"
          sx={{ zIndex: 99999 }}
        >
          <EditIcon />
        </IconButton>
        <div style={styles.sectionActions}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setManagedSection(section.id);
            }}
            style={styles.manageButton}
            variant="contained"
            color="primary"
            sx={{ zIndex: 99999 }}
          >
            {translate('ra.action.menu_manage_products')}
          </Button>
          <Button
            onClick={() => removeSection(section.id)}
            style={styles.removeButton}
            variant="contained"
            color="secondary"
            sx={{ zIndex: 99999 }}
          >
            {translate('ra.action.delete')}
          </Button>
        </div>
      </AccordionSummary>
      <SectionTitleEditDialog
        sectionId={isEditOpen}
        translatable={section.translations}
        onClose={handleEditClose}
      />
      <div className={classes.section}>
        <GridContextProvider onChange={onChange}>
          <div className={classes.container}>
            <GridDropZone
              disableDrag={isDragDisabled}
              disableDrop={isDragDisabled}
              className={classes.dropzone}
              style={{
                height: `${Math.ceil(section.goods.length / 4) * 380}px`,
              }}
              id={`${section.id}${section.label}`}
              boxesPerRow={4}
              rowHeight={370}
            >
              {section.goods.map((good, idx) => {
                return (
                  <GridItem key={good.slug + good.id}>
                    <GoodCard
                      key={good.slug}
                      good={good}
                      goodIdx={idx}
                      className={classes.draggableGood}
                      onAvailabilityChange={onAvailabilityChange}
                      section={section}
                      setSections={setSections}
                      setIsSavedChanges={setIsSavedChanges}
                      shouldDisplayActionButtons
                    />
                  </GridItem>
                );
              })}
            </GridDropZone>
          </div>
        </GridContextProvider>
      </div>
    </Accordion>
  );
};

export default Section;
