import React from 'react';
import { useTranslate } from 'react-admin';
import useStyles, { Chip } from './style';
import { useUiSettings } from '../../../hooks/useUiSettings';

const Summary = ({ orderSummary }) => {
  const styles = useStyles();
  const translate = useTranslate();
  const uiSettings = useUiSettings();

  if (!orderSummary) {
    return null;
  }

  const {
    new: newOrders,
    awaitingPayment,
    delivered,
    canceled,
    ordersCount,
    priceCount,
    scheduled,
  } = orderSummary;

  return (
    <div className={styles.root}>
      <span className={styles.today}>{`${translate('ra.title.today')}: `}</span>
      {!!newOrders && (
        <Chip label={`${translate('ra.status.new')}: ${newOrders}`} className={styles.new} />
      )}
      {!!scheduled && (
        <Chip label={`${translate('ra.status.scheduled')}: ${scheduled}`} className={styles.scheduled} />
      )}
      {!!delivered && (
        <Chip
          label={`${translate('ra.status.delivered')}: ${delivered}`}
          className={styles.delivered}
        />
      )}
      <Chip label={`${translate('ra.title.total')}: ${ordersCount}`} className={styles.total} />
      <Chip
        label={`${translate('ra.title.sum')}: ${priceCount / 100} ${uiSettings.currency}`}
        className={styles.sum}
      />
      {!!awaitingPayment && (
        <Chip
          label={`${translate('ra.status.awaiting_payment')}: ${awaitingPayment}`}
          className={styles.awaitingPayment}
        />
      )}
      {!!canceled && (
        <Chip
          label={`${translate('ra.status.canceled')}: ${canceled}`}
          className={styles.canceled}
        />
      )}
    </div>
  );
};

export default Summary;
