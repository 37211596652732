import React from 'react';
import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const DocumentShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="ra.tab.summary">
        <TextField label="ra.label.id" source="id" />
        <TextField label="ra.label.document_type" source="type" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default DocumentShow;
