import { FormatListBulletedOutlined } from '@mui/icons-material';
import CategoryCreate from './components/Create';
import CategoryEdit from './components/Edit';
import CategoryList from './components/List';
import CategoryShow from './components/Show';

export default {
  list: CategoryList,
  create: CategoryCreate,
  edit: CategoryEdit,
  show: CategoryShow,
  icon: FormatListBulletedOutlined,
};
