import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const DiscountList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
      <TextField source="name" />
      <TextField source="label" />
    </Datagrid>
  </List>
);

export default DiscountList;
