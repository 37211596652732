import CourierCreate from './components/Create';
import CourierEdit from './components/Edit';
import CourierList from './components/List';
import CourierShow from './components/Show';

export default {
  list: CourierList,
  create: CourierCreate,
  edit: CourierEdit,
  show: CourierShow,
};
