import React from 'react';
import { Typography } from '@mui/material';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  useTranslate,
} from 'react-admin';
import { CURRENCY_NAME } from '../../../config/currency.config';

const DeliverySettingsShow = () => {
  const translate = useTranslate();

  return (
    <Show>
      <SimpleShowLayout sx={{ maxWidth: '500px' }}>
        <Typography variant="h4">{translate('ra.label.delivery_settings')}</Typography>
        <TextField source="name" label="ra.label.name" />
        <FunctionField
          label={`${translate('ra.label.base_price')} (${CURRENCY_NAME})`}
          render={({ basePrice }) => basePrice / 100}
        />
        <ArrayField source="orderDistanceRanges" label="ra.label.distances">
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label={`${translate('ra.label.from')} (${translate('ra.navigation.km')})`}
              render={({ from }) => from / 1000}
            />
            <FunctionField
              label={`${translate('ra.label.to')} (${translate('ra.navigation.km')})`}
              render={({ to }) => to / 1000}
            />
            <FunctionField
              label={`${CURRENCY_NAME}/${translate('ra.navigation.km')}`}
              render={({ modifier }) => modifier / 100}
            />
          </Datagrid>
        </ArrayField>
        <BooleanField label="ra.label.enabled" source="peakHoursEnabled" />
        <ArrayField source="peakHours" label="ra.label.peak_hours">
          <Datagrid bulkActionButtons={false}>
            <TextField label="ra.label.from" source="from" />
            <TextField label="ra.label.to" source="to" />
            <FunctionField
              label={translate('ra.label.modifier', { mdl: CURRENCY_NAME })}
              render={({ modifier }) => modifier / 100}
            />
          </Datagrid>
        </ArrayField>
        <BooleanField label="ra.label.enabled" source="orderPriceEnabled" />
        <ArrayField source="orderPriceRanges" label="ra.label.order_price_ranges">
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label={`${translate('ra.label.from')} (${CURRENCY_NAME})`}
              render={({ from }) => from / 100}
            />
            <FunctionField
              label={`${translate('ra.label.to')} (${CURRENCY_NAME})`}
              render={({ to }) => to / 100}
            />
            <FunctionField
              label={translate('ra.label.modifier', { mdl: CURRENCY_NAME })}
              render={({ modifier }) => modifier / 100}
            />
            <BooleanField label="ra.label.free_delivery" source="isFree" />
          </Datagrid>
        </ArrayField>
        <TextField source="settingType" label="ra.label.setting_type" />
      </SimpleShowLayout>
    </Show>
  );
};

export default DeliverySettingsShow;
