/* eslint-disable max-len */
import React from 'react';

export default () => (
  <svg width={24} height={24} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8327 3.33301H4.16602C3.50297 3.33301 2.86709 3.5964 2.39825 4.06524C1.92941 4.53408 1.66602 5.16997 1.66602 5.83301V14.1663C1.66602 14.8294 1.92941 15.4653 2.39825 15.9341C2.86709 16.4029 3.50297 16.6663 4.16602 16.6663H15.8327C16.4957 16.6663 17.1316 16.4029 17.6005 15.9341C18.0693 15.4653 18.3327 14.8294 18.3327 14.1663V5.83301C18.3327 5.16997 18.0693 4.53408 17.6005 4.06524C17.1316 3.5964 16.4957 3.33301 15.8327 3.33301V3.33301ZM4.16602 4.99967H15.8327C16.0537 4.99967 16.2657 5.08747 16.4219 5.24375C16.5782 5.40003 16.666 5.61199 16.666 5.83301L9.99935 9.89967L3.33268 5.83301C3.33268 5.61199 3.42048 5.40003 3.57676 5.24375C3.73304 5.08747 3.945 4.99967 4.16602 4.99967V4.99967ZM16.666 14.1663C16.666 14.3874 16.5782 14.5993 16.4219 14.7556C16.2657 14.9119 16.0537 14.9997 15.8327 14.9997H4.16602C3.945 14.9997 3.73304 14.9119 3.57676 14.7556C3.42048 14.5993 3.33268 14.3874 3.33268 14.1663V7.73301L9.56602 11.5413C9.6927 11.6145 9.8364 11.653 9.98268 11.653C10.129 11.653 10.2727 11.6145 10.3993 11.5413L16.666 7.73301V14.1663Z"
      fill="#555555"
    />
  </svg>
);
