export const SUPPLIER_REPORT_HEADERS = [
  { label: 'Type', key: 'type' },

  { label: 'Order Id', key: 'orderId' },

  { label: 'Fulfillment date', key: 'fulfilmentDate' },

  { label: 'Tilbagebetalingskommentar', key: 'refundsComments' },

  { label: 'Tilbagebetalinger incl. VAT', key: 'refunds' },

  { label: 'Brutto, exclud. VAT', key: 'brutto' },

  { label: 'RS share ', key: 'rsShare' },

  { label: 'Netto', key: 'netto' },
];
