import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  Create,
  useRedirect,
} from 'react-admin';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListContainer, ListItem, ItemContent, ItemSlug, ItemImage } from '../styles';
// eslint-disable-next-line max-len
import TranslatableLabel from '../../../components/Translatable/TranslatableFields/TranslatableLabel';
import useStyles from '../../styles';
import { saveNavMenu } from '../../utils/requests';
import { baseUrl } from '../../../config/connection';
import DragHandle from '../DragHandle';
import AddNewItem from '../AddNewItem';
import CancelButton from '../../../components/CancelButton';
import { useOrganizationLocales } from '../../../hooks/useOrganizationLocales';

const validateRequired = required();

const NavigationCreate = () => {
  const [listItems, setListItems] = useState([]);
  const localeList = useOrganizationLocales();

  const styles = useStyles();
  const redirect = useRedirect();

  const addToItems = (values) => {
    const translationLabel = localeList.reduce((acc, val, idx) => ({
      ...acc,
      [val]: values?.S[`label${idx}`].value,
    }), {});

    const action = {};
    if (values.action) {
      action.type = values.action;
    }
    if (values.tags) {
      action.tags = values.tags;
    }

    setListItems([
      ...listItems,
      {
        slug: values.slug,
        image: values.cover && values.cover.length ? values.cover[0].url : '',
        label: translationLabel,
        action,
      },
    ]);
  };

  const deleteItem = (e) => {
    const newItems = [...listItems];
    newItems.splice(e, 1);
    setListItems(newItems);
  };

  const saveForm = (values) => {
    const navElement = {
      name: values.name,
      slug: values.slug,
      storeId: values.storeId,
      items: listItems,
    };
    saveNavMenu(navElement);
    redirect('/navigation');
  };

  return (
    <Create redirect="list" actions={<CancelButton />}>
      <SimpleForm onSubmit={saveForm}>
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        <TextInput label="ra.label.slug" source="slug" validate={validateRequired} />
        <ReferenceInput source="storeId" reference="store">
          <SelectInput
            label="ra.label.supplier_store"
            optionText="name"
            optionValue="id"
            sx={{ maxWidth: '218px', width: '100%' }}
            validate={validateRequired}
          />
        </ReferenceInput>
        <DragDropContext
          onDragEnd={(param) => {
            const srcI = param.source.index;
            const desI = param.destination?.index;
            if (desI) {
              listItems.splice(desI, 0, listItems.splice(srcI, 1)[0]);
              setListItems(listItems);
            }
          }}
        >
          <ListContainer>
            <TranslatableLabel label="ra.label.items" />
            <Droppable droppableId="droppable-1">
              {(provided, _) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {listItems.map((item, i) => (
                    <Draggable
                      key={`item.slug + ${i * 2}`}
                      draggableId={`${item.slug}${i}`}
                      index={i}
                    >
                      {/* eslint-disable-next-line no-shadow */}
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: snapshot.isDragging ? '0 0 .4rem #666' : 'none',
                          }}
                        >
                          <DragHandle {...provided.dragHandleProps} />
                          <ItemContent>
                            <ItemSlug>{item.slug}</ItemSlug>
                            {item.image ? (
                              <ItemImage
                                src={`${baseUrl}/s3/uploads/${item.image}`}
                                alt="item"
                                width="80"
                                height="80"
                              />
                            ) : null}
                          </ItemContent>
                          <DeleteIcon
                            className={styles.delIcon}
                            onClick={() => {
                              deleteItem(i);
                            }}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <AddNewItem addItem={addToItems} />
          </ListContainer>
        </DragDropContext>
      </SimpleForm>
    </Create>
  );
};

export default NavigationCreate;
