import React from 'react';
import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin';
import { TranslatableTextField } from '../../../components/Translatable';
import ResourceTitle from '../../../components/ResourceTitle';
import PriceField from '../../../components/PriceField';
import { useUiSettings } from '../../../hooks/useUiSettings';

const VariantShow = () => {
  const uiSettings = useUiSettings();

  return (
    <Show title={<ResourceTitle field="name" />}>
      <TabbedShowLayout>
        <Tab label="ra.tab.summary">
          <TranslatableTextField label="ra.label.name" source="name" />
          <TextField label="ra.label.weight" source="weight" />
          <PriceField
            priceLabel="ra.label.price"
            source="price"
            parse={(v) => v / 100}
            options={{
              style: 'currency',
              currency: uiSettings.currency,
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default VariantShow;
