export default {
  paper: {
    margin: '8px',
    borderRadius: '16px',
    userSelect: 'none',
    backgroundColor: '#fff',
    transition: '0.25s',
  },
  goodDetails: {
    padding: '10px',
  },
  actionButton: {
    backgroundColor: '#477890',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flexStart',
    alignItems: 'center',
    alignContent: 'center',
    marginRight: '10px',
  },
  manageButton: {
    backgroundColor: '#477890',
    marginRight: '12px',
    zIndex: 99999,
  },
  removeButton: {
    backgroundColor: '#E0777D',
  },
  sectionActions: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignContent: 'center',
  },
  goodsGrid: {
    display: 'grid',
    flex: '0 1 auto',

    gridTemplateColumns: 'repeat(6,1fr)',
    gridColumnGap: '20px',
    gridRowGap: '30px',
  },
  dialog: {
    marginTop: '32px',
  },
  good: {
    cursor: 'pointer',
  },
  draggableGood: {
    height: '360px',
    overflowY: 'auto',
  },
  img: {
    width: '100%',
    height: '180px',
    borderRadius: '8px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  section: {
    backgroundColor: '#fff',
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  goodsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    width: '100%',
    gridColumnGap: '20px',
    gridRowGap: '30px',
  },
  sectionWrapper: {
    marginTop: '16px',
    padding: '8px',
    backgroundColor: '#fff',
  },
  expansionPanels: {
    marginTop: '12px',
    backgroundColor: '#fff',
  },
  expansionPanelTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
  },
  container: {
    display: 'flex',
    touchAction: 'none',
    width: '100%',
  },
  dropzone: {
    flex: '1',
  },
  searchField: {
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
  hidden: {
    display: 'none',
  },
  dialogTitle: {
    marginLeft: '8px',
    flex: 1,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  unSavedChanges: {
    color: 'red',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  modalSaveBtn: {
    marginTop: '8px',
  },
  goodCardActions: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  goodCardPriceActions: {
    display: 'flex',
    alignItems: 'center',
  },
  goodCardDialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  goodCardScheduleActions: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  modalScheduleButton: {
    display: 'block',
    margin: '0 10px',
  },
};
