import React from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';

const DeliveryInstructions = () => {
  const record = useRecordContext();

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: record.deliveryInstructions }} />;
};

const UserAddressList = (props) => (
  <List {...props}>
    <Datagrid
      rowClick="show"
      expand={<DeliveryInstructions />}
      bulkActionButtons={<ListDeleteConfirmationButton />}
    >
      <TextField label="ra.label.contact_name" source="contactName" />
      <FunctionField
        label="ra.label.address"
        render={({ street, area, house, country }) => {
          return [street, area, house, country].filter(Boolean).join(', ');
        }}
      />
      <TextField label="ra.label.phone" source="phone" />
      <ReferenceField label="ra.label.user" source="userId" reference="user" linkType="show">
        <TextField source="username" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default UserAddressList;
