import React from 'react';
import { TextInput, SimpleForm, Edit } from 'react-admin';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const StoreEdit = (props) => {
  return (
    <Edit {...props} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <TextInput label="ra.label.name" source="name" />
        <TextInput label="ra.label.label" source="label" />
        <TextInput label="ra.label.slug" source="slug" />
      </SimpleForm>
    </Edit>
  );
};

export default StoreEdit;
