import { ALL_USER_ROLES_IDS, ALL_USER_ROLES_NAMES } from '../config/user.config';

export const getRole = (role = 2) =>
  Object.entries(ALL_USER_ROLES_IDS).find((roleArr) => {
    return roleArr[1] === role;
  })[0];
export const isUser = (role = 2) => {
  const currentRole = getRole(role);

  return [ALL_USER_ROLES_NAMES].indexOf(currentRole) !== -1;
};

export const isSuperAdmin = () => localStorage.getItem('role') === ALL_USER_ROLES_NAMES.ADMIN;
