import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import { ReactComponent as RefusedOrderIcon } from './icons/RefusedOrderIcon.svg';
import { setRefusedOrder } from '../../../redux/customReducers/utils';

const RefuseOrderButton = ({ order, setOrder }) => {
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        setOrder(order);
      }}
    >
      <RefusedOrderIcon />
    </IconButton>
  );
};

const mapDispatch = { setOrder: setRefusedOrder };

export default connect(null, mapDispatch)(RefuseOrderButton);
