import React from 'react';
import {
  ChipField,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ResourceTitle from '../../../components/ResourceTitle';

const CourierShow = () => (
  <Show title={<ResourceTitle field="firstName" />}>
    <TabbedShowLayout>
      <Tab label="ra.tab.summary">
        <TextField label="ra.label.phone" source="phone" />
        <TextField label="ra.label.first_name" source="firstName" />
        <TextField label="ra.label.last_name" source="lastName" />
        <ChipField
          label="ra.label.status"
          color="secondary"
          source="status"
          sx={{ maxWidth: '100px' }}
        />
        <FunctionField
          label="ra.label.busy"
          render={({ currentOrderIds }) => {
            return currentOrderIds?.length ? <CheckIcon /> : <CloseIcon />;
          }}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default CourierShow;
