import React from 'react';
import { useRecordContext } from 'react-admin';

const ReferenceAmountField = () => {
  const record = useRecordContext();

  if (record?.totalAmount) {
    return <span>{record.totalAmount / 100}</span>;
  }
};

export default ReferenceAmountField;
