import React from 'react';
import { Show, Tab, TabbedShowLayout, TextField } from 'react-admin';

const TagsShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="ra.label.name">
          <TextField label="ra.label.name" source="name" />
          <TextField label="ra.label.label" source="label" />
          <TextField label="ra.label.slug" source="slug" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TagsShow;
